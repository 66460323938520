import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { filter, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { FileInput } from 'ngx-material-file-input';

import { FileDocumentService } from 'src/app/services/file-document.service';
import { Subscription, Observable } from 'rxjs';
import { FormDataService } from '../../../services/form-data.service';
import { SessionFiles } from 'src/app/models/session-files.model';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
})
export class FileInputComponent implements OnInit, OnDestroy {
  @Input() label: string;
  @Input() group: UntypedFormGroup;
  @Input() controlName: string;
  @Input() placeholder?: string = '';
  @Input() controlObject: UntypedFormControl;
  @Input() hint: string;
  //HACK: if user has already uploaded a file, we show a fake text input not included in form group and without validation, otherwise we show real file input with needed validation
  @Input() fileContent$: Observable<any>;
  @Input() consistencyInfoWarning$: Observable<boolean>;
  @Input() isRequired?: boolean;

  isUploading: boolean;
  valueChangesSubscription: Subscription;
  fakeFileName: string;
  warningMessage: string =
    'Hai modificato alcuni campi, verifica che il documento sia coerente con le informazioni inserite.';

  constructor(
    private fileDocumentService: FileDocumentService,
    private formDataService: FormDataService,
    private sessionService: SessionService,
  ) {}

  ngOnInit(): void {
    this.fileContent$.subscribe((uploadedFile) => {
      // HACK: if user has already uploaded a file, we show a fake text input not included in form group and without
      // validation, otherwise we show real file input with needed validation
      if (!uploadedFile) {
        this.controlObject.setValidators([Validators.required]);
      } else {
        // Set a value to prevent the control to be invalid
        // This line break the flow when changing the file upon selected
        // this.controlObject.setValue(uploadedFile.fileKey);

        // SMARMELLO: il file potrebbe arrivare dalla sessione o dall'upload
        this.fakeFileName = uploadedFile.fileName
          ? uploadedFile.fileName
          : uploadedFile.name;
      }
    });

    this.valueChangesSubscription = this.controlObject.valueChanges
      .pipe(
        filter((fileInput) => {
          if (!fileInput) {
            return false;
          }

          if (
            !!fileInput &&
            fileInput.files &&
            fileInput.files[0] &&
            fileInput.files[0].size &&
            fileInput.files[0].size > 5000000
          ) {
            this.controlObject.setErrors({
              validationMessage: 'Il file non deve superare i 5MB',
            });
            return false;
          }

          return true;
        }),
        distinctUntilChanged(),
        switchMap((fileInput: FileInput) => {
          this.isUploading = true;
          this.controlObject.disable();
          this.controlObject.markAsPending();
          return this.fileDocumentService.uploadFile(fileInput, this.controlName);
        }),
      )
      .subscribe(
        (_res) => {
          this.isUploading = false;
          this.controlObject.markAsTouched();
          this.controlObject.markAsDirty();
          this.controlObject.enable();
        },
        (_err) => {
          // TODO: this error is not handled and break everything!
          this.isUploading = false;
          this.controlObject.markAsTouched();
          this.controlObject.markAsDirty();
          this.controlObject.enable();
        },
      );
  }

  ngOnDestroy(): void {
    this.valueChangesSubscription && this.valueChangesSubscription.unsubscribe();
  }

  uploadNewFile(controlName: string): void {
    let overrideSession: SessionFiles = {};

    switch (controlName) {
      case 'directorId':
        //HACK: if user has already uploaded a file, we show a fake text input not included in form group and without validation, otherwise we show real file input with needed validation
        this.formDataService.saveDirectorDocument(null);
        overrideSession = { directorFile: null };
        break;
      case 'accountManagerId':
        //HACK: if user has already uploaded a file, we show a fake text input not included in form group and without validation, otherwise we show real file input with needed validation
        this.formDataService.saveAccountManagerDocument(null);
        overrideSession = { accountManagerFile: null };
        break;
      case 'cardReferentId':
        //HACK: if user has already uploaded a file, we show a fake text input not included in form group and without validation, otherwise we show real file input with needed validation
        this.formDataService.saveCardReferentDocument(null);
        overrideSession = { cardReferentFile: null };
        break;

      default:
        break;
    }

    const sessionFiles = this.sessionService.getUploadedFilesData();
    this.sessionService.saveUploadedFiles({
      ...sessionFiles,
      ...overrideSession,
    });

    this.controlObject.reset();
  }
}
