import { Component, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { Plan as PlanConfig } from '../../../lib/models/plan';
import { Plan } from 'src/app/models/plan.model';
import { ApiService } from '../../services/api.service';
import { ScrollService } from 'src/app/services/scroll.service';
import { SessionService } from 'src/app/services/session.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { ActivatedRoute, Router } from '@angular/router';
import Plans from './assets/plans.json';
import { LoadingService } from 'src/app/services/loading.service';
import { Events } from 'src/app/shared/utils/events';
import { ProspectService } from 'src/app/services/prospect.service';
import { FormDataService } from 'src/app/services/form-data.service';
import { Activate as AtokaActivate } from '../../../lib/models/activate';
import { ContractData as AtokaContractData } from '../../../lib/models/contract-data';

@Component({
  selector: 'app-select-plans',
  templateUrl: './select-plans.component.html',
  styleUrls: ['./select-plans.component.scss'],
})
export class SelectPlansComponent implements OnDestroy {
  plans$: Observable<Array<Plan>>;
  plansLength: number = 0;
  agreement: string;
  linkId: string;
  chooseActionMessage: string;
  planInfo: {
    headline: string;
    tagline: string;
    logo: string;
  };
  defaultAgreement = 'sardex';
  requiredPayment = false;

  @ViewChild('services', { static: true }) servicesElRef: ElementRef;
  @ViewChild('plans', { static: true }) plansElRef: ElementRef;

  constructor(
    private planService: ApiService,
    private scrollService: ScrollService,
    private sessionService: SessionService,
    private loadingService: LoadingService,
    private prospectService: ProspectService,
    private formDataService: FormDataService,
    private navbarService: NavbarService,
    private route: ActivatedRoute,
    private router: Router,
    private events: Events,
  ) {}

  ngOnInit(): void {
    this.agreement = this.route.snapshot.paramMap.get('agreement');
    this.linkId = this.route.snapshot.paramMap.get('linkId');

    this.scrollService.addScrollElements([
      {
        slug: 'services',
        title: 'Servizi',
        elementRef: this.servicesElRef.nativeElement,
      },
      {
        slug: 'plans',
        title: 'Piani',
        elementRef: this.plansElRef.nativeElement,
      },
    ]);

    this.loadingService.startSpinner();

    // If the saved linkId is different from the current route,
    // delete the session and start from scratch
    const landingRoute = this.sessionService.getLandingRoute();

    if (landingRoute) {
      const landingRouteLinkId = landingRoute.split('/')[2];

      if (this.linkId !== landingRouteLinkId) {
        this.sessionService.clear();
      }
    }

    this.plans$ = this.planService.getPlanConfig(this.agreement, this.linkId).pipe(
      tap(({ token, paymentRequired, isProspect }) => {
        this.requiredPayment = paymentRequired;
        this.sessionService.saveRequiredPayment(paymentRequired);
        this.sessionService.saveIsProspect(isProspect);
        this.sessionService.saveJwt(token);
        this.navbarService.resetSteps();
      }),
      map((planConfig: { plans?: PlanConfig; token?: string }) => {
        this.setChooseSolutionMessage(planConfig.plans.plans);
        this.plansLength = planConfig.plans.plans.length;
        this.sessionService.saveLandingRoute(this.router.url);

        //Prospect session
        if (this.sessionService.getIsProspect()) {
          this.prospectService.startProspectSession(planConfig.plans.plans[0]);
        }

        this.events.publish('save-landing-route');
        return planConfig.plans.plans;
      }),
      catchError((err) => {
        const errorPages = {
          404: '/not-found',
          410: '/expired',
        };
        this.router.navigate([errorPages[err.status] || errorPages[404]]);
        return of([]);
      }),
      finalize(() => {
        this.loadingService.stopSpinner();

        // Redirect to contract-data if prospect
        if (this.sessionService.getIsProspect()) {
          this.prospectService
            .submitActivate()
            .subscribe(
              (response: {
                Activate?: AtokaActivate;
                ContractData?: AtokaContractData;
              }) => {
                this.formDataService.saveActivateForm(response);
                this.router.navigate(['/contract-data']);
              },
            );
        }
      }),
    );
    this.setPlanInfo();
  }

  setChooseSolutionMessage(data: Plan[]) {
    this.chooseActionMessage =
      data.length > 1
        ? 'Scegli ora la soluzione su misura per te'
        : 'Attiva ora la soluzione su misura per te';
  }

  setPlanInfo() {
    this.planInfo = Plans.plans[this.agreement] || Plans.plans[this.defaultAgreement];
  }

  ngOnDestroy(): void {
    this.scrollService.removeElements();
  }

  scrollToPlans(): void {
    this.scrollService.scrollTo('plans');
  }
}
