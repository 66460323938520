<header class="offer-header">
  <div class="title-container">
    <h1 class="mat-display-1" [innerHTML]="planInfo.tagline"></h1>
    <a
      mat-flat-button
      color="primary"
      class="mat-big-button scroll-to-plans"
      href="#plans"
      (click)="scrollToPlans(); $event.preventDefault()"
    >
      <ng-container *ngIf="requiredPayment"> Attiva subito </ng-container>
      <ng-container *ngIf="!requiredPayment"> Compila il contratto </ng-container>
    </a>
  </div>
  <div class="arrow"></div>
  <div class="cta-container">
    <a
      id="scroll-to-plans"
      mat-flat-button
      color="primary"
      class="mat-big-button"
      href="#plans"
      (click)="scrollToPlans(); $event.preventDefault()"
    >
      <ng-container *ngIf="requiredPayment"> Attiva subito </ng-container>
      <ng-container *ngIf="!requiredPayment"> Compila il contratto </ng-container>
    </a>
  </div>
</header>

<section class="offer-description">
  <div class="page-container">
    <img *ngIf="planInfo.logo" [src]="planInfo.logo" alt="Confeserfidi" />
    <p class="mat-title">
      {{ planInfo.headline }}
    </p>
  </div>
</section>

<section id="services" class="benefits" #services>
  <div class="page-container">
    <h2>Entra nel circuito, moltiplica il tuo business!</h2>
    <div class="benefits-container">
      <div class="benefit">
        <div class="icon">
          <img src="/assets/Icon_new-clients.svg" alt="Nuovi clienti e fornitori" />
        </div>
        <p class="mat-title">
          Nuovi clienti e<br />
          fornitori
        </p>
        <p class="benefit-description mat-body-2">
          Amplia il portfolio clienti ed entra in contatto sia con realtà in tutto il
          Paese, sia con imprese locali, con cui sviluppare legami commerciali e di
          fiducia reciproca.
        </p>
      </div>
      <div class="benefit">
        <div class="icon">
          <img src="/assets/Icon_zero-interests.svg" alt="Credito senza interessi" />
        </div>
        <p class="mat-title">
          Credito senza<br />
          interessi
        </p>
        <p class="benefit-description mat-body-2">
          Un progetto importante alle porte? Un investimento inaspettato? Efficio+ ti dà
          credito aggiuntivo che restituisci con interessi zero.
        </p>
      </div>
      <div class="benefit">
        <div class="icon">
          <img
            src="/assets/Icon_products-people.svg"
            alt="Tanti prodotti e servizi pensati per te e i tuoi dipendenti"
          />
        </div>
        <p class="mat-title">Prodotti e servizi per te e i tuoi dipendenti</p>
        <p class="benefit-description mat-body-2">
          Apri un conto B2E per i membri della tua squadra e versa crediti Sardex. Li
          spenderanno liberamente presso le attività del circuito.
        </p>
      </div>
    </div>
  </div>
</section>

<section class="about-sardex">
  <div class="page-container">
    <div class="phone">
      <img width="100%" src="/assets/sardex@2x.png" alt="Io pago Sardex" />
    </div>

    <div class="description">
      <h2 class="mat-title">
        La piattaforma SardexPay è lo strumento aggiuntivo ideale per far crescere i tuoi
        affari.
      </h2>
      <p class="mat-body-2">
        Da Desktop o da Mobile non importa. In pochi click puoi pagare, incassare, cercare
        tra migliaia di PMI chi sarà il tuo prossimo fornitore o il tuo nuovo cliente,
        pubblicare annunci e visualizzare offerte speciali promosse da tante PMI come te.
      </p>
    </div>
  </div>
</section>

<div id="plans" class="plans" #plans>
  <div class="page-container">
    <h1 class="plans-title">
      {{ chooseActionMessage }}
    </h1>
    <div class="plans-container" [class]="plansLength <= 1 ? 'single-plan' : ''">
      <app-plan-card
        *ngFor="let plan of plans$ | async"
        [plan]="plan"
        [requiredPayment]="requiredPayment"
      ></app-plan-card>
    </div>
    <div *ngIf="plansLength <= 0">
      <!-- No content -->
    </div>
  </div>
</div>
