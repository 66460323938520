import { Inject, Injectable, Optional } from '@angular/core';
import { MAT_DATE_LOCALE, MatDateFormats } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Moment } from 'moment';
import * as moment from 'moment';

@Injectable()
export class CustomDateAdapter extends MomentDateAdapter {
  locale: string;

  constructor(@Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string) {
    super(dateLocale);
    this.locale = dateLocale;
  }

  format(date: Moment, displayFormat: string): string {
    date = this.clone(date);

    if (!this.isValid(date)) {
      throw Error('MomentDateAdapter: Cannot format invalid date.');
    }
    return date.format(displayFormat);
  }

  //NOTE: this solves manual input date wrongly set 1 day back
  parse(value: any, parseFormat: string | string[]): Moment | null {
    if (value && typeof value == 'string') {
      if (value.length !== 10) {
        return null;
      }

      const momentUtcDate = moment.utc(value, parseFormat).locale(this.locale);

      return momentUtcDate.year() > 1800 ? momentUtcDate : null;
    }
    return value ? moment.utc(value).locale(this.locale) : null;
  }

  //NOTE: this solves select date in calendar wrongly set 1 day back
  createDate(year: number, month: number, date: number): Moment {
    // Moment.js will create an invalid date if any of the components are out of bounds, but we
    // explicitly check each case so we can throw more descriptive errors.
    if (month < 0 || month > 11) {
      throw Error(
        `Invalid month index "${month}". Month index has to be between 0 and 11.`,
      );
    }

    if (date < 1) {
      throw Error(`Invalid date "${date}". Date has to be greater than 0.`);
    }

    let result = moment.utc({ year, month, date }).locale(this.locale);

    // If the result isn't valid, the date must have been out of bounds for this month.
    if (!result.isValid()) {
      throw Error(`Invalid date "${date}" for month with index "${month}".`);
    }

    return result;
  }
}

export const CUSTOM_MOMENT_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM Y',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMM Y',
  },
};
