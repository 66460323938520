/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { City } from '../models/city';


/**
 * City
 */
@Injectable({
  providedIn: 'root',
})
export class CityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getCityCap
   */
  static readonly GetCityCapPath = '/city/{city_id}/cap';

  /**
   * Get cap property of a city
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCityCap()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCityCap$Response(params: {

    /**
     * City Id
     */
    cityId: any;

  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, CityService.GetCityCapPath, 'get');
    if (params) {

      rb.path('city_id', params.cityId);

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * Get cap property of a city
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCityCap$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCityCap(params: {

    /**
     * City Id
     */
    cityId: any;

  }): Observable<Array<string>> {

    return this.getCityCap$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation searchCity
   */
  static readonly SearchCityPath = '/city/search';

  /**
   * Search for a city
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchCity()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchCity$Response(params?: {

    /**
     * Query
     */
    q?: any;

  }): Observable<StrictHttpResponse<Array<City>>> {

    const rb = new RequestBuilder(this.rootUrl, CityService.SearchCityPath, 'get');
    if (params) {

      rb.query('q', params.q);

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<City>>;
      })
    );
  }

  /**
   * Search for a city
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `searchCity$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchCity(params?: {

    /**
     * Query
     */
    q?: any;

  }): Observable<Array<City>> {

    return this.searchCity$Response(params).pipe(
      map((r: StrictHttpResponse<Array<City>>) => r.body as Array<City>)
    );
  }

}
