import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { PaymentDetailsResponse } from '../../../lib/models/payment-details-response';
import { Payment } from '../../models/payment.model';
import { PaypalCreateStatus } from '../../models/paypal-status.model';
import { Plan } from '../../models/plan.model';
import { ApiService } from '../../services/api.service';
import { ErrorService } from '../../services/error.service';
import { FormDataService } from '../../services/form-data.service';
import { LoadingService } from '../../services/loading.service';
import { PaymentService } from '../../services/payment.service';
import { SessionService } from '../../services/session.service';
import { ScrollService } from 'src/app/services/scroll.service';
import { NEVER } from 'rxjs';
import { NocheckoutRequest } from 'src/lib/models';
import { ProspectService } from 'src/app/services/prospect.service';

enum PaymentMethods {
  PayPal = 'paypal',
  InstantSepa = 'instantSepa',
  RecurringSepa = 'recurringSepa',
}

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit, OnDestroy {
  paypalToken: string = '';
  paypalError: any;
  fabrickPaymentToken: string;
  showPaymentError: boolean = false;

  plan: Plan;
  hasDelay: boolean;
  paymentForm: UntypedFormGroup;

  availablePaymentMethods: PaymentMethods[];
  selectedPaymentMethod: PaymentMethods;

  requiredPayment: boolean = false;
  isProspect: boolean = false;

  constructor(
    private el: ElementRef,
    private apiService: ApiService,
    private formBuilder: UntypedFormBuilder,
    private formDataService: FormDataService,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
    private paymentService: PaymentService,
    private prospectService: ProspectService,
    private sessionService: SessionService,
    private errorService: ErrorService,
    private scrollService: ScrollService,
    private router: Router,
  ) {
    this.requiredPayment = this.sessionService.getRequiredPayment();
    this.isProspect = this.sessionService.getIsProspect();

    this.plan = this.sessionService.getPlan();

    this.hasDelay = this.plan.delay && this.plan.delay > 0;

    this.paypalToken = this.route.snapshot.queryParamMap.get('token');
    this.paypalError = this.route.snapshot.queryParamMap.get('paypalError');

    this.fabrickPaymentToken = this.route.snapshot.queryParamMap.get('paymentToken');

    if (this.isProspect) {
      this.paymentForm = this.prospectService.buildPaymentForm(
        this.sessionService.getPaymentFormData(),
      );

      //Disable PEC field
      this.paymentForm.controls.pec.disable();
    } else {
      this.paymentForm = this._buildForm(this.sessionService.getPaymentFormData());
    }

    this.resetPaymentMethod();
  }

  ngOnInit(): void {
    this._setAvailablePaymentMethods();

    // NOTE: if paypalToken is in url parameters, then we are in the paypal cancel flow,
    // so we need to give some feedbacks
    if (this.paypalToken) {
      // payment cancelled by user
    }

    // NOTE: if paypalError is in url parameters, then something wrong has happened on paypal side,
    // and we need to give some feedbacks to user
    if (this.paypalError) {
      this.showPaymentError = true;
    }

    if (this.fabrickPaymentToken) {
      this.showPaymentError = true;
    }

    this.autoSelectPaymentMethod();
  }

  // Use PaymentMethods Enum on template
  get paymentMethods(): typeof PaymentMethods {
    return PaymentMethods;
  }

  /**
   * Automatically selects a payment method if there is only one available.
   */
  private autoSelectPaymentMethod() {
    const availablePaymentMethodsLenght = this.availablePaymentMethods.length;
    if (availablePaymentMethodsLenght === 1) {
      this.selectPaymentMethod(this.availablePaymentMethods[0]);
    }
  }

  paymentMethodIsAvailable(paymentMethod: PaymentMethods) {
    return this.availablePaymentMethods.includes(paymentMethod);
  }

  saveDraft() {
    // Save a draft of the form in the session
    const formData: Payment = this.paymentForm.value;
    this.formDataService.savePaymentForm(formData);

    this.router.navigate(['/signature']);
  }

  private _createPayment(
    paymentService: PaymentMethods | string,
    formData: Payment = null,
  ) {
    switch (paymentService) {
      case PaymentMethods.PayPal:
        return this.paymentService.createPayment().pipe(
          tap((createPayment: PaypalCreateStatus) => {
            window.location.href = createPayment.paymentURL;
          }),
        );
    }
  }

  submitForm() {
    if (this.paymentForm.invalid) {
      this.paymentForm.setErrors({
        validationMessage:
          'Errore durante il salvataggio. Correggi i campi non validi e riprova.',
      });

      this.paymentForm.markAllAsTouched();
      return;
    }

    const formData: Payment = this.paymentForm.value;

    if (!this.selectedPaymentMethod) {
      this.paymentForm.setErrors({
        validationMessage: 'Scegli un metodo di pagamento per continuare',
      });
      return;
    }

    this.loadingService.startSpinner();
    formData.payment = this.selectedPaymentMethod;

    this.apiService
      .submitPaymentForm(formData)
      .pipe(
        tap((paymentFormData: PaymentDetailsResponse) =>
          this.formDataService.savePaymentForm(paymentFormData),
        ),
        catchError((errorResponse: HttpErrorResponse) => {
          this.loadingService.stopSpinner();
          this._handleError(errorResponse);

          // Stop the logic in case of validation errors
          return NEVER;
        }),
        // Start the payment flow
        switchMap(() => this._createPayment(formData.payment, formData)),
      )
      .subscribe(
        () => {
          // Payment flow done
        },
        (errorResponse: HttpErrorResponse) => {
          this.loadingService.stopSpinner();

          if (
            errorResponse.error &&
            errorResponse.error.providedIban &&
            !errorResponse.error.isValid
          ) {
            if (this.paymentForm.controls.recurringSepaIBAN.enable) {
              this.paymentForm.controls.recurringSepaIBAN.setErrors({
                validationMessage: "L'IBAN inserito non è valido per l'operazione.",
              });
            }

            if (this.paymentForm.controls.instantSepaIBAN.enable) {
              this.paymentForm.controls.instantSepaIBAN.setErrors({
                validationMessage: "L'IBAN inserito non è valido per l'operazione.",
              });
            }
          }

          this._handlePaymentError(errorResponse);

          // SMARMELLO: Remove setTimeout, the class is added after few ms...
          setTimeout(() => {
            const firstInvalid = this._getFirstInvalidControlPosition();
            this.scrollService.scrollToCoords(firstInvalid);
          }, 50);
        },
      );

    return;
  }

  submitContract(): void {
    if (this.paymentForm.invalid) {
      this.paymentForm.setErrors({
        validationMessage:
          'Errore durante il salvataggio. Correggi i campi non validi e riprova.',
      });

      this.paymentForm.markAllAsTouched();
      return;
    }

    const formData: NocheckoutRequest = this.paymentForm.value;

    this.loadingService.startSpinner();

    this.apiService.submitNoCheckoutContract(formData).subscribe({
      next: () => {
        this.router.navigate(['/nocheckout-thank-you']);
        this.loadingService.stopSpinner();
      },
      error: (err) => {
        this.loadingService.stopSpinner();
        console.log('err', err);
      },
    });

    return;
  }

  ngOnDestroy(): void {}

  resetPaymentMethod() {
    this.selectedPaymentMethod = null;
  }

  selectPaymentMethod(selectedPaymentMethod: PaymentMethods) {
    this.selectedPaymentMethod = selectedPaymentMethod;

    switch (this.selectedPaymentMethod) {
      case PaymentMethods.PayPal:
        this.paymentForm.controls.paypalCheck.setValue(true);
        break;
      case PaymentMethods.RecurringSepa:
        this.paymentForm.controls.recurringSepaIBAN.enable();
        this.paymentForm.controls.recurringSepaPrivacy.enable();
        break;
      case PaymentMethods.InstantSepa:
        this.paymentForm.controls.instantSepaIBAN.enable();
        this.paymentForm.controls.instantSepaPrivacy.enable();
        break;
    }
  }

  private _setAvailablePaymentMethods() {
    this.availablePaymentMethods = [PaymentMethods.PayPal];
  }

  private _getFirstInvalidControlPosition(): number {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      '.mat-form-field-invalid',
    );

    if (!firstInvalidControl) {
      return null;
    }

    return firstInvalidControl.offsetTop;
  }

  private _buildForm(formDefaultValues: Payment): UntypedFormGroup {
    return this.formBuilder.group({
      pec: [
        formDefaultValues.pec,
        [
          Validators.required,
          Validators.pattern(
            "^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$",
          ),
        ],
      ],
      ipa: [
        formDefaultValues.ipa,
        [Validators.required, Validators.pattern('^[a-zA-Z0-9]{6,7}$')],
      ],
      paypalCheck: [false, [Validators.requiredTrue]],
    });
  }

  private _handleError(errorResponse: HttpErrorResponse): void {
    this.paymentForm = this.errorService._handleSubmitError(
      this.paymentForm,
      errorResponse,
    );
  }

  private _handlePaymentError(errorResponse: HttpErrorResponse): void {
    this.paymentForm = this.errorService._handleFabrickError(
      this.paymentForm,
      errorResponse,
    );
  }
}
