<div class="container">
  <div>
    <ng-container *ngIf="isSuccess; else error">
      <h2>Il tuo contratto è stato firmato</h2>

      <p>
        Il tuo contratto è stato compilato correttamente e firmato.
        <!--TODO: Da aggiungere Abbiamo inviato una
        copia all'indirizzo {{ emailAddress }}.-->
        <br />
        <ng-container *ngIf="!requiredPayment"
          >Il commerciale di riferimento ti contatterà per finalizzare il
          pagamento.</ng-container
        >
      </p>
      <a
        *ngIf="requiredPayment"
        routerLink="/payment"
        mat-raised-button
        color="accent"
        class="mat-big-button"
        >Procedi con il pagamento</a
      >
      <a
        *ngIf="!requiredPayment"
        routerLink="/"
        mat-raised-button
        color="accent"
        class="mat-big-button"
        >Chiudi</a
      >
    </ng-container>
    <ng-template #error>
      <h2>Qualcosa è andato storto</h2>

      <p>
        Si è verificato un problema durante l'elaborazione dei dati, riprova più tardi
      </p>
      <a routerLink="/signature" mat-raised-button color="accent" class="mat-big-button"
        >Riprova</a
      >
    </ng-template>
  </div>
</div>
