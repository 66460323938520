<ng-container [ngSwitch]="inputType">
  <ng-container *ngSwitchCase="inputTypesEnum.Url">
    <p
      *ngIf="
        controlObject.errors?.pattern && (controlObject.dirty || controlObject.touched)
      "
    >
      Inserisci un indirizzo web valido
    </p>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <p
      *ngIf="
        controlObject.errors?.pattern && (controlObject.dirty || controlObject.touched)
      "
    >
      Dati non validi
    </p>
    <p
      *ngIf="
        controlObject.errors?.maxlength && (controlObject.dirty || controlObject.touched)
      "
    >
      Dati non validi
    </p>
    <p
      *ngIf="
        controlObject.errors?.minlength && (controlObject.dirty || controlObject.touched)
      "
      class="alert alert-danger"
    >
      Dati non validi
    </p>
  </ng-container>
</ng-container>

<p
  *ngIf="controlObject.errors?.required && (controlObject.dirty || controlObject.touched)"
>
  Campo obbligatorio
</p>
<p *ngIf="controlObject.errors?.validationMessage">
  {{ controlObject.errors.validationMessage }}
</p>

<p *ngIf="controlObject.errors?.usernameTaken">Username non disponibile</p>

<p *ngIf="controlObject.errors?.ibanNotValidForPayment">
  L'IBAN specificato non è valido per questa operazione
</p>

<p *ngIf="controlObject.errors?.ibanNotValid">L'IBAN specificato non è valido</p>

<p *ngIf="controlObject.errors?.circuitPropensityError">
  Inserire un valore compreso tra {{ controlObject.errors.circuitPropensityError.min }} e
  {{ controlObject.errors.circuitPropensityError.max }} euro
</p>

<p *ngIf="controlObject.errors?.invalidAutocompleteObject">Seleziona un valore</p>
