import { Injectable } from '@angular/core';

import { Plan } from '../models/plan.model';

import { AbstractControl, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class AccountManagementValidators {
  constructor() {}

  circuitPropensityValidator(plan: Plan): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (
        control.value !== undefined &&
        (isNaN(control.value) ||
          control.value < plan.commercialAvailability.gte ||
          control.value > plan.commercialAvailability.lte)
      ) {
        return {
          circuitPropensityError: {
            min: plan.commercialAvailability.gte,
            max: plan.commercialAvailability.lte,
          },
        };
      }
      return null;
    };
  }
}
