/**
 * * Data model for 2nd step of activate form
 *
 * @export
 * @class AccountManagement
 */

type acceptancePercentageEnum = 25 | 50 | 75 | 100;

export class AccountManagement {
  firstName?: string = null;
  lastName?: string = null;
  accountManagerId?: string = null;
  email?: string = null;
  mobileNumber?: string = null;
  cardFirstName?: string = null;
  cardLastName?: string = null;
  cardReferentId?: string = null;
  circuitPropensity?: number = null;
  acceptancePercentage?: acceptancePercentageEnum = null;
  onlineName?: string = null;
  loginName?: string = null;
  paymentRequired?: boolean = false;
  sepa?: boolean = false;
  bankDetails?: string;
  creditorAddress?: string;
  creditorIBAN?: string;
  creditorName?: string;
  creditorPlaceAndZipCode?: string;
  debtorAddress?: string;
  debtorIBAN?: string;
  debtorName?: string;
  debtorPlaceAndZipCode?: string;
  isProspect?: boolean;
}
