<div class="container background">
  <div class="page-container">
    <h1 class="mat-h1">Compila il form e attiva il piano</h1>
    <form [formGroup]="activateForm">
      <app-text-input
        type="text"
        label="Nome"
        [group]="activateForm"
        controlName="firstName"
        [controlObject]="activateForm.controls.firstName"
        [isRequired]="true"
      ></app-text-input>
      <app-text-input
        type="text"
        label="Cognome"
        [group]="activateForm"
        controlName="lastName"
        [controlObject]="activateForm.controls.lastName"
        [isRequired]="true"
      ></app-text-input>

      <app-text-input
        [type]="'text'"
        [label]="'Partita Iva'"
        [placeholder]="'ES. 03257760920'"
        [group]="activateForm"
        [controlName]="'vatNumber'"
        [controlObject]="activateForm.controls.vatNumber"
        [isRequired]="true"
      ></app-text-input>

      <app-text-input
        [type]="'phone'"
        [label]="'Numero di telefono cellulare'"
        [group]="activateForm"
        [controlName]="'mobileNumber'"
        [controlObject]="activateForm.controls.mobileNumber"
        [isRequired]="true"
      ></app-text-input>

      <app-text-input
        [type]="'email'"
        [label]="'Email'"
        [group]="activateForm"
        [controlName]="'email'"
        [controlObject]="activateForm.controls.email"
        [isRequired]="true"
      ></app-text-input>

      <div class="privacy-check" [formGroup]="activateForm">
        <mat-checkbox
          [id]="'privacyPolicyBase'"
          [name]="'privacyPolicyBase'"
          [formControlName]="'privacyPolicyBase'"
          color="primary"
        >
          Do il consenso al trattamento dati, ex art. 13 Regolamento UE 679/2016 di cui
          all'<a href="#" (click)="openDialog(); $event.preventDefault()">informativa</a>
        </mat-checkbox>
        <mat-error>
          <app-input-validation
            [controlObject]="activateForm.controls.privacyPolicyBase"
          ></app-input-validation>
        </mat-error>
      </div>

      <div class="actions">
        <ng-container *ngIf="requiredPayment">
          <button
            *ngIf="plan.type === 'online' || plan.type === 'recurring'"
            mat-flat-button
            class="mat-big-button"
            type="submit"
            color="accent"
            (click)="onlineActivation()"
            [disabled]="activateForm.pending"
          >
            Attiva online
          </button>
          <button
            *ngIf="plan.type === 'offline'"
            mat-flat-button
            class="mat-big-button"
            type="submit"
            color="accent"
            (click)="offlineActivation()"
            [disabled]="activateForm.pending"
          >
            Attiva tramite appuntamento
          </button>
        </ng-container>
        <ng-container *ngIf="!requiredPayment">
          <button
            mat-flat-button
            class="mat-big-button"
            type="submit"
            color="accent"
            (click)="onlineActivation()"
            [disabled]="activateForm.pending"
          >
            Compila il contratto
          </button>
        </ng-container>
      </div>
    </form>
  </div>

  <mat-card
    class="submit-error mat-elevation-z0"
    *ngIf="activateForm.errors?.validationMessage"
  >
    <mat-list>
      <mat-list-item>
        <mat-icon mat-list-icon>announcement</mat-icon>
        <div mat-line>{{ activateForm.errors.validationMessage }}</div>
      </mat-list-item>
    </mat-list>
  </mat-card>
</div>
