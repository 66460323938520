/**
 * Data model for activate form
 *
 * @export
 * @class Activate
 */

export class Activate {
  firstName?: string = null;
  lastName?: string = null;
  vatNumber?: string = null;
  mobileNumber?: string = null;
  email?: string = null;
  privacyPolicyBase?: boolean = null;
}
