<mat-form-field [formGroup]="group" appearance="fill">
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    [id]="controlName"
    [name]="controlName"
    [formControlName]="controlName"
    [matAutocomplete]="cityInputRef"
    [formControl]="controlObject"
    autocomplete="chrome-off"
    [required]="isRequired"
  />
  <mat-spinner *ngIf="isLoading" diameter="25" color="accent" matSuffix></mat-spinner>
  <mat-autocomplete
    #cityInputRef="matAutocomplete"
    [displayWith]="displayCityFn"
    autoActiveFirstOption
  >
    <ng-container *ngIf="!isLoading">
      <mat-option *ngFor="let city of filteredCities" [value]="city">
        <span>{{ city.nome }} ({{ city.provincia_abbr }})</span>
      </mat-option>
    </ng-container>
  </mat-autocomplete>
  <mat-error>
    <app-input-validation [controlObject]="controlObject"></app-input-validation>
  </mat-error>
</mat-form-field>
