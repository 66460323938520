import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { Observable, BehaviorSubject } from 'rxjs';

import { Activate as AtokaActivate } from '../../lib/models/activate';
import { ContractData as AtokaContractData } from '../../lib/models/contract-data';

import { AccountManagement } from '../models/account-management.model';
import { ContractData } from '../models/contract-data.model';
import { Payment } from '../models/payment.model';
import { UploadedFile } from '../models/uploaded-file.model';

import { NavbarService } from './navbar.service';
import { SessionService } from './session.service';
import { debounceTime, map } from 'rxjs/operators';
import { Signature } from '../models/signature.model';

/**
 * Used to keep and manage data provided through form steps
 *
 * it should contain getter and setter methods for each form model, plus common methods (submit, clear, etc.)
 *
 * @export
 * @class FormDataService
 */
@Injectable({
  providedIn: 'root',
})
export class FormDataService {
  private directorDocument: BehaviorSubject<any>;
  directorDocument$: Observable<any>;

  private accountManagerDocument: BehaviorSubject<any>;
  accountManagerDocument$: Observable<any>;

  private cardReferentDocument: BehaviorSubject<any>;
  cardReferentDocument$: Observable<any>;

  private consistencyWarning: BehaviorSubject<string>;
  consistencyWarning$: Observable<string>;

  constructor(
    private navbarService: NavbarService,
    private sessionService: SessionService,
  ) {
    this._initFormDefaultValues();

    this.consistencyWarning = new BehaviorSubject('');
    this.consistencyWarning$ = this.consistencyWarning.asObservable();
  }

  saveActivateForm(formData: {
    Activate?: AtokaActivate;
    ContractData?: AtokaContractData;
  }): void {
    // SMARMELLO: Resetta i file
    this._initFormDefaultValues();

    const activateData: any = formData.Activate;
    const atokaContractData: AtokaContractData = formData.ContractData;

    this.sessionService.saveActivateFormData(activateData);

    this.sessionService.saveContractFormData({
      ...atokaContractData,
      vatNumber: activateData.vatNumber,
      mobileNumber: activateData.mobileNumber,
      email: activateData.email,
      directorFirstName: atokaContractData.directorFirstName,
      directorLastName: atokaContractData.directorLastName,
      directorBirthDate: atokaContractData.directorBirthDate
        ? atokaContractData.directorBirthDate
        : '',
      legalCity: atokaContractData.legalCity ? atokaContractData.legalCity[0] : null,
      hqCity: atokaContractData.hqCity ? atokaContractData.hqCity[0] : null,
    });

    this.navbarService.enableStep('contract-data');
  }

  saveContractDataForm(formData: ContractData): void {
    this.sessionService.saveContractFormData({
      ...formData,
      directorId: this.directorDocument.value?.name,
      paymentRequired: this.sessionService.getRequiredPayment(),
    });

    this.sessionService.saveAccountManagementFormData({
      ...this.sessionService.getAccountManagementFormData(),
      mobileNumber: formData.mobileNumber,
      email: formData.email,
      paymentRequired: this.sessionService.getRequiredPayment(),
    });

    this.sessionService.savePaymentFormData({
      ...this.sessionService.getPaymentFormData(),
      pec: formData.pec,
    });

    this.navbarService.enableStep('account-management');
  }

  saveAccountManagementForm(formData: AccountManagement): void {
    this.sessionService.saveAccountManagementFormData({
      ...formData,
      accountManagerId: this.accountManagerDocument.value?.name,
      cardReferentId: this.cardReferentDocument.value?.name,
    });

    this.navbarService.enableStep('signature');
  }

  saveSignatureForm(formData: Signature): void {
    this.sessionService.saveSignatureFormData(formData);
    this.navbarService.enableStep('payment');
  }

  savePaymentForm(formData: Payment): void {
    this.sessionService.savePaymentFormData(formData);
  }

  saveDirectorDocument(content: UploadedFile): void {
    this.directorDocument.next(content);
  }

  saveAccountManagerDocument(content: UploadedFile): void {
    this.accountManagerDocument.next(content);
  }

  saveCardReferentDocument(content: UploadedFile): void {
    this.cardReferentDocument.next(content);
  }

  watchConsistencyInfo(watchedControl: AbstractControl): Observable<boolean> {
    let previousValue = watchedControl.value;

    return watchedControl.valueChanges.pipe(
      debounceTime(300),
      map((actualValue) => {
        //NOTE: avoid to show consistency warning if input is still empty
        if (previousValue === null) {
          previousValue = actualValue;
        }
        return previousValue !== actualValue;
      }),
    );
  }

  private _initFormDefaultValues(): void {
    //INIT OBSERVABLES FOR FILE INPUTS
    const sessionFiles = this.sessionService.getUploadedFilesData();

    if (sessionFiles && sessionFiles.directorFile) {
      this.directorDocument = new BehaviorSubject(sessionFiles.directorFile);
    } else {
      this.directorDocument = new BehaviorSubject(null);
    }
    this.directorDocument$ = this.directorDocument.asObservable();

    if (sessionFiles && sessionFiles.directorFile) {
      this.accountManagerDocument = new BehaviorSubject(sessionFiles.accountManagerFile);
    } else {
      this.accountManagerDocument = new BehaviorSubject(null);
    }
    this.accountManagerDocument$ = this.accountManagerDocument.asObservable();

    if (sessionFiles && sessionFiles.directorFile) {
      this.cardReferentDocument = new BehaviorSubject(sessionFiles.cardReferentFile);
    } else {
      this.cardReferentDocument = new BehaviorSubject(null);
    }
    this.cardReferentDocument$ = this.cardReferentDocument.asObservable();
  }
}
