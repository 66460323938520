import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { Plan } from '../models/plan.model';

import { Activate } from '../models/activate.model';
import { ContractData } from '../models/contract-data.model';
import { AccountManagement } from '../models/account-management.model';
import { Payment } from '../models/payment.model';
import { SessionFiles } from '../models/session-files.model';
import { Step } from '../models/step.model';
import { Signature } from '../models/signature.model';

export type STORAGE_KEY =
  | 'jwt'
  | 'requiredPayment'
  | 'plan'
  | 'activate'
  | 'contractData'
  | 'accountManagement'
  | 'signature'
  | 'payment'
  | 'files'
  | 'steps'
  | 'landingRoute'
  | 'isProspect';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private jwt: BehaviorSubject<string>;
  jwt$: Observable<string>;
  private requiredPayment: BehaviorSubject<boolean>;
  requiredPayment$: Observable<boolean>;
  private isProspect: BehaviorSubject<boolean>;
  isProspect$: Observable<boolean>;

  constructor() {
    this.jwt = new BehaviorSubject<string>(localStorage.getItem('jwt'));
    this.jwt$ = this.jwt.asObservable();

    this.requiredPayment = new BehaviorSubject<boolean>(
      localStorage.getItem('requiredPayment') === '1',
    );
    this.requiredPayment$ = this.requiredPayment.asObservable();

    this.isProspect = new BehaviorSubject<boolean>(
      localStorage.getItem('isProspect') === 'true',
    );
    this.isProspect$ = this.isProspect.asObservable();
  }

  initFormData(): void {
    this.saveActivateFormData(new Activate());
    this.saveContractFormData(new ContractData());
    this.saveAccountManagementFormData(new AccountManagement());
    this.saveSignatureFormData(new Signature());
    this.savePaymentFormData(new Payment());
    this.resetUploadedFiles();
  }

  private save<T>(key: STORAGE_KEY, payload: T): void {
    localStorage.setItem(
      key,
      typeof payload === 'string' ? payload : JSON.stringify(payload),
    );
  }

  private load<T>(key: STORAGE_KEY): T {
    return JSON.parse(localStorage.getItem(key));
  }

  getjwtToken(): string {
    return this.jwt.value;
  }

  saveJwt(jwtToken: string): void {
    this.save('jwt', jwtToken);
    this.jwt.next(jwtToken);
  }

  getRequiredPayment(): boolean {
    return this.requiredPayment.value;
  }

  saveRequiredPayment(required: boolean): void {
    this.save('requiredPayment', required.toString());
    this.requiredPayment.next(required);
  }

  getIsProspect(): boolean {
    return this.isProspect.value;
  }

  saveIsProspect(isProspect: boolean) {
    this.save('isProspect', isProspect.toString());
    this.isProspect.next(isProspect);
  }

  getPlan(): Plan {
    return this.load('plan');
  }

  savePlan(plan: Plan): void {
    this.save('plan', plan);
  }

  getActivateFormData(): Activate {
    return JSON.parse(localStorage.getItem('activate'));
  }

  saveActivateFormData(activate: Activate): void {
    this.save('activate', activate);
  }

  getContractFormData(): ContractData {
    return this.load('contractData');
  }

  saveContractFormData(contractData: ContractData): void {
    this.save('contractData', contractData);
  }

  getAccountManagementFormData(): AccountManagement {
    return this.load('accountManagement');
  }

  saveAccountManagementFormData(accountManagement: AccountManagement): void {
    this.save('accountManagement', accountManagement);
  }

  getSignatureFormData(): Signature {
    return this.load('signature');
  }

  saveSignatureFormData(signature: Signature): void {
    this.save('signature', signature);
  }

  getPaymentFormData(): Payment {
    return this.load('payment');
  }

  savePaymentFormData(payment: Payment): void {
    this.save('payment', payment);
  }

  getUploadedFilesData(): SessionFiles {
    return this.load('files');
  }

  saveUploadedFiles(files: SessionFiles): void {
    let sessionFiles = JSON.parse(localStorage.getItem('files')) || {};

    localStorage.setItem(
      'files',
      JSON.stringify({
        ...sessionFiles,
        ...files,
      }),
    );
  }

  resetUploadedFiles(): void {
    this.save('files', {});
  }

  getStepSession(): Array<Step> {
    return this.load('steps');
  }

  saveStepSession(steps: Array<Step>): void {
    this.save('steps', steps);
  }

  getLandingRoute(): string {
    return localStorage.getItem('landingRoute');
  }

  saveLandingRoute(landingRoute: string): void {
    this.save('landingRoute', landingRoute);
  }

  clear(): void {
    this.jwt.next(null);
    localStorage.removeItem('jwt');
    localStorage.removeItem('plan');
    localStorage.removeItem('activate');
    localStorage.removeItem('contractData');
    localStorage.removeItem('accountManagement');
    localStorage.removeItem('signature');
    localStorage.removeItem('payment');
    localStorage.removeItem('files');
  }
}
