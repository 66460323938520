<app-navbar></app-navbar>
<div class="payment-container">
  <div class="page-container">
    <mat-card class="submit-error on-top mat-elevation-z0" *ngIf="showPaymentError">
      <mat-list>
        <mat-list-item>
          <mat-icon mat-list-icon>announcement</mat-icon>
          <div mat-line><h2>Non è stato possibile completare il pagamento</h2></div>
          <div mat-line>
            Scegli un metodo di pagamento alternativo o riprova più tardi
          </div>
        </mat-list-item>
      </mat-list>
    </mat-card>

    <form [formGroup]="paymentForm">
      <div class="form-container billing">
        <div class="form-title">
          <h3>Dati di fatturazione</h3>
        </div>
        <div class="form-section">
          <app-text-input
            type="text"
            label="Indirizzo di posta certificata"
            [group]="paymentForm"
            controlName="pec"
            [controlObject]="paymentForm.controls.pec"
            [isRequired]="true"
          ></app-text-input>
          <app-text-input
            *ngIf="!isProspect"
            type="text"
            label="Codice univoco di fatturazione"
            [group]="paymentForm"
            controlName="ipa"
            [controlObject]="paymentForm.controls.ipa"
            [isRequired]="true"
            hint="Inserisci qui il codice univoco per la fatturazione elettronica"
          ></app-text-input>
        </div>
      </div>

      <div class="form-container preview">
        <div class="form-title">
          <h3>Riepilogo acquisto</h3>
        </div>
        <div class="payment-checkout">
          <ng-container *ngIf="!!plan.name">
            <p class="mat-subheading-2">Sottoscrizione annuale a:</p>
            <p class="mat-subheading-2 blue">{{ plan.name }}</p>
          </ng-container>
          <p class="mat-subheading-2">Importo (comprensivo d'IVA):</p>
          <p class="mat-headline primary">
            {{ plan.discountedPrice || plan.fullPrice }}€
          </p>
          <ng-container *ngIf="plan.type === 'recurring'">
            <p class="mat-subheading-2">Importo singola rata (comprensivo d'IVA):</p>
            <p class="mat-headline primary">{{ plan.installmentPrice }}€</p>
            <p class="mat-subheading-2">Numero rate:</p>
            <p class="mat-headline primary">{{ plan.installments }}</p>
          </ng-container>
          <ng-container *ngIf="hasDelay">
            <p class="mat-subheading-2">Prima rata tra:</p>
            <p class="mat-headline primary">{{ plan.delay }} giorni</p>
          </ng-container>
        </div>
      </div>

      <div class="form-container methods" *ngIf="requiredPayment">
        <div class="form-title">
          <h3>Metodo di pagamento</h3>
        </div>
        <div class="form-section">
          <div class="payment-methods">
            <mat-accordion>
              <!-- Paypal -->

              <mat-expansion-panel
                [expanded]="true"
                class="mat-elevation-z0"
                *ngIf="paymentMethodIsAvailable(paymentMethods.PayPal)"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title> Paypal / Carta di credito </mat-panel-title>
                </mat-expansion-panel-header>

                <app-checkbox-input
                  label=""
                  [group]="paymentForm"
                  controlName="paypalCheck"
                  [controlObject]="paymentForm.controls.paypalCheck"
                >
                  <span
                    >PayPal / Carta di credito
                    <div>
                      <img
                        src="https://www.paypalobjects.com/webstatic/mktg/logo-center/logo_paypal_pagamento.jpg"
                      />
                    </div>
                  </span>
                </app-checkbox-input>
              </mat-expansion-panel>
              <!-- / Paypal -->
            </mat-accordion>
          </div>
        </div>
      </div>
    </form>

    <mat-card
      class="submit-error mat-elevation-z0"
      *ngIf="paymentForm.errors?.validationMessage"
    >
      <mat-list>
        <mat-list-item>
          <mat-icon mat-list-icon>announcement</mat-icon>
          <div mat-line>{{ paymentForm.errors.validationMessage }}</div>
        </mat-list-item>
      </mat-list>
    </mat-card>

    <div class="form-buttons" #formButtons>
      <button mat-button color="primary" (click)="saveDraft()">Indietro</button>

      <ng-container *ngIf="requiredPayment">
        <button
          mat-flat-button
          color="primary"
          [disabled]="paymentForm.pending"
          (click)="submitForm()"
        >
          Completa l'iscrizione
        </button>
      </ng-container>
      <ng-container *ngIf="!requiredPayment">
        <button
          mat-flat-button
          color="primary"
          [disabled]="paymentForm.pending"
          (click)="submitContract()"
        >
          Conferma
        </button>
      </ng-container>
    </div>
  </div>
</div>
