/**
 * Data model for 1st step of activate form
 *
 * @export
 * @class ContractData
 */

import { City } from '../../lib/models/city';

export class ContractData {
  businessName?: string = null;
  vatNumber?: string = null;
  mobileNumber?: string = null;
  email?: string = null;
  pec?: string = null;
  supplyChainPosition?: string = null;
  productCategory?: string = null;
  businessType?: string = null;
  tag?: string[] = [];
  website?: string = null;
  employees?: number = null;
  lastRevenue?: number = null;
  directorFirstName?: string = null;
  directorLastName?: string = null;
  directorFiscalCode?: string = null;
  directorId?: string = null;
  directorBirthPlace?: string = null;
  directorBirthDate?: string = null;
  legalAddress?: string = null;
  legalAddressNumber?: string = null;
  legalCity?: City = null;
  legalZip?: string = null;
  hqAddress?: string = null;
  hqAddressNumber?: string = null;
  hqCity?: City = null;
  hqZip?: string = null;
  paymentRequired?: boolean = null;
  isProspect?: boolean = null;
}
