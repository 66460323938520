/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Plan } from '../models/plan';


/**
 * Activate offer workflow
 */
@Injectable({
  providedIn: 'root',
})
export class InitializeAgreementService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation initialize
   */
  static readonly InitializePath = '/initialize/{agreement_slug}';

  /**
   * Ask for plan list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `initialize()` instead.
   *
   * This method doesn't expect any request body.
   */
  initialize$Response(params: {

    /**
     * Agreement Slug to identify the agreement
     */
    agreementSlug: string;

    /**
     * Optional Link Id from Link Generator
     */
    linkId?: string;

  }): Observable<StrictHttpResponse<{ 'plans'?: Plan, 'paymentRequired'?: boolean, 'isProspect'?: boolean, 'token'?: string }>> {

    const rb = new RequestBuilder(this.rootUrl, InitializeAgreementService.InitializePath, 'get');
    if (params) {

      rb.path('agreement_slug', params.agreementSlug);
      rb.query('linkId', params.linkId);

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'plans'?: Plan, 'paymentRequired'?: boolean, 'isProspect'?: boolean, 'token'?: string }>;
      })
    );
  }

  /**
   * Ask for plan list
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `initialize$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  initialize(params: {

    /**
     * Agreement Slug to identify the agreement
     */
    agreementSlug: string;

    /**
     * Optional Link Id from Link Generator
     */
    linkId?: string;

  }): Observable<{ 'plans'?: Plan, 'paymentRequired'?: boolean, 'isProspect'?: boolean, 'token'?: string }> {

    return this.initialize$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'plans'?: Plan, 'paymentRequired'?: boolean, 'isProspect'?: boolean, 'token'?: string }>) => r.body as { 'plans'?: Plan, 'paymentRequired'?: boolean, 'isProspect'?: boolean, 'token'?: string })
    );
  }

  /**
   * Path part for operation listproducts
   */
  static readonly ListproductsPath = '/listproducts/{agreement_slug}';

  /**
   * Ask for plan list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listproducts()` instead.
   *
   * This method doesn't expect any request body.
   */
  listproducts$Response(params: {

    /**
     * Agreement Slug to identify the agreement
     */
    agreementSlug: string;

  }): Observable<StrictHttpResponse<{ 'plans'?: Plan }>> {

    const rb = new RequestBuilder(this.rootUrl, InitializeAgreementService.ListproductsPath, 'get');
    if (params) {

      rb.path('agreement_slug', params.agreementSlug);

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'plans'?: Plan }>;
      })
    );
  }

  /**
   * Ask for plan list
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listproducts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listproducts(params: {

    /**
     * Agreement Slug to identify the agreement
     */
    agreementSlug: string;

  }): Observable<{ 'plans'?: Plan }> {

    return this.listproducts$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'plans'?: Plan }>) => r.body as { 'plans'?: Plan })
    );
  }

}
