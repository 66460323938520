import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Plan } from 'src/app/models/plan.model';
import { Signature } from 'src/app/models/signature.model';
import { ApiService } from 'src/app/services/api.service';
import { DialogService } from 'src/app/services/dialog.service';
import { ErrorService } from 'src/app/services/error.service';
import { FormDataService } from 'src/app/services/form-data.service';
import { LoadingService } from 'src/app/services/loading.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { ScrollService } from 'src/app/services/scroll.service';
import { SessionService } from 'src/app/services/session.service';
import { SignatureResponse } from 'src/lib/models/signature-response';
import { SignaturePhoneNumberDialog } from './phone-number-dialog/phone-number-dialog.component';
import { FactoringResponse } from 'src/lib/models/factoring-response';
import { AccountManagement } from 'src/app/models/account-management.model';

@Component({
  selector: 'signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss'],
})
export class SignatureComponent implements OnInit {
  plan: Plan;
  signatureForm: UntypedFormGroup;
  accountManagementData: AccountManagement;
  isProspect: boolean;
  hasDelay: boolean;
  requiredPayment: boolean;

  constructor(
    private el: ElementRef,
    private dialogService: DialogService,
    private matDialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private formDataService: FormDataService,
    private loadingService: LoadingService,
    private navbarService: NavbarService,
    private router: Router,
    private apiService: ApiService,
    private sessionService: SessionService,
    private errorService: ErrorService,
    private scrollService: ScrollService,
  ) {
    this.plan = this.sessionService.getPlan();

    this.hasDelay = this.plan.delay && this.plan.delay > 0;
    this.requiredPayment = this.sessionService.getRequiredPayment();
    this.isProspect = this.sessionService.getIsProspect();
  }

  ngOnInit(): void {
    this.signatureForm = this._buildForm(this.sessionService.getSignatureFormData());
    this.accountManagementData = this.sessionService.getAccountManagementFormData();
    this.signatureForm.controls['signatureMobilePhone'].setValue(
      this.accountManagementData?.mobileNumber,
    );

    /**
     * Checks if 'factoring' in signatureForm is null. If so, initiates a loading process,
     * makes an API call to fetch factoring data, updates the form with the response,
     * and sets 'factoring' to 'deny' if falsy and the context is for a prospect.
     */

    if (this.signatureForm.controls['factoring'].value === null && this.isProspect) {
      this.loadingService.startSpinner();
      this.apiService
        .getFactoring(this.plan.id)
        .subscribe((response: FactoringResponse) => {
          this.loadingService.stopSpinner();

          this.signatureForm.controls.factoring.setValue(response.factoring);

          const factoringValue = this.signatureForm.controls['factoring'].value;
          if (!factoringValue) {
            this.signatureForm.controls['factoring'].setValue('deny');
          }
        });
    }

    this.signatureForm.statusChanges
      .pipe(filter(() => !this.signatureForm.valid))
      .subscribe(() => this._onFormInValid());
  }

  saveDraft() {
    this.sessionService.saveSignatureFormData(this.signatureForm.value);

    this.router.navigate(['/account-management']);
  }

  /**
   * Opens the phone number dialog and updates the signature form with the selected phone number.
   * Submits the form after updating the phone number.
   */
  openPhoneNumberDialog() {
    this.matDialog
      .open(SignaturePhoneNumberDialog, {
        width: '450px',
        data: this.signatureForm,
      })
      .afterClosed()
      .subscribe((phoneNumber) => {
        this.signatureForm.setValue({
          ...this.signatureForm.value,
          signatureMobilePhone: phoneNumber ?? '',
        });
        this.submitForm();
      });
  }

  submitForm() {
    // Che the form valid before sending to the backend
    if (this.signatureForm.invalid) {
      this.signatureForm.setErrors({
        validationMessage:
          'Errore durante il salvataggio. Correggi i campi non validi e riprova.',
      });
      this.signatureForm.markAllAsTouched();

      return;
    }
    this.loadingService.startSpinner();

    const formData: any = {
      ...this.signatureForm.value,
    };

    this.apiService.submitSignatureForm(formData).subscribe(
      (response: SignatureResponse) => {
        this.formDataService.saveSignatureForm(response);

        // YouSign Flow
        const youSignUrl = response?.signatureLink;
        if (!youSignUrl) {
          throw new Error('YouSign URL not found');
        }

        window.location.href = youSignUrl;
      },
      (errorResponse: HttpErrorResponse) => {
        this.loadingService.stopSpinner();
        this._handleError(errorResponse);

        // SMARMELLO: Remove setTimeout, the class is added after few ms...
        setTimeout(() => {
          const firstInvalid = this._getFirstInvalidControlPosition();
          this.scrollService.scrollToCoords(firstInvalid);
        }, 50);
      },
    );
  }

  /**
   * Opens a textual dialog.
   *
   * @param dialogDataSlug - The slug of the dialog data.
   */
  openTextualDialog(dialogDataSlug: string) {
    this.dialogService.open(dialogDataSlug);
  }

  private _getFirstInvalidControlPosition(): number {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      '.mat-form-field-invalid',
    );

    if (!firstInvalidControl) {
      return null;
    }

    return firstInvalidControl.offsetTop;
  }

  private _onFormInValid() {
    this.navbarService.disableStep('payment');
  }

  private _buildForm(formDefaultValues: Signature): UntypedFormGroup {
    return this.formBuilder.group({
      termsAcceptance: [formDefaultValues.termsAcceptance, Validators.requiredTrue],
      factoring: [
        formDefaultValues.factoring,
        [Validators.required, Validators.pattern('^accept|deny$')],
      ],
      privacyPolicyProfiling: [
        formDefaultValues.privacyPolicyProfiling,
        [Validators.required, Validators.pattern('^accept|deny$')],
      ],
      privacyPolicyMarketing: [
        formDefaultValues.privacyPolicyMarketing,
        [Validators.required, Validators.pattern('^accept|deny$')],
      ],
      privacyPolicyMarketingThirdParty: [
        formDefaultValues.privacyPolicyMarketingThirdParty,
        [Validators.required, Validators.pattern('^accept|deny$')],
      ],
      signatureMobilePhone: [
        formDefaultValues.signatureMobilePhone,
        [Validators.required, Validators.pattern('^\\d{10}$')],
      ],
    });
  }

  private _handleError(errorResponse: HttpErrorResponse): void {
    this.signatureForm = this.errorService._handleSubmitError(
      this.signatureForm,
      errorResponse,
    );
  }
}
