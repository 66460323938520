<mat-form-field
  appearance="fill"
  [formGroup]="group"
  *ngIf="!(fileContent$ | async)"
  class="{{ controlName }}"
>
  <mat-label>{{ label }}</mat-label>
  <ngx-mat-file-input
    #realFileInput
    [id]="controlName"
    [placeholder]="placeholder"
    [formControlName]="controlName"
    [multiple]="false"
    accept=".png,.jpeg,.jpg,.pdf"
    [required]="isRequired"
  >
  </ngx-mat-file-input>
  <button
    mat-icon-button
    matSuffix
    type="button"
    *ngIf="!realFileInput.empty && !isUploading"
    (click)="realFileInput.clear($event)"
  >
    <mat-icon>clear</mat-icon>
  </button>
  <mat-icon *ngIf="realFileInput.empty && !isUploading" matSuffix>folder</mat-icon>
  <mat-spinner *ngIf="isUploading" diameter="25" color="accent" matSuffix></mat-spinner>
  <mat-error>
    <app-input-validation [controlObject]="controlObject"></app-input-validation>
  </mat-error>
  <mat-error *ngIf="controlObject.hasError('maxContentSize')">
    La dimensione del file non deve essere superiore a
    {{ controlObject.getError('maxContentSize').maxSize | byteFormat }}.<br />
    La dimensione attuale è
    {{ controlObject.getError('maxContentSize').actualSize | byteFormat }}.
  </mat-error>
  <mat-hint>{{ hint }}</mat-hint>
</mat-form-field>

<mat-form-field
  class="fake-input {{ controlName }}"
  appearance="fill"
  *ngIf="fileContent$ | async"
  (click)="uploadNewFile(controlName)"
>
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    type="text"
    [value]="fakeFileName"
    disabled
    [required]="isRequired"
    (click)="uploadNewFile(controlName)"
  />
  <button
    class="remove-file"
    type="button"
    mat-icon-button
    matSuffix
    [disabled]="controlObject.disabled"
    (click)="uploadNewFile(controlName)"
  >
    <mat-icon>clear</mat-icon>
  </button>
  <mat-hint *ngIf="consistencyInfoWarning$ | async" class="consistency-warning">{{
    warningMessage
  }}</mat-hint>
</mat-form-field>
