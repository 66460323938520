<mat-form-field [formGroup]="group" appearance="fill">
  <mat-label>{{ label }}</mat-label>
  <mat-chip-list #chipList aria-label="Le tue parole chiave" [disabled]="chipsDisabled">
    <mat-chip
      *ngFor="let tag of selectedTags"
      [selectable]="selectable"
      [removable]="removable"
      (removed)="remove(tag)"
    >
      {{ tag }}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip>
    <input
      [placeholder]="placeholder"
      #tagsInput
      [formControlName]="controlName"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)"
      (blur)="resetInputValue()"
    />
  </mat-chip-list>
  <mat-spinner *ngIf="isLoading" diameter="25" color="accent" matSuffix></mat-spinner>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let tag of filteredTags | async" [value]="selectedTags">
      {{ tag }}
    </mat-option>
  </mat-autocomplete>
  <mat-hint>{{ hint }}</mat-hint>
  <mat-error>
    <app-input-validation [controlObject]="controlObject"></app-input-validation>
  </mat-error>
</mat-form-field>
