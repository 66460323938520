/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';



/**
 * PayPal Payment Endpoints
 */
@Injectable({
  providedIn: 'root',
})
export class PayPalService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation createPayPalPayment
   */
  static readonly CreatePayPalPaymentPath = '/paypal/createpayment';

  /**
   * Initiate a PayPal Payment for the Client, returning the PaymentID parameter
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPayPalPayment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPayPalPayment$Response(params: {
      body: { 'plan_id'?: string }
  }): Observable<StrictHttpResponse<{ 'payPalStatusCode'?: number, 'orderId'?: string, 'paymentURL'?: string }>> {

    const rb = new RequestBuilder(this.rootUrl, PayPalService.CreatePayPalPaymentPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'payPalStatusCode'?: number, 'orderId'?: string, 'paymentURL'?: string }>;
      })
    );
  }

  /**
   * Initiate a PayPal Payment for the Client, returning the PaymentID parameter
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createPayPalPayment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPayPalPayment(params: {
      body: { 'plan_id'?: string }
  }): Observable<{ 'payPalStatusCode'?: number, 'orderId'?: string, 'paymentURL'?: string }> {

    return this.createPayPalPayment$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'payPalStatusCode'?: number, 'orderId'?: string, 'paymentURL'?: string }>) => r.body as { 'payPalStatusCode'?: number, 'orderId'?: string, 'paymentURL'?: string })
    );
  }

  /**
   * Path part for operation confirmPayPalPayment
   */
  static readonly ConfirmPayPalPaymentPath = '/paypal/confirmpayment';

  /**
   * Confirm a PayPal Payment the buyer should've already performed
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `confirmPayPalPayment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  confirmPayPalPayment$Response(params: {
      body: { 'orderId'?: string }
  }): Observable<StrictHttpResponse<{ 'paymentStatus'?: string, 'orderId'?: string, 'message'?: string }>> {

    const rb = new RequestBuilder(this.rootUrl, PayPalService.ConfirmPayPalPaymentPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'paymentStatus'?: string, 'orderId'?: string, 'message'?: string }>;
      })
    );
  }

  /**
   * Confirm a PayPal Payment the buyer should've already performed
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `confirmPayPalPayment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  confirmPayPalPayment(params: {
      body: { 'orderId'?: string }
  }): Observable<{ 'paymentStatus'?: string, 'orderId'?: string, 'message'?: string }> {

    return this.confirmPayPalPayment$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'paymentStatus'?: string, 'orderId'?: string, 'message'?: string }>) => r.body as { 'paymentStatus'?: string, 'orderId'?: string, 'message'?: string })
    );
  }

}
