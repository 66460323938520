<mat-toolbar class="mat-elevation-z2">
  <div class="header-container">
    <a [href]="landingRoute" alt="Home">
      <img src="/assets/sardex_logo.svg" width="147" />
    </a>
    <span class="spacer"></span>

    <nav class="mat-subheading-2" *ngIf="showScrollNav">
      <ul>
        <li *ngFor="let navElement of navElements">
          <a
            [href]="'#' + navElement.slug"
            (click)="scrollTo(navElement.slug); $event.preventDefault()"
            >{{ navElement.title }}</a
          >
        </li>
      </ul>
    </nav>
  </div>
</mat-toolbar>

<ng-container *ngIf="!isProduction">
  <mat-toolbar-row class="env-alert">
    <mat-icon>warning</mat-icon>
    <strong>Attenzione: Ambiente di test</strong>
  </mat-toolbar-row>
</ng-container>
