import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Plan } from '../models/plan.model';
import { FormDataService } from './form-data.service';

import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root',
})
export class ActivatePlanGuardService implements CanActivate {
  constructor(
    public router: Router,
    private formDataService: FormDataService,
    private sessionService: SessionService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const plan: Plan = this.sessionService.getPlan();

    if (!plan) {
      this.router.navigate([`/`]);
    }

    return true;
  }
}
