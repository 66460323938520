import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { TextInputComponent } from './text-input/text-input.component';
import { DateInputComponent } from './date-input/date-input.component';
import { SelectInputComponent } from './select-input/select-input.component';
import { InputValidationComponent } from './input-validation/input-validation.component';
import { CheckboxInputComponent } from './checkbox-input/checkbox-input.component';
import { TagsInputComponent } from './tags-input/tags-input.component';

import { MaterialModule } from 'src/app/shared/material/material.module';
import { FileInputComponent } from './file-input/file-input.component';
import { SupplyChainPositionComponent } from './supply-chain-position/supply-chain-position.component';
import { ProductCategoryComponent } from './product-category/product-category.component';
import { BusinessTypeComponent } from './business-type/business-type.component';
import { CityInputComponent } from './city-input/city-input.component';
import { ZipInputComponent } from './zip-input/zip-input.component';

@NgModule({
  declarations: [
    TextInputComponent,
    DateInputComponent,
    SelectInputComponent,
    InputValidationComponent,
    CheckboxInputComponent,
    FileInputComponent,
    TagsInputComponent,
    SupplyChainPositionComponent,
    ProductCategoryComponent,
    BusinessTypeComponent,
    CityInputComponent,
    ZipInputComponent,
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule],
  exports: [
    TextInputComponent,
    DateInputComponent,
    SelectInputComponent,
    InputValidationComponent,
    CheckboxInputComponent,
    FileInputComponent,
    TagsInputComponent,
    SupplyChainPositionComponent,
    ProductCategoryComponent,
    BusinessTypeComponent,
    CityInputComponent,
    ZipInputComponent,
  ],
})
export class FormInputModule {}
