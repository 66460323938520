import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { CategoriesService } from 'src/lib/services';
import { SessionService } from '../../../services/session.service';

@Component({
  selector: 'app-business-type',
  templateUrl: './business-type.component.html',
  styleUrls: ['./business-type.component.scss'],
})
export class BusinessTypeComponent implements OnChanges {
  @Input() supplyChainPosition: string;
  @Input() productCategory: string;
  @Input() label: string;
  @Input() hint?: string;
  @Input() isRequired?: boolean;
  @Input() group: UntypedFormGroup;
  @Input() controlName: string;
  @Input() placeholder?: string = '';
  @Input() controlObject: UntypedFormControl;
  @Input() disabled: boolean;

  isLoading: boolean;
  productCategoryCategories: string[];
  filteredOptions: Observable<string[]>;

  constructor(
    private categoriesService: CategoriesService,
    private sessionService: SessionService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    this.controlObject.disable();

    const { productCategory } = changes;

    if (!!productCategory) {
      if (!productCategory.isFirstChange()) {
        this.controlObject.reset();
      }

      if (changes.productCategory.currentValue === null) {
        this.controlObject.disable();

        return;
      }

      this.isLoading = true;
      this._fetchCategories()
        .pipe(take(1))
        .subscribe((res) => {
          if(!changes['disabled']?.currentValue) {
            this.controlObject.enable();
          }
          this.isLoading = false;
          this.productCategoryCategories = res;

          const formData = this.sessionService.getContractFormData();

          this.sessionService.saveContractFormData({
            ...formData,
            tag: [],
          });
        });
    }
  }

  private _fetchCategories(): Observable<string[]> {
    return this.categoriesService.settore({
      body: {
        posizionamento_in_filiera: this.supplyChainPosition,
        settore_merceologico: this.productCategory,
      },
    });
  }
}
