<div class="navbar-title">
  <ng-container *ngIf="requiredPayment">
    <h1>Apri subito il tuo conto Sardex in pochi passi</h1>
  </ng-container>
  <ng-container *ngIf="!requiredPayment">
    <h1>Sottoscrivi il tuo contratto SardexPay in pochi passi</h1>
  </ng-container>
</div>
<nav class="navbar-steps">
  <ul [style.grid-template-columns]="gridColumnStyle">
    <ng-container *ngFor="let step of steps; let index = index; let isLast = last">
      <li matRipple>
        <a
          routerLink="/{{ step.slug }}"
          routerLinkActive="active"
          [class.disabled]="!isEnabled(step.slug)"
        >
          <span class="step-dot">
            <span>{{ index + 1 }}</span>
          </span>
          {{ step.title }}
        </a>
      </li>
      <!-- Add the line only if it's not the last item -->
      <li *ngIf="!isLast" class="line"></li>
    </ng-container>
  </ul>
</nav>
