import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { CategoriesService } from 'src/lib/services';

@Component({
  selector: 'app-product-category',
  templateUrl: './product-category.component.html',
  styleUrls: ['./product-category.component.scss'],
})
export class ProductCategoryComponent implements OnChanges {
  @Input() supplyChainPosition: string;
  @Input() label: string;
  @Input() hint?: string;
  @Input() isRequired?: boolean;
  @Input() group: UntypedFormGroup;
  @Input() controlName: string;
  @Input() placeholder?: string = '';
  @Input() controlObject: UntypedFormControl;
  @Input() disabled: boolean;

  isLoading: boolean;
  productCategoryCategories: string[];

  constructor(private categoriesService: CategoriesService) {}

  ngOnChanges(changes: SimpleChanges) {
    this.controlObject.disable();

    if (!!changes.supplyChainPosition) {
      if (!changes.supplyChainPosition.isFirstChange()) {
        this.controlObject.reset();
      }

      if (
        changes.supplyChainPosition.isFirstChange() &&
        changes.supplyChainPosition.currentValue === null
      ) {
        this.controlObject.disable();

        return;
      }

      this.isLoading = true;
      this._fetchCategories()
        .pipe(take(1))
        .subscribe((res) => {
          if(!changes['disabled']?.currentValue) {
            this.controlObject.enable();
          }
          this.isLoading = false;
          this.productCategoryCategories = res;
        });
    }
  }

  private _fetchCategories(): Observable<string[]> {
    return this.categoriesService
      .posizionamento({
        body: {
          posizionamento_in_filiera: this.supplyChainPosition,
        },
      })
      .pipe(map((res) => res));
  }
}
