import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['./select-input.component.scss'],
})
export class SelectInputComponent implements OnInit {
  @Input() label: string;
  @Input() hint?: string;
  @Input() group: UntypedFormGroup;
  @Input() controlName: string;
  @Input() placeholder?: string = '';
  @Input() controlObject: UntypedFormControl;
  @Input() optionsData?: any = [];
  @Input() isRequired?: boolean;

  constructor() {}

  ngOnInit(): void {}
}
