import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Subscription, iif, of, Observable } from 'rxjs';
import { switchMap, tap, map } from 'rxjs/operators';

import { City } from '../../../../lib/models/city';

import { ApiService } from '../../../services/api.service';

@Component({
  selector: 'app-zip-input',
  templateUrl: './zip-input.component.html',
  styleUrls: ['./zip-input.component.scss'],
})
export class ZipInputComponent implements OnInit, OnDestroy {
  @Input() label: string;
  @Input() group: UntypedFormGroup;
  @Input() controlObject: UntypedFormControl;
  @Input() controlName: string;
  @Input() linkedControlObject: UntypedFormControl;
  @Input() isRequired?: boolean;
  @Input() clonedLegalZip?: string;

  zipSubscription: Subscription;
  filteredZipCodes: string[];
  selected: string;

  constructor(private apiService: ApiService) {}

  ngOnInit(): void {
    const defaultValue = this.controlObject.value[0];
    if (this.linkedControlObject.value !== null) {
      this._getZipCodes(this.linkedControlObject.value.id).subscribe(
        (zipCodes: string[]) => {
          this.filteredZipCodes = zipCodes;

          this.selected = defaultValue;
        },
      );
    }

    this.zipSubscription = this.linkedControlObject.valueChanges
      .pipe(
        switchMap((res: City) =>
          iif(() => res.id !== undefined, this._getZipCodes(res.id), of([])),
        ),
      )
      .subscribe((zipCodes: string[]) => {
        this.filteredZipCodes = zipCodes;
        if (this.clonedLegalZip) {
          this.controlObject.setValue(this.clonedLegalZip);
        }
      });
  }

  ngOnDestroy(): void {
    this.zipSubscription && this.zipSubscription.unsubscribe();
  }

  private _getZipCodes(cityId: string): Observable<string[]> {
    return this.apiService.getZip(cityId).pipe(
      tap((zipCodes: string[]) => {
        this.controlObject.reset();
        zipCodes.length > 0 ? this.controlObject.enable() : this.controlObject.disable();
      }),
      map((zipCodes: string[]) => zipCodes),
    );
  }
}
