<app-navbar></app-navbar>
<div class="page-container">
  <div class="form-container preview">
    <div class="form-title">
      <h3>Riepilogo acquisto</h3>
    </div>
    <div class="payment-checkout">
      <ng-container *ngIf="!!plan.name">
        <p class="mat-subheading-2">Sottoscrizione annuale a:</p>
        <p class="mat-subheading-2 blue">{{ plan.name }}</p>
      </ng-container>
    </div>
  </div>

  <div *ngIf="accountManagementData.sepa">
    <div class="form-container">
      <div class="form-title">
        <h3>Mandato per Addebito Diretto Unico Europeo</h3>
      </div>
      <div class="form-container">
        <p>
          La sottoscrizione del presente mandato comporta l’autorizzazione a Sardex SpA a
          richiedere alla banca del debitore l’addebito del suo conto e l’autorizzazione
          alla banca del debitore di procedere a tale addebito conformemente alle
          disposizioni impartite da Sardex SpA. Il debitore ha diritto di ottenere il
          rimborso dalla propria Banca secondo gli accordi ed alle condizioni che regolano
          il rapporto con quest’ultima. Se del caso, il rimborso deve essere richiesto nel
          termine di 8 settimane a decorrere dalla data di addebito in conto.
        </p>

        <div class="other-info-container">
          <div>
            <p><b>Nome del debitore</b> {{ accountManagementData.debtorName }}</p>
            <p><b>Indirizzo</b> {{ accountManagementData.debtorAddress }}</p>
            <p><b>Località e CAP</b> {{ accountManagementData.debtorPlaceAndZipCode }}</p>
            <p><b>Numero del conto (IBAN)</b> {{ accountManagementData.debtorIBAN }}</p>
            <p>
              <b>Nome della Banca e Filiale (BIC)</b>
              {{ accountManagementData.bankDetails }}
            </p>
          </div>
          <div>
            <p><b>Nome del creditore</b> {{ accountManagementData.creditorName }}</p>
            <p><b>Numero del conto (IBAN)</b> {{ accountManagementData.creditorIBAN }}</p>
            <p><b>Indirizzo</b> {{ accountManagementData.creditorAddress }}</p>
            <p>
              <b>Località e CAP</b> {{ accountManagementData.creditorPlaceAndZipCode }}
            </p>
            <p><b>Tipo di pagamento</b> Ricorrente</p>
          </div>
        </div>

        <p style="font-weight: bold; margin-top: 20px">Note</p>
        <p>
          I diritti del sottoscrittore del presente mandato sono indicati nella
          documentazione ottenibile dalla propria banca. L’uso del marchio SEPA è sotto
          l’autorizzazione del Consiglio Europeo di Pagamenti.
        </p>
      </div>
    </div>
  </div>

  <form [formGroup]="signatureForm">
    <div class="form-container">
      <div class="form-title">
        <h3>Termini e condizioni</h3>
      </div>
      <div class="form-section">
        <div [formGroup]="signatureForm">
          <mat-checkbox
            id="termsAcceptance"
            name="termsAcceptance"
            formControlName="termsAcceptance"
            color="primary"
            >Accetto
            <a
              href="#"
              (click)="openTextualDialog('terminiCondizioni'); $event.preventDefault()"
              >termini e condizioni</a
            >
          </mat-checkbox>
          <mat-error>
            <app-input-validation
              [controlObject]="signatureForm.controls.termsAcceptance"
            ></app-input-validation>
          </mat-error>
        </div>
      </div>
    </div>

    <div class="form-container">
      <p style="margin-top: 30px">
        Ai sensi e per gli effetti dell’art. 1264 C.C., i crediti vantati da Sardex nei
        confronti dell’Iscritto per i corrispettivi di cui all’art. 4 del presente
        contratto, potranno essere ceduti da Sardex a Grenke Bank A.G. Branch Italy (il
        “Cessionario”) con sede in via Montefeltro n. 4, 20156 Milano (MI) in conformità
        alla Legge n. 52 del 21 febbraio 1991.<br />
        Al verificarsi di tale eventualità, l’Iscritto, che verrà avvisato dal Cessionario
        mediante notifica via PEC, dovrà effettuare il pagamento dei suddetti
        corrispettivi al Cessionario medesimo a mezzo RIBA o bonifico bancario all’IBAN
        IT98B0310401600000000770686.
      </p>

      <mat-radio-group
        class="factoring"
        aria-label="Seleziona un opzione"
        [formGroup]="signatureForm"
        name="factoring"
        [disabled]="isProspect"
        formControlName="factoring"
        color="primary"
      >
        <mat-radio-button value="accept" class="accept">Si acconsento</mat-radio-button>
        <mat-radio-button value="deny" class="deny">No, non acconsento</mat-radio-button>
      </mat-radio-group>
      <mat-error>
        <app-input-validation
          [controlObject]="signatureForm.controls.factoring"
        ></app-input-validation>
      </mat-error>
    </div>

    <div class="form-container">
      <div class="form-title">
        <h3>Privacy policy</h3>
      </div>
      <p>
        <a
          href="#"
          (click)="openTextualDialog('privacyPolicyBase'); $event.preventDefault()"
          >Leggi il testo integrale</a
        >
      </p>

      <p class="privacy-title mat-body-2">CONSENSO PER FINALITA’ DI PROFILAZIONE</p>
      <p class="privacy-description mat-body-2">
        (attività di profilazione strumentale all’invio di comunicazioni di marketing
        personalizzate)
      </p>
      <mat-radio-group
        class="privacyPolicyProfiling"
        aria-label="Seleziona un opzione"
        [formGroup]="signatureForm"
        name="privacyPolicyProfiling"
        formControlName="privacyPolicyProfiling"
        color="primary"
      >
        <mat-radio-button value="accept" class="accept">Do il consenso</mat-radio-button>
        <mat-radio-button value="deny" class="deny">Nego il consenso</mat-radio-button>
      </mat-radio-group>
      <mat-error>
        <app-input-validation
          [controlObject]="signatureForm.controls.privacyPolicyProfiling"
        ></app-input-validation>
      </mat-error>

      <p class="privacy-title mat-body-2">
        CONSENSO PER FINALITA’ COMMERCIALI DI MARKETING DIRETTO
      </p>
      <p class="privacy-description mat-body-2">
        (invio di materiale pubblicitario rivolto alla vendita, a ricerche di mercato o
        sondaggi di opinione, per comunicazioni promozionali e commerciali o per
        informarti e proporti l’acquisizione di prodotti o servizi analoghi a quelli già
        acquistati, mediante e-mail, sms, canali digitali, contatto telefonico con o senza
        operatore e/o posta ordinaria)
      </p>
      <mat-radio-group
        class="privacyPolicyMarketing"
        aria-label="Seleziona un opzione"
        [formGroup]="signatureForm"
        name="privacyPolicyMarketing"
        formControlName="privacyPolicyMarketing"
        color="primary"
      >
        <mat-radio-button value="accept" class="accept">Do il consenso</mat-radio-button>
        <mat-radio-button value="deny" class="deny">Nego il consenso</mat-radio-button>
      </mat-radio-group>
      <mat-error>
        <app-input-validation
          [controlObject]="signatureForm.controls.privacyPolicyMarketing"
        ></app-input-validation>
      </mat-error>

      <p class="privacy-title mat-body-2">
        CONSENSO PER FINALITA’ COMMERCIALI di MARKETING INDIRETTO O DI TERZE PARTI
      </p>
      <p class="privacy-description mat-body-2">
        (comunicazione dei tuoi dati a partner commerciali e/o alle società del gruppo per
        consentire a questi in via autonoma il trattamento per finalità di marketing
        diretto, ricerche e indagini di mercato)
      </p>
      <mat-radio-group
        class="privacyPolicyMarketingThirdParty"
        aria-label="Seleziona un opzione"
        [formGroup]="signatureForm"
        name="privacyPolicyMarketingThirdParty"
        formControlName="privacyPolicyMarketingThirdParty"
        color="primary"
      >
        <mat-radio-button value="accept" class="accept">Do il consenso</mat-radio-button>
        <mat-radio-button value="deny" class="deny">Nego il consenso</mat-radio-button>
      </mat-radio-group>
      <mat-error>
        <app-input-validation
          [controlObject]="signatureForm.controls.privacyPolicyMarketingThirdParty"
        ></app-input-validation>
      </mat-error>
    </div>
  </form>

  <mat-card
    class="submit-error mat-elevation-z0"
    *ngIf="signatureForm.errors?.validationMessage"
  >
    <mat-list>
      <mat-list-item>
        <mat-icon mat-list-icon>announcement</mat-icon>
        <div mat-line>{{ signatureForm.errors.validationMessage }}</div>
      </mat-list-item>
    </mat-list>
  </mat-card>
</div>

<div class="form-buttons" #formButtons>
  <div class="page-container">
    <button mat-button color="primary" (click)="saveDraft()">Indietro</button>

    <!--TOOD: change this when the document is already signed-->
    <button
      mat-flat-button
      color="primary"
      [disabled]="signatureForm.pending"
      (click)="openPhoneNumberDialog()"
    >
      Firma il contratto
    </button>
  </div>
</div>
