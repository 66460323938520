import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { take, map } from 'rxjs/operators';

import { FileInput } from 'ngx-material-file-input';

import { UploadedFile } from '../models/uploaded-file.model';

import { IdentityDocumentService } from 'src/lib/services';
import { FormDataService } from './form-data.service';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root',
})
export class FileDocumentService {
  directorId$: BehaviorSubject<string>;
  accountManagerId$: BehaviorSubject<string>;
  cardReferentId$: BehaviorSubject<string>;

  constructor(
    private identityDocumentService: IdentityDocumentService,
    private formDataService: FormDataService,
    private sessionService: SessionService,
  ) {
    this.directorId$ = new BehaviorSubject('');
    this.accountManagerId$ = new BehaviorSubject('');
    this.cardReferentId$ = new BehaviorSubject('');
  }

  uploadFile(fileInput: FileInput, controlName: string) {
    return this.identityDocumentService
      .identitydocument({
        body: {
          document: fileInput.files[0],
        },
      })
      .pipe(
        take(1),
        map((res) => {
          const fileDocument: UploadedFile = {
            fileName: fileInput.files[0].name,
            fileKey: res.message,
          };

          switch (controlName) {
            case 'directorId':
              this.formDataService.saveDirectorDocument(fileDocument);
              this.sessionService.saveUploadedFiles({
                directorFile: fileDocument,
              });
              return this.directorId$.next(res.message);
            case 'accountManagerId':
              this.formDataService.saveAccountManagerDocument(fileDocument);
              this.sessionService.saveUploadedFiles({
                accountManagerFile: fileDocument,
              });
              return this.accountManagerId$.next(res.message);
            case 'cardReferentId':
              this.formDataService.saveCardReferentDocument(fileDocument);
              this.sessionService.saveUploadedFiles({
                cardReferentFile: fileDocument,
              });
              this.cardReferentId$.next(res.message);
            default:
              break;
          }
        }),
      );
  }
}
