import { Component } from '@angular/core';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss'],
})
export class ThankYouComponent {
  constructor(private loadingService: LoadingService) {}

  ngOnInit(): void {
    this.loadingService.stopSpinner();
  }
}
