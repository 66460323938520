/**
 * Data model for 3rd step of activate form
 *
 * @export
 * @class Payment
 */

export class Payment {
  pec?: string = null;
  ipa?: string = null;
  payment?: string = null;
  iban?: string = null;
  paypalCheck?: boolean = false;
  instantSepaIBAN?: string = null;
  instantSepaPrivacy?: boolean = false;
  recurringSepaIBAN?: string = null;
  recurringSepaIBANPrivacy?: boolean = false;
}
