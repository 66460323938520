import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { Activate as AtokaActivate } from '../../../lib/models/activate';
import { ContractData as AtokaContractData } from '../../../lib/models/contract-data';

import { Activate } from '../../models/activate.model';
import { Plan } from '../../models/plan.model';

import { ApiService } from '../../services/api.service';
import { DialogService } from '../../services/dialog.service';
import { ErrorService } from '../../services/error.service';
import { FormDataService } from '../../services/form-data.service';
import { LoadingService } from '../../services/loading.service';
import { SessionService } from '../../services/session.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { ScrollService } from 'src/app/services/scroll.service';

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.scss'],
})
export class ActivateComponent implements OnInit, OnDestroy {
  plan: Plan;
  activateForm: UntypedFormGroup;
  requiredPayment: boolean = false;

  constructor(
    private el: ElementRef,
    private apiService: ApiService,
    private dialogService: DialogService,
    private formBuilder: UntypedFormBuilder,
    private formDataService: FormDataService,
    private loadingService: LoadingService,
    private router: Router,
    private sessionService: SessionService,
    private navbarService: NavbarService,
    private errorService: ErrorService,
    private scrollService: ScrollService,
  ) {
    this.plan = this.sessionService.getPlan();
  }

  ngOnInit(): void {
    this.requiredPayment = this.sessionService.getRequiredPayment();

    console.log('requiredPayment', this.requiredPayment);

    this.sessionService.initFormData();
    this.navbarService.resetSteps();
    this.activateForm = this._buildForm(this.sessionService.getActivateFormData());
  }

  offlineActivation() {
    this.loadingService.startSpinner();
    const formData: Activate = this.activateForm.value;

    this.apiService.offlineActivation(formData).subscribe(
      () => {
        this.loadingService.stopSpinner();
        this.router.navigate(['/thank-you']);
      },
      (errorResponse: HttpErrorResponse) => {
        this.loadingService.stopSpinner();
        this._handleError(errorResponse);
        this.scrollService.scrollToCoords(this._getFirstInvalidControlPosition());
      },
    );
  }

  onlineActivation(): void {
    this.loadingService.startSpinner();
    const formData: Activate = this.activateForm.value;

    this.apiService.onlineActivation(formData).subscribe(
      (response: { Activate?: AtokaActivate; ContractData?: AtokaContractData }) => {
        this.formDataService.saveActivateForm(response);
        this.router.navigate(['/contract-data']).then(() => {
          this.loadingService.stopSpinner();
        });
      },
      (errorResponse: HttpErrorResponse) => {
        this.loadingService.stopSpinner();
        this._handleError(errorResponse);

        // SMARMELLO: Remove setTimeout, the class is added after few ms...
        setTimeout(() => {
          const firstInvalid = this._getFirstInvalidControlPosition();
          this.scrollService.scrollToCoords(firstInvalid);
        }, 50);
      },
    );
  }

  openDialog() {
    this.dialogService.open('privacyPolicyBase');
  }

  ngOnDestroy(): void {}

  private _getFirstInvalidControlPosition(): number {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      '.mat-form-field-invalid',
    );

    if (!firstInvalidControl) {
      return null;
    }

    return firstInvalidControl.offsetTop;
  }

  private _buildForm(formDefaultValues: Activate): UntypedFormGroup {
    return this.formBuilder.group({
      firstName: [
        formDefaultValues.firstName,
        [Validators.required, Validators.maxLength(255)],
      ],
      lastName: [
        formDefaultValues.lastName,
        [Validators.required, Validators.maxLength(255)],
      ],
      vatNumber: [
        formDefaultValues.vatNumber,
        [Validators.required, Validators.pattern('^[0-9]{11}$')],
      ],
      mobileNumber: [
        formDefaultValues.mobileNumber,
        [
          Validators.required,
          Validators.pattern(
            '^(\\((00|\\+)39\\)|(00|\\+)39)?\\s?(38[890]|34[4-90]|36[680]|33[13-90]|32[89]|35[01]|37[019])\\s?(\\d{3}\\s?\\d{3,4}|\\d{6,7})$',
          ),
        ],
      ],
      email: [
        formDefaultValues.email,
        [
          Validators.required,
          Validators.pattern(
            "^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$",
          ),
        ],
      ],
      privacyPolicyBase: [formDefaultValues.privacyPolicyBase, Validators.requiredTrue],
    });
  }

  private _handleError(errorResponse: HttpErrorResponse): void {
    this.activateForm = this.errorService._handleSubmitError(
      this.activateForm,
      errorResponse,
    );
  }
}
