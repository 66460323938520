import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { InputTypes } from 'src/app/models/inputTypes.model';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
})
export class TextInputComponent implements OnInit {
  @Input() type: InputTypes;
  @Input() label: string;
  @Input() hint?: string;
  @Input() isRequired?: boolean;
  @Input() group: UntypedFormGroup;
  @Input() controlName: string;
  @Input() placeholder?: string = '';
  @Input() controlObject: UntypedFormControl;
  @Input() iconSuffix: string;

  httpAddedHint: boolean;

  constructor() {}

  ngOnInit(): void {}

  onBlur(): void {
    this.httpAddedHint = false;

    if (this.type === 'url') {
      const urlValue = this.controlObject.value;
      if (urlValue === '') {
        return;
      }

      const re: RegExp = new RegExp(/^(http:|https:)(\/\/).*$/);
      const startWithHttp: boolean = re.test(urlValue);

      if (!startWithHttp) {
        this.httpAddedHint = true;
        this.controlObject.setValue(`http://${urlValue}`);
      }
    }
  }
}
