<mat-form-field [formGroup]="group" appearance="fill">
  <mat-label>{{ label }}</mat-label>
  <mat-select
    [id]="controlName"
    [formControlName]="controlName"
    [(ngModel)]="selected"
    [required]="isRequired"
  >
    <mat-option disabled>Seleziona un cap</mat-option>
    <ng-container *ngFor="let zip of filteredZipCodes">
      <mat-option [value]="zip" *ngIf="zip !== ''">{{ zip }}</mat-option>
    </ng-container>
  </mat-select>

  <mat-error>
    <app-input-validation [controlObject]="controlObject"></app-input-validation>
  </mat-error>
</mat-form-field>
