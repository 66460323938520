import { Injectable } from '@angular/core';

import { Overlay, OverlayConfig, GlobalPositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

import { LoadingComponent } from '../components/loading/loading.component';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private overlayRef;
  private portal: ComponentPortal<LoadingComponent>;
  private _isActive: boolean;

  constructor(private overlay: Overlay) {
    this._isActive = false;

    const positionStrategy: GlobalPositionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();

    const config: OverlayConfig = {
      hasBackdrop: true,
      positionStrategy,
    };

    this.overlayRef = this.overlay.create(config);
    this.portal = new ComponentPortal(LoadingComponent);
  }

  get isActive(): boolean {
    return this._isActive;
  }

  startSpinner() {
    // Attach Ref only is not already attached
    if (!this._isActive) {
      this.overlayRef.attach(this.portal);
    }

    this._isActive = true;
  }

  stopSpinner() {
    this.overlayRef.detach();
    this._isActive = false;
  }
}
