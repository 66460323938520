import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { PaypalConfirmStatus } from '../../models/paypal-status.model';

import { PaymentService } from '../../services/payment.service';
import { SessionService } from '../../services/session.service';

@Component({
  selector: 'app-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss'],
})
export class PaymentSuccessComponent implements OnInit {
  pec: string = '';
  orderId: string = '';
  paypalSubscriptionId: string = '';
  fabrickPaymentToken: string = '';
  paymentCheck: boolean = false;

  retryCount = 6;
  retryTimeout = 10000;

  isProspect: boolean = false;

  constructor(
    private paymentService: PaymentService,
    private router: Router,
    private route: ActivatedRoute,
    private sessionService: SessionService,
  ) {
    this.isProspect = this.sessionService.getIsProspect();
    this.orderId = this.route.snapshot.queryParamMap.get('token');
    this.paypalSubscriptionId = this.route.snapshot.queryParamMap.get('subscription_id');
    this.fabrickPaymentToken = this.route.snapshot.queryParamMap.get('paymentToken');

    const session = this.sessionService.getPaymentFormData();

    if (session) {
      this.pec = session.pec;
    }
  }

  ngOnInit(): void {
    if ((this.orderId || this.paypalSubscriptionId) && !this.fabrickPaymentToken) {
      this._confirmPayPalPayment();
    }
  }

  private _confirmPaymentSuccess() {
    this.paymentCheck = true;
    this.sessionService.initFormData();
  }

  private _confirmPayPalPayment() {
    /*
     *  In case of PayPal subscription use the paypalSubscriptionId,
     *  for PayPal instant use the token (orderId)
     */
    const paypalConfimationId = this.paypalSubscriptionId
      ? this.paypalSubscriptionId
      : this.orderId;

    this.paymentService.confirmPayPalPayment(paypalConfimationId).subscribe(
      (_paymentResult: PaypalConfirmStatus) => {
        this._confirmPaymentSuccess();
      },
      (err) => {
        this.router.navigate(['/payment'], { queryParams: { paypalError: err } });
      },
    );
  }
}
