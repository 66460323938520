import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SelectPlansComponent } from './pages/select-plans/select-plans.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';

import { ActivateComponent } from './pages/activate/activate.component';
import { ContractDataComponent } from './pages/contract-data/contract-data.component';
import { AccountManagementComponent } from './pages/account-management/account-management.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { ThankYouComponent } from './pages/thank-you/thank-you.component';

import { StepGuardService } from './services/step-guard.service';
import { ActivatePlanGuardService } from './services/activate-plan-guard.service';
import { PaymentSuccessComponent } from './pages/payment-success/payment-success.component';
import { specialRoutes } from './app-special-routing';
import { NocheckoutThankYouComponent } from './pages/nocheckout-thank-you/nocheckout-thank-you.component';
import { SignatureComponent } from './pages/signature/signature.component';
import { SignatureFeedbackComponent } from './pages/signature/feedback-page/feedback.component';

const routes: Routes = [
  {
    path: '',
    component: SelectPlansComponent,
  },
  {
    path: 'products/:agreement/:linkId',
    component: SelectPlansComponent,
  },
  {
    path: 'products/:agreement',
    component: SelectPlansComponent,
  },
  {
    path: 'activate',
    component: ActivateComponent,
    canActivate: [ActivatePlanGuardService],
  },
  {
    path: 'contract-data',
    component: ContractDataComponent,
    canActivate: [ActivatePlanGuardService, StepGuardService],
  },
  {
    path: 'account-management',
    component: AccountManagementComponent,
    canActivate: [ActivatePlanGuardService, StepGuardService],
  },
  {
    path: 'signature',
    component: SignatureComponent,
    canActivate: [ActivatePlanGuardService, StepGuardService],
  },
  {
    path: 'signature/success',
    component: SignatureFeedbackComponent,
    data: {
      success: true,
    },
  },
  {
    path: 'signature/error',
    component: SignatureFeedbackComponent,
    data: {
      success: false,
    },
  },
  {
    path: 'payment',
    component: PaymentComponent,
    canActivate: [StepGuardService, ActivatePlanGuardService],
  },
  {
    path: 'thank-you',
    component: ThankYouComponent,
  },
  {
    path: 'nocheckout-thank-you',
    component: NocheckoutThankYouComponent,
  },
  {
    path: 'payment-success',
    component: PaymentSuccessComponent,
  },
  ...specialRoutes,
  {
    path: 'expired',
    component: NotFoundComponent,
    data: {
      page: 'expired',
    },
  },
  {
    path: 'payment-error',
    component: NotFoundComponent,
    data: {
      page: 'payment-error',
    },
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
