import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { ScrollElement } from '../models/scroll-element.model';

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  elements: ScrollElement[];
  elements$: Subject<ScrollElement[]>;

  constructor() {
    this.elements = [];
    this.elements$ = new Subject();
    this.elements$.next(this.elements);
  }

  addScrollElements(elements: ScrollElement[]) {
    this.elements = elements;
    this.elements$.next(this.elements);
  }

  removeElements() {
    this.elements = [];
    this.elements$.next(this.elements);
  }

  scrollTo(scrollElementSlug: string) {
    const yOffset = -100;
    const element = this.elements.find(
      (scrollElement) => scrollElement.slug === scrollElementSlug,
    );

    if (!!element) {
      const ElementBoundTop = element.elementRef.getBoundingClientRect().top;
      const y = ElementBoundTop + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }

  scrollToCoords(elementY: number) {
    if (elementY) {
      const yOffset = -250;
      const y = elementY + yOffset;

      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }
}
