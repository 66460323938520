import { Component } from '@angular/core';
import { ApiConfiguration } from '../lib/api-configuration';
import { ENV } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'onboarding';

  constructor(private apiConfiguration: ApiConfiguration) {
    this.apiConfiguration.rootUrl = `${ENV.apiUrl}/api`;
  }
}
