import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { Observable, timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AvailabilityService } from 'src/lib/services';

@Injectable({
  providedIn: 'root',
})
export class UserValidators {
  constructor(private availabilityService: AvailabilityService) {}

  searchUsername(username: string) {
    return timer(300).pipe(
      switchMap(() => {
        return this.availabilityService.loginNameSearch({
          loginname: username,
        });
      }),
    );
  }

  usernameValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
      return this.searchUsername(control.value).pipe(
        map((res) => {
          if (res.taken) {
            control.markAsTouched();
            return { usernameTaken: true };
          }
        }),
      );
    };
  }
}
