import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import * as moment from 'moment';

import { filter, map } from 'rxjs/operators';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';

import { ContractDataRequest } from '../../../lib/models/contract-data-request';
import { ContractData } from '../../models/contract-data.model';

import { ApiService } from '../../services/api.service';
import { ErrorService } from '../../services/error.service';
import { FormDataService } from '../../services/form-data.service';
import { NavbarService } from '../../services/navbar.service';
import { LoadingService } from '../../services/loading.service';
import { ScrollService } from '../../services/scroll.service';
import { SessionService } from '../../services/session.service';

import { ContractDataValidators } from '../../validators/contract-data.validator';
import { ProspectService } from 'src/app/services/prospect.service';
import { AccountManagement } from 'src/app/models/account-management.model';

@Component({
  selector: 'app-contract-data',
  templateUrl: './contract-data.component.html',
  styleUrls: ['./contract-data.component.scss'],
})
export class ContractDataComponent implements OnInit, OnDestroy {
  contractDataForm: UntypedFormGroup;
  isProspect: boolean;

  filteredSupplyChainPosition: string[] = [];
  filteredProductCategory: string[] = [];
  filteredBusinessType: string[] = [];
  clonedLegalZip: string = '';

  directorDocument$: Observable<any>;

  addressGroupSubscriptions: Subscription;
  directorDocumentSubscription: Subscription;
  watchedControlSubscriptions: Subscription;

  private directorInfoWarning: BehaviorSubject<boolean>;
  directorInfoWarning$: Observable<boolean>;

  constructor(
    private el: ElementRef,
    private formBuilder: UntypedFormBuilder,
    private formDataService: FormDataService,
    private loadingService: LoadingService,
    private navbarService: NavbarService,
    private apiService: ApiService,
    private router: Router,
    private prospectService: ProspectService,
    private sessionService: SessionService,
    private contractDataValidators: ContractDataValidators,
    private errorService: ErrorService,
    private scrollService: ScrollService,
  ) {
    this.directorDocument$ = this.formDataService.directorDocument$;

    this.watchedControlSubscriptions = new Subscription();

    this.directorInfoWarning = new BehaviorSubject(false);
    this.directorInfoWarning$ = this.directorInfoWarning.asObservable();
  }

  ngOnInit(): void {
    const formDefaultValues = this.sessionService.getContractFormData();
    this.isProspect = this.sessionService.getIsProspect();

    if (this.isProspect) {
      this.contractDataForm =
        this.prospectService.buildContractDataForm(formDefaultValues);
      this.contractDataForm.disable();
    } else {
      this.contractDataForm = this._buildForm(formDefaultValues);

      this.contractDataForm.statusChanges
        .pipe(filter(() => !this.contractDataForm.valid))
        .subscribe(() => this._onFormInValid());

      const watchedControls = [
        this.contractDataForm.controls.directorFirstName,
        this.contractDataForm.controls.directorLastName,
        this.contractDataForm.controls.directorFiscalCode,
        this.contractDataForm.controls.directorBirthPlace,
        //NOTE: directorBirthDate change is managed inside date-input component
      ];

      watchedControls.forEach((control) => {
        const controlSubscription = this.formDataService
          .watchConsistencyInfo(control)
          .subscribe((isValueChanged) => {
            this.directorInfoWarning.next(isValueChanged);
          });
        this.watchedControlSubscriptions.add(controlSubscription);
      });

      this.directorDocumentSubscription = this.directorDocument$
        .pipe(map((file) => (file !== null ? true : false)))
        .subscribe((isFileUploaded: boolean) => {
          this.directorInfoWarning.next(!isFileUploaded);
        });
    }
  }

  dateHasChanged(isDateChanged: boolean) {
    this.directorInfoWarning.next(isDateChanged);
  }

  submitForm() {
    this.loadingService.startSpinner();

    const { directorFile } = this.sessionService.getUploadedFilesData();

    const formData: ContractDataRequest = {
      ...this.contractDataForm.value,
      directorId: directorFile ? directorFile.fileKey : null,
      legalCityId: this.contractDataForm.value.legalCity
        ? this.contractDataForm.value.legalCity.id
        : null,
      hqCityId: this.contractDataForm.value.hqCity
        ? this.contractDataForm.value.hqCity.id
        : null,
    };

    const date = new Date(formData.directorBirthDate);
    formData.directorBirthDate = moment(date).toISOString();

    this.apiService.submitContractDataForm(formData).subscribe(
      (contractData: ContractData) => {
        this.formDataService.saveContractDataForm(contractData);

        if (this.isProspect) {
          this.apiService
            .submitAccountManagementForm(formData)
            .subscribe((accountManagement: AccountManagement) => {
              this.formDataService.saveAccountManagementForm(accountManagement);
              this.router.navigate(['/account-management']).then(() => {
                this.loadingService.stopSpinner();
              });
            });
        } else {
          this.router.navigate(['/account-management']).then(() => {
            this.loadingService.stopSpinner();
          });
        }
      },
      (errorResponse: HttpErrorResponse) => {
        this.loadingService.stopSpinner();
        this._handleError(errorResponse);

        // SMARMELLO: Remove setTimeout, the class is added after few ms...
        setTimeout(() => {
          const firstInvalid = this._getFirstInvalidControlPosition();
          this.scrollService.scrollToCoords(firstInvalid);
        }, 50);
      },
    );
  }

  copyFromLegalData(): void {
    if (this.contractDataForm.controls.legalAddress.value !== null) {
      this.contractDataForm.controls.hqAddress.setValue(
        this.contractDataForm.controls.legalAddress.value,
      );
    }

    if (this.contractDataForm.controls.legalAddressNumber.value !== null) {
      this.contractDataForm.controls.hqAddressNumber.setValue(
        this.contractDataForm.controls.legalAddressNumber.value,
      );
    }

    if (this.contractDataForm.controls.legalCity.value !== null) {
      this.contractDataForm.controls.hqCity.setValue(
        this.contractDataForm.controls.legalCity.value,
      );
    }

    if (this.contractDataForm.controls.legalZip.value !== null) {
      this.clonedLegalZip = this.contractDataForm.controls.legalZip.value;
      this.contractDataForm.controls.hqZip.enable();
    }
  }

  ngOnDestroy(): void {
    this.directorDocumentSubscription && this.directorDocumentSubscription.unsubscribe();
    this.watchedControlSubscriptions && this.watchedControlSubscriptions.unsubscribe();
  }

  private _buildForm(formDefaultValues: ContractData): UntypedFormGroup {
    return this.formBuilder.group({
      //company data
      businessName: [
        formDefaultValues.businessName,
        [Validators.required, Validators.maxLength(255)],
      ],
      vatNumber: [
        formDefaultValues.vatNumber,
        [Validators.required, Validators.pattern('^[0-9]{11}$')],
      ],
      email: [
        formDefaultValues.email,
        [
          Validators.required,
          Validators.pattern(
            "^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$",
          ),
        ],
      ],
      pec: [
        formDefaultValues.pec,
        [
          Validators.required,
          Validators.pattern(
            "^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$",
          ),
        ],
      ],
      mobileNumber: [
        formDefaultValues.mobileNumber,
        [
          Validators.required,
          Validators.pattern(
            '^(\\((00|\\+)39\\)|(00|\\+)39)?\\s?(38[890]|34[4-90]|36[680]|33[13-90]|32[89]|35[01]|37[019])\\s?(\\d{3}\\s?\\d{3,4}|\\d{6,7})$',
          ),
        ],
      ],
      //business info
      supplyChainPosition: [formDefaultValues.supplyChainPosition, [Validators.required]],
      productCategory: [
        {
          value: formDefaultValues.productCategory,
          disabled: this.filteredProductCategory.length === 0 ? true : false,
        },
        [Validators.required],
      ],
      businessType: [
        {
          value: formDefaultValues.businessType,
          disabled: this.filteredBusinessType.length === 0 ? true : false,
        },
        [Validators.required],
      ],
      tag: [[formDefaultValues.tag], [Validators.required]],
      website: [
        formDefaultValues.website,
        [
          Validators.pattern(
            '^(http:\\/\\/www\\.|https:\\/\\/www\\.|http:\\/\\/|https:\\/\\/)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,8}(:[0-9]{1,5})?(\\/.*)?$',
          ),
        ],
      ],
      employees: [
        formDefaultValues.employees,
        [Validators.required, Validators.pattern('^[0-9]*$')],
      ],
      lastRevenue: [
        formDefaultValues.lastRevenue,
        [Validators.required, Validators.pattern('^[0-9]*$')],
      ],
      //company director's data
      directorFirstName: [
        formDefaultValues.directorFirstName,
        [Validators.required, Validators.maxLength(255)],
      ],
      directorLastName: [
        formDefaultValues.directorLastName,
        [Validators.required, Validators.maxLength(255)],
      ],
      directorFiscalCode: [
        formDefaultValues.directorFiscalCode,
        [
          Validators.required,
          Validators.pattern(
            '^[a-zA-Z]{6}[0-9]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9]{2}([a-zA-Z]{1}[0-9]{3})[a-zA-Z]{1}$',
          ),
        ],
      ],
      directorId: [formDefaultValues.directorId],
      directorBirthPlace: [
        formDefaultValues.directorBirthPlace,
        [Validators.required, Validators.maxLength(255)],
      ],
      directorBirthDate: [
        formDefaultValues.directorBirthDate,
        [
          Validators.required,
          this.contractDataValidators.dateValidator(),
          this.contractDataValidators.futureDateValidator(),
          this.contractDataValidators.majorAgeValidator(),
        ],
      ],
      //legal hq address info
      legalAddress: [
        formDefaultValues.legalAddress,
        [Validators.required, Validators.maxLength(255)],
      ],
      legalAddressNumber: [
        formDefaultValues.legalAddressNumber,
        [Validators.required, Validators.maxLength(255)],
      ],
      legalCity: [
        formDefaultValues.legalCity,
        [
          Validators.required,
          Validators.maxLength(255),
          this.contractDataValidators.autocompleteObjectValidator(),
        ],
      ],
      legalZip: [
        {
          value: [formDefaultValues.legalZip],
          disabled: [formDefaultValues.legalZip].length === 0 ? true : false,
        },
        [Validators.required],
      ],
      //operative hq address info
      hqAddress: [
        formDefaultValues.hqAddress,
        [Validators.required, Validators.maxLength(255)],
      ],
      hqAddressNumber: [
        formDefaultValues.hqAddressNumber,
        [Validators.required, Validators.maxLength(255)],
      ],
      hqCity: [
        formDefaultValues.hqCity,
        [
          Validators.required,
          Validators.maxLength(255),
          this.contractDataValidators.autocompleteObjectValidator(),
        ],
      ],
      hqZip: [
        {
          value: [formDefaultValues.hqZip],
          disabled: [formDefaultValues.hqZip].length === 0 ? true : false,
        },
        [Validators.required],
      ],
    });
  }

  private _getFirstInvalidControlPosition(): number {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      '.mat-form-field-invalid',
    );

    if (!firstInvalidControl) {
      return null;
    }

    return firstInvalidControl.offsetTop;
  }

  private _onFormInValid() {
    this.navbarService.disableStep('account-management');
  }

  private _handleError(errorResponse: HttpErrorResponse): void {
    this.contractDataForm = this.errorService._handleSubmitError(
      this.contractDataForm,
      errorResponse,
    );
  }
}
