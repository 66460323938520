import { Component, OnInit, Input, DebugElement } from '@angular/core';
import { NavbarService } from '../../services/navbar.service';
import { Step } from '../../models/step.model';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  requiredPayment: boolean = false;

  steps: Step[];

  gridColumnStyle: string;

  constructor(
    private navbarService: NavbarService,
    private sessionService: SessionService,
  ) {
    this.steps = this.navbarService.getSteps();
    this.generateGridColumnStyle();
  }

  ngOnInit(): void {
    this.requiredPayment = this.sessionService.getRequiredPayment();
  }

  isEnabled(slug: string) {
    const step: Step = this.navbarService.getStep(slug);
    return step.isEnabled;
  }

  generateGridColumnStyle(): void {
    const repeatCount = this.steps.length;
    this.gridColumnStyle = String('auto 1fr ').repeat(repeatCount).slice(0, -4);
  }
}
