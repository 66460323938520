import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Plan } from '../models/plan.model';
import { PaypalCreateStatus, PaypalConfirmStatus } from '../models/paypal-status.model';

import { PayPalService } from '../../lib/services/pay-pal.service';
import { SessionService } from './session.service';
@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(
    private paypalService: PayPalService,
    private sessionService: SessionService,
  ) {}

  createPayment(): Observable<PaypalCreateStatus> {
    const plan: Plan = this.sessionService.getPlan();

    return this.paypalService.createPayPalPayment({ body: { plan_id: plan.id } });
  }

  confirmPayPalPayment(orderId: string): Observable<PaypalConfirmStatus> {
    return this.paypalService.confirmPayPalPayment({ body: { orderId } });
  }
}
