import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LayoutModule } from '@angular/cdk/layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';

import { ApiModule as OnboardingApiModule } from '../lib/api.module';

import { AppRoutingModule } from './app-routing.module';

import { MaterialModule } from './shared/material/material.module';
import { FormInputModule } from './components/form-input/form-input.module';

import { FormDataService } from './services/form-data.service';
import { NavbarService } from './services/navbar.service';
import { ApiService } from './services/api.service';
import { StepGuardService } from './services/step-guard.service';
import { ActivatePlanGuardService } from './services/activate-plan-guard.service';
import { LoadingService } from './services/loading.service';
import { FileDocumentService } from './services/file-document.service';
import { SessionService } from './services/session.service';
import { PaymentService } from './services/payment.service';
import { ErrorService } from './services/error.service';

import { AppComponent } from './app.component';

import { NavbarComponent } from './components/navbar/navbar.component';
import { PlanCardComponent } from './components/plan-card/plan-card.component';
import { FooterComponent } from './core/footer/footer.component';
import { HeaderComponent } from './core/header/header.component';

import { AccountManagementComponent } from './pages/account-management/account-management.component';
import { ActivateComponent } from './pages/activate/activate.component';
import { ContractDataComponent } from './pages/contract-data/contract-data.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { SelectPlansComponent } from './pages/select-plans/select-plans.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { LoadingComponent } from './components/loading/loading.component';
import { ThankYouComponent } from './pages/thank-you/thank-you.component';
import { PaymentSuccessComponent } from './pages/payment-success/payment-success.component';
import { InternetBankingDialog } from './components/internet-banking-dialog/internet-banking-dialog.component';
import { PsuidInputDialog } from './components/psuid-input-dialog/psuid-input-dialog.component';

import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { SignatureComponent } from './pages/signature/signature.component';
import { SignatureFeedbackComponent } from './pages/signature/feedback-page/feedback.component';
import { SignaturePhoneNumberDialog } from './pages/signature/phone-number-dialog/phone-number-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    PlanCardComponent,
    FooterComponent,
    HeaderComponent,
    AccountManagementComponent,
    ActivateComponent,
    ContractDataComponent,
    SignatureComponent,
    SignaturePhoneNumberDialog,
    PaymentComponent,
    SelectPlansComponent,
    DialogComponent,
    LoadingComponent,
    ThankYouComponent,
    PaymentSuccessComponent,
    NotFoundComponent,
    InternetBankingDialog,
    PsuidInputDialog,
    SignatureFeedbackComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    ReactiveFormsModule,
    MaterialModule,
    FormInputModule,
    OnboardingApiModule,
  ],
  providers: [
    FormDataService,
    NavbarService,
    ApiService,
    StepGuardService,
    ActivatePlanGuardService,
    LoadingService,
    FileDocumentService,
    PaymentService,
    SessionService,
    ErrorService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
