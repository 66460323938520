<form [formGroup]="psuIdForm">
  <h2 mat-dialog-title>Autorizza l'accesso</h2>
  <div mat-dialog-content>
    <p>
      Per proseguire è necessario inserire il tuo username o codice cliente per accedere
      ai servizi della tua banca.
    </p>

    <mat-list>
      <mat-list-item *ngFor="let service of bankServices">
        <mat-icon mat-list-icon>account_balance</mat-icon>
        <div mat-line *ngIf="service.bankLabel">{{ service.bankLabel }}</div>
        <div mat-line *ngIf="service.serviceLabel">{{ service.serviceLabel }}</div>
      </mat-list-item>
    </mat-list>

    <app-text-input
      type="text"
      label="Username o Codice cliente"
      [group]="psuIdForm"
      controlName="psuId"
      [controlObject]="psuIdForm.controls.psuId"
      [isRequired]="true"
      hint="Inserisci qui il tuo username, codice cliente o identificativo utente che utilizzi per accedere alla tua banca."
    ></app-text-input>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button color="secondary" (click)="onNoClick()">Annulla</button>
    <button mat-button color="accent" (click)="submitForm()">Conferma</button>
  </div>
</form>
