import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, AbstractControl } from '@angular/forms';
import { City } from '../../../../lib/models/city';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { ApiService } from '../../../services/api.service';

@Component({
  selector: 'app-city-input',
  templateUrl: './city-input.component.html',
  styleUrls: ['./city-input.component.scss'],
})
export class CityInputComponent implements OnInit, OnDestroy {
  @Input() label: string;
  @Input() group: UntypedFormGroup;
  @Input() controlObject: UntypedFormControl;
  @Input() controlName: string;
  @Input() isRequired?: boolean;

  autocompleteSubscription: Subscription;

  filteredCities: City[] = [];
  isLoading: boolean = false;

  constructor(private apiService: ApiService) {}

  ngOnInit(): void {
    this.autocompleteSubscription = this._autocompleteCities().subscribe(
      (cities: City[]) => (this.filteredCities = cities),
    );
  }

  displayCityFn(city: any) {
    if (city !== null) {
      return city && city.nome
        ? `${city.nome} (${city.provincia_abbr})`
        : `${this.filteredCities[0].nome} (${this.filteredCities[0].provincia_abbr})`;
    }
  }

  ngOnDestroy(): void {
    this.autocompleteSubscription && this.autocompleteSubscription.unsubscribe();
  }

  private _autocompleteCities(): Observable<City[]> {
    return this.controlObject.valueChanges.pipe(
      debounceTime(300),
      tap(() => (this.isLoading = true)),
      switchMap((value) =>
        this.apiService.searchCity(value).pipe(
          tap((res) => {
            if (!res.length && typeof this.controlObject.value === 'string') {
              this.controlObject.setErrors({
                validationMessage: 'Nessuna città trovata',
              });
            }
          }),
          finalize(() => {
            this.isLoading = false;
          }),
        ),
      ),
    );
  }
}
