import { Injectable } from '@angular/core';
import { Step } from '../models/step.model';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root',
})
export class NavbarService {
  private steps: Array<Step>;
  private initialStepConfig: Array<Step> = [
    {
      slug: 'contract-data',
      title: 'Dati Contrattuali',
      isEnabled: true,
    },
    {
      slug: 'account-management',
      title: 'Gestione del conto',
      isEnabled: false,
    },
    {
      slug: 'signature',
      title: 'Firma',
      isEnabled: false,
    },
  ];

  constructor(private sessionService: SessionService) {
    const stepSession = this.sessionService.getStepSession();

    if (stepSession) {
      this.steps = stepSession;
    } else {
      this.resetSteps();
    }
  }

  getSteps(): Step[] {
    return this.steps;
  }

  getFirstValidStep(): Step {
    return this.steps.filter((step) => step.isEnabled === true)[0];
  }

  getStep(slug: string): Step {
    return this.steps.find((step) => step.slug === slug);
  }

  enableStep(slug: string) {
    this.steps = this.steps.map((step) =>
      step.slug === slug ? { ...step, isEnabled: true } : step,
    );
    this._saveStepSession();
  }

  disableStep(slug: string) {
    this.steps = this.steps.map((step) =>
      step.slug === slug ? { ...step, isEnabled: false } : step,
    );
    this._saveStepSession();
  }

  resetSteps() {
    //Clone deep to avoid reference
    this.steps = JSON.parse(JSON.stringify(this.initialStepConfig));

    const isPaymentRequired = this.sessionService.getRequiredPayment();
    const isStepPresent = this.steps.find((step) => step.slug === 'payment');
    if (isPaymentRequired && !isStepPresent) {
      this.steps.push({
        slug: 'payment',
        title: 'Pagamento',
        isEnabled: false,
      });
    }

    this._saveStepSession();
  }

  private _saveStepSession(): void {
    this.sessionService.saveStepSession(this.steps);
  }
}
