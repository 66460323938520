<app-navbar></app-navbar>
<div class="page-container">
  <form [formGroup]="accountManagementForm">
    <div class="form-container">
      <div class="form-title">
        <h3>Referente del conto</h3>
        <button
          mat-icon-button
          type="button"
          aria-label="Referente del conto"
          (click)="openDialog('referenteConto')"
        >
          <mat-icon>info_outlined</mat-icon>
        </button>
      </div>
      <div class="action-buttons">
        <button
          *ngIf="!isProspect"
          mat-stroked-button
          type="button"
          color="primary"
          (click)="copyFromDirector('accountManager')"
        >
          Compila con i dati del Rappresentante legale
        </button>
      </div>
      <div class="form-section">
        <app-text-input
          type="text"
          label="Nome referente"
          [group]="accountManagementForm"
          controlName="firstName"
          [controlObject]="accountManagementForm.controls.firstName"
          [isRequired]="true"
        ></app-text-input>

        <app-text-input
          type="text"
          label="Cognome del referente"
          [group]="accountManagementForm"
          controlName="lastName"
          [controlObject]="accountManagementForm.controls.lastName"
          [isRequired]="true"
        ></app-text-input>

        <app-file-input
          *ngIf="!isProspect"
          label="Documento di identità referente"
          [group]="accountManagementForm"
          controlName="accountManagerId"
          [controlObject]="accountManagementForm.controls.accountManagerId"
          [fileContent$]="accountManagerDocument$"
          placeholder="Scegli un file"
          hint="Formati consentiti: PDF, JPG, PNG. Dimensioni massime: 5MB"
          [consistencyInfoWarning$]="accountManagerInfoWarning$"
          [isRequired]="true"
        ></app-file-input>

        <app-text-input
          type="email"
          label="Indirizzo email"
          [group]="accountManagementForm"
          controlName="email"
          [controlObject]="accountManagementForm.controls.email"
          [isRequired]="true"
        ></app-text-input>

        <app-text-input
          type="phone"
          label="Numero di telefono cellulare"
          [group]="accountManagementForm"
          controlName="mobileNumber"
          [controlObject]="accountManagementForm.controls.mobileNumber"
          [isRequired]="true"
        ></app-text-input>
      </div>
    </div>

    <div class="form-container">
      <div class="form-title">
        <h3>Intestatario della carta</h3>
        <button
          mat-icon-button
          type="button"
          aria-label="Intestatario della carta"
          (click)="openDialog('intestatarioCarta')"
        >
          <mat-icon>info_outlined</mat-icon>
        </button>
      </div>
      <div class="action-buttons">
        <button
          *ngIf="!isProspect"
          mat-stroked-button
          type="button"
          color="primary"
          (click)="copyFromDirector('cardReferent')"
        >
          Compila con i dati del Rappresentante legale
        </button>
        <button
          *ngIf="!isProspect"
          mat-stroked-button
          type="button"
          color="primary"
          (click)="copyFromAccountManager()"
        >
          Compila con i dati del Referente del conto
        </button>
      </div>
      <div class="form-section">
        <app-text-input
          type="text"
          label="Nome intestatario della carta"
          [group]="accountManagementForm"
          controlName="cardFirstName"
          [controlObject]="accountManagementForm.controls.cardFirstName"
          [isRequired]="true"
        ></app-text-input>
        <app-text-input
          type="text"
          label="Cognome intestatario della carta"
          [group]="accountManagementForm"
          controlName="cardLastName"
          [controlObject]="accountManagementForm.controls.cardLastName"
          [isRequired]="true"
        ></app-text-input>
        <app-file-input
          *ngIf="!isProspect"
          label="Documento di identità intestatario carta"
          [group]="accountManagementForm"
          controlName="cardReferentId"
          [controlObject]="accountManagementForm.controls.cardReferentId"
          [fileContent$]="cardReferentDocument$"
          placeholder="Scegli un file"
          hint="Formati consentiti: PDF, JPG, PNG. Dimensioni massime: 5MB"
          [consistencyInfoWarning$]="cardReferentInfoWarning$"
          [isRequired]="true"
        ></app-file-input>
      </div>
    </div>

    <div class="form-container">
      <div class="form-title">
        <h3>Disponibilità commerciale</h3>
        <button
          mat-icon-button
          type="button"
          aria-label="Disponibilità commerciale"
          (click)="openDialog('disponibilitaCommerciale')"
        >
          <mat-icon>info_outlined</mat-icon>
        </button>
      </div>
      <div class="form-section">
        <app-text-input
          type="text"
          label="Disponibilità di beni e servizi nel circuito"
          [group]="accountManagementForm"
          controlName="circuitPropensity"
          [controlObject]="accountManagementForm.controls.circuitPropensity"
          [hint]="
            isProspect
              ? ''
              : 'Valore compreso tra ' +
                plan.commercialAvailability.gte +
                ' e ' +
                plan.commercialAvailability.lte +
                ' Euro'
          "
          iconSuffix="euro"
          [isRequired]="true"
        ></app-text-input>
        <app-select-input
          label="Percentuale di accettazione oltre 1000SRD"
          [group]="accountManagementForm"
          controlName="acceptancePercentage"
          [controlObject]="accountManagementForm.controls.acceptancePercentage"
          [optionsData]="[25, 50, 75, 100]"
          hint="Quota percentuale di accettazione oltre i 1000 SRD"
          [placeholder]="'Seleziona un valore'"
          [isRequired]="true"
        ></app-select-input>
      </div>
    </div>

    <div class="form-container">
      <div class="form-title">
        <h3>Nome online</h3>
        <button
          mat-icon-button
          type="button"
          aria-label="Nome online"
          (click)="openDialog('nomeOnline')"
        >
          <mat-icon>info_outlined</mat-icon>
        </button>
      </div>
      <div class="form-section">
        <app-text-input
          type="text"
          label="Nome attività online"
          [group]="accountManagementForm"
          controlName="onlineName"
          [controlObject]="accountManagementForm.controls.onlineName"
          hint="Nome della tua attività da utilizzare sulla tua vetrina online all'interno del circuito"
          [isRequired]="true"
        ></app-text-input>
      </div>
    </div>

    <div class="form-container" *ngIf="!isProspect">
      <div class="form-title">
        <h3>Nome di login</h3>
        <button
          mat-icon-button
          type="button"
          aria-label="Nome di login"
          (click)="openDialog('nomeLogin')"
        >
          <mat-icon>info_outlined</mat-icon>
        </button>
      </div>
      <div class="form-section">
        <app-text-input
          type="text"
          label="Username per il login"
          [group]="accountManagementForm"
          controlName="loginName"
          [controlObject]="accountManagementForm.controls.loginName"
          hint="Nome utente da utilizzare per effettuare l'accesso al tuo conto personale"
          [isRequired]="true"
        ></app-text-input>
      </div>
    </div>
  </form>

  <mat-card
    class="submit-error mat-elevation-z0"
    *ngIf="accountManagementForm.errors?.validationMessage"
  >
    <mat-list>
      <mat-list-item>
        <mat-icon mat-list-icon>announcement</mat-icon>
        <div mat-line>{{ accountManagementForm.errors.validationMessage }}</div>
      </mat-list-item>
    </mat-list>
  </mat-card>
</div>

<div class="form-buttons" #formButtons>
  <div class="page-container">
    <button mat-button color="primary" (click)="saveDraft()">Indietro</button>
    <button
      mat-flat-button
      color="primary"
      [disabled]="accountManagementForm.pending"
      (click)="submitForm()"
    >
      Avanti
    </button>
  </div>
</div>
