/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class FormPartecipaService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation partecipa
   */
  static readonly PartecipaPath = '/partecipa';

  /**
   * Endpoint used to create a Lead
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `partecipa()` instead.
   *
   * This method doesn't expect any request body.
   */
  partecipa$Response(params: {

    /**
     * Lead Firstname
     */
    nome: string;

    /**
     * Lead Lastname
     */
    cognome: string;

    /**
     * Lead Company Name
     */
    ragioneSociale: string;

    /**
     * Lead VAT Number
     */
    piva: string;

    /**
     * Lead E-Mail
     */
    email: string;

    /**
     * Lead Phone Number
     */
    telefono: string;

    /**
     * Lead Website
     */
    sitointernet: string;

    /**
     * City ID
     */
    cityId: number;

    /**
     * CAP
     */
    cap: string;

    /**
     * Legal City ID
     */
    legalCityId: number;

    /**
     * Legal CAP
     */
    legalCap: string;

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, FormPartecipaService.PartecipaPath, 'post');
    if (params) {

      rb.query('nome', params.nome);
      rb.query('cognome', params.cognome);
      rb.query('ragione_sociale', params.ragioneSociale);
      rb.query('piva', params.piva);
      rb.query('email', params.email);
      rb.query('telefono', params.telefono);
      rb.query('sitointernet', params.sitointernet);
      rb.query('city_id', params.cityId);
      rb.query('cap', params.cap);
      rb.query('legal_city_id', params.legalCityId);
      rb.query('legal_CAP', params.legalCap);

    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Endpoint used to create a Lead
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `partecipa$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  partecipa(params: {

    /**
     * Lead Firstname
     */
    nome: string;

    /**
     * Lead Lastname
     */
    cognome: string;

    /**
     * Lead Company Name
     */
    ragioneSociale: string;

    /**
     * Lead VAT Number
     */
    piva: string;

    /**
     * Lead E-Mail
     */
    email: string;

    /**
     * Lead Phone Number
     */
    telefono: string;

    /**
     * Lead Website
     */
    sitointernet: string;

    /**
     * City ID
     */
    cityId: number;

    /**
     * CAP
     */
    cap: string;

    /**
     * Legal City ID
     */
    legalCityId: number;

    /**
     * Legal CAP
     */
    legalCap: string;

  }): Observable<void> {

    return this.partecipa$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
