export type privacyPolicyEnum = 'deny' | 'accept';
export type factoringValueEnum = 'deny' | 'accept';

export class Signature {
  termsAcceptance?: boolean = null;
  signatureMobilePhone?: string = null;
  factoring?: factoringValueEnum = null;
  privacyPolicyProfiling?: privacyPolicyEnum = null;
  privacyPolicyMarketing?: privacyPolicyEnum = null;
  privacyPolicyMarketingThirdParty?: privacyPolicyEnum = null;
}
