import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BankService } from 'src/app/models/bank-service.model';
import { PsuidDialogData } from 'src/app/models/Psuid-dialog-data.model';

@Component({
  selector: 'psuid-input-dialog',
  templateUrl: './psuid-input-dialog.component.html',
  styleUrls: ['./psuid-input-dialog.component.scss'],
})
export class PsuidInputDialog {
  psuIdForm: UntypedFormGroup;
  bankServices: BankService[];

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<PsuidInputDialog>,
    @Inject(MAT_DIALOG_DATA)
    public data: PsuidDialogData,
  ) {
    dialogRef.disableClose = true;

    this.psuIdForm = this.formBuilder.group({
      psuId: ['', [Validators.required]],
    });

    this.bankServices = data.services;

    if (!this.bankServices.length && data.bankLabel) {
      this.bankServices.push({
        bankLabel: data.bankLabel,
      });
    }
  }

  submitForm() {
    this.dialogRef.close(this.psuIdForm.controls.psuId.value);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
