<h2 mat-dialog-title>Scegli il tuo Internet Banking per procedere con il pagamento</h2>
<div mat-dialog-content>
  <mat-action-list>
    <h3 matSubheader>{{ data.services[0].bankLabel }}</h3>
    <button
      mat-list-item
      *ngFor="let service of data.services"
      (click)="chooseService(service.serviceCode)"
    >
      <h4 matLine>
        <strong>{{ service.serviceLabel }}</strong>
      </h4>
      <p matLine>{{ service.description }}</p>
      <mat-icon>arrow_forward_ios</mat-icon>
    </button>
  </mat-action-list>
</div>
<div mat-dialog-actions align="end">
  <button mat-button color="accent" (click)="onNoClick()">Annulla</button>
</div>
