import { Injectable } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import { DialogComponent } from '../components/dialog/dialog.component';
import { dialogsData } from '../models/dialog-data.model';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private dialog: MatDialog) {}

  open(dialogDataSlug) {
    this.dialog.open(DialogComponent, {
      data: dialogsData[dialogDataSlug],
      autoFocus: false,
    });
  }
}
