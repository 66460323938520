/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';



/**
 * Data about new Categorization
 */
@Injectable({
  providedIn: 'root',
})
export class CategoriesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation elenca
   */
  static readonly ElencaPath = '/elenca';

  /**
   * Ottieni lista posizionamenti in filiera
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `elenca()` instead.
   *
   * This method doesn't expect any request body.
   */
  elenca$Response(params?: {

  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, CategoriesService.ElencaPath, 'post');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * Ottieni lista posizionamenti in filiera
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `elenca$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  elenca(params?: {

  }): Observable<Array<string>> {

    return this.elenca$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation posizionamento
   */
  static readonly PosizionamentoPath = '/posizionamento';

  /**
   * Ottieni attività da posizionamento in filiera
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `posizionamento()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  posizionamento$Response(params: {
      body: { 'posizionamento_in_filiera'?: string }
  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, CategoriesService.PosizionamentoPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * Ottieni attività da posizionamento in filiera
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `posizionamento$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  posizionamento(params: {
      body: { 'posizionamento_in_filiera'?: string }
  }): Observable<Array<string>> {

    return this.posizionamento$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation settore
   */
  static readonly SettorePath = '/settore';

  /**
   * Ottieni attività da posizionamento in filiera
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `settore()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  settore$Response(params: {
      body: { 'posizionamento_in_filiera'?: string, 'settore_merceologico'?: string }
  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, CategoriesService.SettorePath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * Ottieni attività da posizionamento in filiera
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `settore$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  settore(params: {
      body: { 'posizionamento_in_filiera'?: string, 'settore_merceologico'?: string }
  }): Observable<Array<string>> {

    return this.settore$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation attivita
   */
  static readonly AttivitaPath = '/attivita';

  /**
   * Ottieni tags da attivita
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attivita()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  attivita$Response(params: {
      body: { 'posizionamento_in_filiera'?: string, 'settore_merceologico'?: string, 'attivita'?: string }
  }): Observable<StrictHttpResponse<{ 'tags_minime'?: Array<string>, 'tags_aggiuntive'?: Array<string>, 'tags'?: Array<string> }>> {

    const rb = new RequestBuilder(this.rootUrl, CategoriesService.AttivitaPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'tags_minime'?: Array<string>, 'tags_aggiuntive'?: Array<string>, 'tags'?: Array<string> }>;
      })
    );
  }

  /**
   * Ottieni tags da attivita
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `attivita$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  attivita(params: {
      body: { 'posizionamento_in_filiera'?: string, 'settore_merceologico'?: string, 'attivita'?: string }
  }): Observable<{ 'tags_minime'?: Array<string>, 'tags_aggiuntive'?: Array<string>, 'tags'?: Array<string> }> {

    return this.attivita$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'tags_minime'?: Array<string>, 'tags_aggiuntive'?: Array<string>, 'tags'?: Array<string> }>) => r.body as { 'tags_minime'?: Array<string>, 'tags_aggiuntive'?: Array<string>, 'tags'?: Array<string> })
    );
  }

}
