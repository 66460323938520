import { Injectable } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Activate } from 'src/lib/models';
import { ApiService } from './api.service';
import { SessionService } from './session.service';
import { ContractData } from '../models/contract-data.model';
import { AccountManagement } from '../models/account-management.model';
import { Plan } from '../models/plan.model';
import { NavbarService } from './navbar.service';
import { Observable } from 'rxjs';
import { Payment } from '../models/payment.model';

@Injectable({
  providedIn: 'root',
})
export class ProspectService {
  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private sessionService: SessionService,
    private navService: NavbarService,
  ) {}

  /**
   * Starts a prospect session.
   *
   * @param plan - The plan to be used in the prospect session.
   *
   * @remarks
   * This method initiates a prospect session with the specified plan. It performs the necessary setup and configuration.
   *
   */
  public startProspectSession(plan: Plan): void {
    this.sessionService.initFormData();
    this.navService.resetSteps();
    this.sessionService.savePlan(plan);
  }

  /**
   * Submits an empty action form.
   *
   * This method must only be called when the plan is prospect.
   * @returns {Observable<any>} An observable that represents the result of the activation request.
   */
  public submitActivate(): Observable<{
    Activate?: Activate;
    ContractData?: ContractData;
  }> {
    const formData: Activate = this.buildActiveForm(
      this.sessionService.getActivateFormData(),
    ).value;

    return this.apiService.onlineActivation(formData);
  }

  /**
   * Builds an active form using the provided form values.
   *
   * This method must only be called when the plan is prospect, because this remove all the validation from the form.
   *
   * @param formValues The values to populate the form with.
   * @returns The form group for active form, without any validation.
   */
  public buildActiveForm(formValues: Activate): UntypedFormGroup {
    return this.formBuilder.group({
      firstName: [formValues.firstName],
      lastName: [formValues.lastName],
      vatNumber: [formValues.vatNumber],
      mobileNumber: [formValues.mobileNumber],
      email: [formValues.email],
      privacyPolicyBase: [formValues.privacyPolicyBase],
    });
  }

  /**
   * Builds a form group for contract data.
   *
   * This method must only be called when the plan is prospect, because this remove all the validation from the form.
   *
   * @param formValues - The contract data values.
   * @returns The form group for contract data, without any validation.
   */
  public buildContractDataForm(formValues: ContractData): UntypedFormGroup {
    return this.formBuilder.group({
      businessName: [formValues.businessName],
      vatNumber: [formValues.vatNumber],
      email: [formValues.email],
      pec: [formValues.pec],
      mobileNumber: [formValues.mobileNumber],
      //business info
      supplyChainPosition: [formValues.supplyChainPosition],
      productCategory: [formValues.productCategory],
      businessType: [formValues.businessType],
      tag: [[formValues.tag]],
      website: [formValues.website],
      employees: [formValues.employees],
      lastRevenue: [formValues.lastRevenue],
      //company director's data
      directorFirstName: [formValues.directorFirstName],
      directorLastName: [formValues.directorLastName],
      directorFiscalCode: [formValues.directorFiscalCode],
      directorId: [formValues.directorId],
      directorBirthPlace: [formValues.directorBirthPlace],
      directorBirthDate: [formValues.directorBirthDate],
      //legal hq address info
      legalAddress: [formValues.legalAddress],
      legalAddressNumber: [formValues.legalAddressNumber],
      legalCity: [formValues.legalCity],
      legalZip: [formValues.legalZip],
      //operative hq address info
      hqAddress: [formValues.hqAddress],
      hqAddressNumber: [formValues.hqAddressNumber],
      hqCity: [formValues.hqCity],
      hqZip: [formValues.hqZip],
    });
  }

  /**
   * Builds an account management form using the provided form values.
   *
   * This method must only be called when the plan is prospect, because this remove all the validation from the form.
   * @param formValues - The values to populate the form with.
   * @returns The built account management form, without any validation.
   */
  public buildAccountManagementForm(formValues: AccountManagement) {
    return this.formBuilder.group({
      firstName: [formValues.firstName],
      lastName: [formValues.lastName],
      accountManagerId: [formValues.accountManagerId],
      email: [formValues.email],
      mobileNumber: [formValues.mobileNumber],
      cardFirstName: [formValues.cardFirstName],
      cardLastName: [formValues.cardLastName],
      cardReferentId: [formValues.cardReferentId],
      circuitPropensity: [formValues.circuitPropensity],
      acceptancePercentage: [formValues.acceptancePercentage],
      onlineName: [formValues.onlineName],
      loginName: [formValues.loginName],
    });
  }

  public buildPaymentForm(formValues: Payment) {
    return this.formBuilder.group({
      pec: [formValues.pec],
      ipa: [formValues.ipa],
      paypalCheck: [false, [Validators.requiredTrue]],
    });
  }
}
