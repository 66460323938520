import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { UntypedFormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor() {}

  _handleSubmitError(formGroup: UntypedFormGroup, errorResponse: HttpErrorResponse): UntypedFormGroup {
    switch (errorResponse.status) {
      case 500:
        formGroup.setErrors({
          validationMessage: 'Si è verificato un errore imprevisto. Riprova',
        });
        break;
      default:
        const errors = errorResponse.error;
        Object.keys(errors).map((key) => {
          formGroup.setErrors({
            validationMessage:
              'Errore durante il salvataggio. Correggi i campi non validi e riprova.',
          });
          //HACK: hotfix for field mismatch between models
          if (key === 'legalCityId') {
            key = 'legalCity';
          }

          if (key === 'hqCityId') {
            key = 'hqCity';
          }
          formGroup.controls[key].markAsTouched();

          formGroup.controls[key].setErrors({
            incorrect: true,
            validationMessage: errors[key],
          });
        });
        break;
    }

    return formGroup;
  }

  _handleFabrickError(formGroup: UntypedFormGroup, errorResponse: HttpErrorResponse): UntypedFormGroup {
    switch (errorResponse.error?.details) {
      case 'Payment not allowed':
        formGroup.setErrors({
          validationMessage:
            'Si è verificato un errore col il pagamento. Scegli un altro metodo di pagamento e riprova.',
        });
        break;

      default:
        formGroup.setErrors({
          validationMessage: 'Si è verificato un errore. Riprova più tardi',
        });
        break;
    }

    return formGroup;
  }
}
