/* tslint:disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { ActivatePlanService } from './services/activate-plan.service';
import { AskAppointmentService } from './services/ask-appointment.service';
import { CategoriesService } from './services/categories.service';
import { CityService } from './services/city.service';
import { AvailabilityService } from './services/availability.service';
import { VSalesService } from './services/v-sales.service';
import { IdentityDocumentService } from './services/identity-document.service';
import { InitializeAgreementService } from './services/initialize-agreement.service';
import { LinkGeneratorService } from './services/link-generator.service';
import { AtokaService } from './services/atoka.service';
import { NoCheckoutSubscriptionService } from './services/no-checkout-subscription.service';
import { FormPartecipaService } from './services/form-partecipa.service';
import { PayPalService } from './services/pay-pal.service';
import { SignatureService } from './services/signature.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    ActivatePlanService,
    AskAppointmentService,
    CategoriesService,
    CityService,
    AvailabilityService,
    VSalesService,
    IdentityDocumentService,
    InitializeAgreementService,
    LinkGeneratorService,
    AtokaService,
    NoCheckoutSubscriptionService,
    FormPartecipaService,
    PayPalService,
    SignatureService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
