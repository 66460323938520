<div [formGroup]="group">
  <mat-checkbox
    [id]="controlName"
    [name]="controlName"
    [formControlName]="controlName"
    color="primary"
  >
    {{ label }}
    <ng-content></ng-content>
  </mat-checkbox>
  <mat-error>
    <app-input-validation [controlObject]="controlObject"></app-input-validation>
  </mat-error>
</div>
