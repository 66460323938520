import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'internet-banking-dialog',
  templateUrl: './internet-banking-dialog.component.html',
  styleUrls: ['./internet-banking-dialog.component.scss'],
})
export class InternetBankingDialog {
  constructor(
    public dialogRef: MatDialogRef<InternetBankingDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    dialogRef.disableClose = true;
  }

  chooseService(serviceCode) {
    this.dialogRef.close(serviceCode);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
