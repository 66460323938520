<app-navbar></app-navbar>
<div class="page-container">
  <form [formGroup]="contractDataForm">
    <div class="form-container">
      <div class="form-title">
        <h3>Dati della società</h3>
      </div>
      <div class="form-section">
        <app-text-input
          [type]="'text'"
          [label]="'Ragione Sociale'"
          [group]="contractDataForm"
          [controlName]="'businessName'"
          [controlObject]="contractDataForm.controls.businessName"
          [isRequired]="true"
        ></app-text-input>
        <app-text-input
          [type]="'text'"
          [label]="'Partita Iva'"
          [placeholder]="'ES. 03257760920'"
          [group]="contractDataForm"
          [controlName]="'vatNumber'"
          [controlObject]="contractDataForm.controls.vatNumber"
          [isRequired]="true"
        ></app-text-input>
        <app-text-input
          [type]="'email'"
          [label]="'Email'"
          [group]="contractDataForm"
          [controlName]="'email'"
          [controlObject]="contractDataForm.controls.email"
          [isRequired]="true"
        ></app-text-input>
        <app-text-input
          [type]="'email'"
          [label]="'Indirizzo di posta certificata'"
          [group]="contractDataForm"
          [controlName]="'pec'"
          [controlObject]="contractDataForm.controls.pec"
          [isRequired]="true"
        ></app-text-input>
        <app-text-input
          [type]="'phone'"
          [label]="'Numero di telefono cellulare'"
          [group]="contractDataForm"
          [controlName]="'mobileNumber'"
          [controlObject]="contractDataForm.controls.mobileNumber"
          [isRequired]="true"
        ></app-text-input>
      </div>
    </div>

    <div class="form-container">
      <div class="form-title">
        <h3>Informazioni sulla tua attività</h3>
      </div>
      <div class="form-section activity-info">
        <app-supply-chain-position
          *ngIf="!isProspect"
          class="form-control supplyChainPosition"
          label="Settore produttivo"
          [group]="contractDataForm"
          controlName="supplyChainPosition"
          [disabled]="isProspect"
          [controlObject]="contractDataForm.controls.supplyChainPosition"
          hint="Specifica il settore in cui opera prevalentemente la tua azienda"
          [isRequired]="true"
        ></app-supply-chain-position>
        <app-text-input
          *ngIf="isProspect"
          class="form-control supplyChainPosition"
          label="Settore produttivo"
          [group]="contractDataForm"
          controlName="supplyChainPosition"
          [controlObject]="contractDataForm.controls.supplyChainPosition"
        >
        </app-text-input>

        <app-text-input
          class="form-control website"
          [type]="'url'"
          [label]="'Sito web'"
          [group]="contractDataForm"
          [controlName]="'website'"
          [controlObject]="contractDataForm.controls.website"
        ></app-text-input>

        <app-product-category
          *ngIf="!isProspect"
          [supplyChainPosition]="this.contractDataForm.controls.supplyChainPosition.value"
          class="form-control productCategory"
          label="Settore merceologico"
          [group]="contractDataForm"
          controlName="productCategory"
          [disabled]="isProspect"
          [controlObject]="contractDataForm.controls.productCategory"
          hint="Specifica il settore merceologico prevalente della tua azienda"
          [isRequired]="true"
        ></app-product-category>
        <app-text-input
          *ngIf="isProspect"
          class="form-control productCategory"
          label="Settore merceologico"
          [group]="contractDataForm"
          controlName="productCategory"
          [controlObject]="contractDataForm.controls.productCategory"
        >
        </app-text-input>

        <app-text-input
          class="form-control employees"
          type="text"
          label="Numero di dipendenti"
          [group]="contractDataForm"
          controlName="employees"
          [controlObject]="contractDataForm.controls.employees"
          [isRequired]="true"
        ></app-text-input>

        <app-business-type
          *ngIf="!isProspect"
          [supplyChainPosition]="this.contractDataForm.controls.supplyChainPosition.value"
          [productCategory]="this.contractDataForm.controls.productCategory.value"
          class="form-control businessType"
          label="Attività"
          [group]="contractDataForm"
          controlName="businessType"
          [disabled]="isProspect"
          [controlObject]="contractDataForm.controls.businessType"
          hint="Specifica l'attività di cui si occupa la tua azienda"
          [isRequired]="true"
        ></app-business-type>
        <app-text-input
          *ngIf="isProspect"
          class="form-control businessType"
          label="Attività"
          [group]="contractDataForm"
          controlName="businessType"
          [controlObject]="contractDataForm.controls.businessType"
          hint="Specifica l'attività di cui si occupa la tua azienda"
        >
        </app-text-input>

        <app-text-input
          class="form-control lastRevenue"
          type="text"
          label="Fatturato ultimo anno"
          [group]="contractDataForm"
          controlName="lastRevenue"
          [controlObject]="contractDataForm.controls.lastRevenue"
          hint="Valore espresso in euro"
          [isRequired]="true"
          iconSuffix="euro"
        ></app-text-input>

        <app-tags-input
          *ngIf="!isProspect"
          [supplyChainPosition]="this.contractDataForm.controls.supplyChainPosition.value"
          [productCategory]="this.contractDataForm.controls.productCategory.value"
          [businessType]="this.contractDataForm.controls.businessType.value"
          class="form-control tag"
          label="Servizi e prodotti"
          placeholder="Aggiungi parola chiave"
          [group]="contractDataForm"
          controlName="tag"
          [controlObject]="contractDataForm.controls.tag"
          [disabled]="isProspect"
          hint="Inserisci qua le parole chiave che identificano prodotti e servizi messi a disposizione dalla tua azienda"
        ></app-tags-input>
        <app-text-input
          *ngIf="isProspect"
          class="form-control tag"
          label="Servizi e prodotti"
          placeholder="Aggiungi parola chiave"
          [group]="contractDataForm"
          controlName="tag"
          [controlObject]="contractDataForm.controls.tag"
          placeholder="Aggiungi parola chiave"
        >
        </app-text-input>
      </div>
    </div>

    <div class="form-container">
      <div class="form-title">
        <h3>Dati del rappresentante legale</h3>
      </div>
      <div class="form-section">
        <app-text-input
          [type]="'text'"
          [label]="'Nome'"
          [group]="contractDataForm"
          [controlName]="'directorFirstName'"
          [controlObject]="contractDataForm.controls.directorFirstName"
          [isRequired]="true"
        ></app-text-input>
        <app-text-input
          [type]="'text'"
          [label]="'Cognome'"
          [group]="contractDataForm"
          [controlName]="'directorLastName'"
          [controlObject]="contractDataForm.controls.directorLastName"
          [isRequired]="true"
        ></app-text-input>
        <app-text-input
          [type]="'text'"
          [label]="'Codice fiscale'"
          [group]="contractDataForm"
          [controlName]="'directorFiscalCode'"
          [controlObject]="contractDataForm.controls.directorFiscalCode"
          [isRequired]="true"
        ></app-text-input>
        <app-file-input
          *ngIf="!isProspect"
          label="Documento di identità"
          [group]="contractDataForm"
          controlName="directorId"
          [controlObject]="contractDataForm.controls.directorId"
          [fileContent$]="directorDocument$"
          placeholder="Scegli un file"
          hint="Formati consentiti: PDF, JPG, PNG. Dimensioni massime: 5MB"
          [consistencyInfoWarning$]="directorInfoWarning$"
          [isRequired]="true"
        ></app-file-input>

        <app-text-input
          [type]="'text'"
          [label]="'Luogo di nascita'"
          [group]="contractDataForm"
          [controlName]="'directorBirthPlace'"
          [controlObject]="contractDataForm.controls.directorBirthPlace"
          [isRequired]="true"
        ></app-text-input>
        <app-date-input
          [label]="'Data di nascita'"
          [group]="contractDataForm"
          [controlName]="'directorBirthDate'"
          [controlObject]="contractDataForm.controls.directorBirthDate"
          placeholder="GG/MM/AAAA"
          [isRequired]="true"
          (dateHasChanged)="dateHasChanged($event)"
        ></app-date-input>
      </div>
    </div>

    <div class="form-container">
      <div class="form-title">
        <h3>Indirizzo sede legale</h3>
      </div>
      <div class="form-section">
        <app-text-input
          [type]="'text'"
          [label]="'Via/Piazza'"
          [group]="contractDataForm"
          [controlName]="'legalAddress'"
          [controlObject]="contractDataForm.controls.legalAddress"
          [isRequired]="true"
        ></app-text-input>
        <app-text-input
          [type]="'text'"
          [label]="'Numero civico'"
          [group]="contractDataForm"
          [controlName]="'legalAddressNumber'"
          [controlObject]="contractDataForm.controls.legalAddressNumber"
          [isRequired]="true"
        ></app-text-input>

        <app-city-input
          class="form-control"
          [label]="'Città e provincia'"
          [group]="contractDataForm"
          [controlObject]="contractDataForm.controls.legalCity"
          [controlName]="'legalCity'"
          [isRequired]="true"
        ></app-city-input>

        <app-zip-input
          *ngIf="!isProspect"
          class="form-control"
          [label]="'CAP'"
          [group]="contractDataForm"
          [controlObject]="contractDataForm.controls.legalZip"
          [controlName]="'legalZip'"
          [linkedControlObject]="contractDataForm.controls.legalCity"
          [isRequired]="true"
        ></app-zip-input>
        <app-text-input
          *ngIf="isProspect"
          class="form-control"
          [label]="'CAP'"
          [group]="contractDataForm"
          [controlObject]="contractDataForm.controls.legalZip"
          [controlName]="'legalZip'"
          [isRequired]="true"
        >
        </app-text-input>
      </div>
    </div>

    <div class="form-container">
      <div class="form-title">
        <h3>Indirizzo della sede operativa</h3>
      </div>
      <div class="action-buttons">
        <button
          *ngIf="!isProspect"
          mat-stroked-button
          color="primary"
          type="button"
          (click)="copyFromLegalData()"
        >
          Compila con i dati della sede legale
        </button>
      </div>
      <div class="form-section">
        <app-text-input
          [type]="'text'"
          [label]="'Via/Piazza'"
          [group]="contractDataForm"
          [controlName]="'hqAddress'"
          [controlObject]="contractDataForm.controls.hqAddress"
          [isRequired]="true"
        ></app-text-input>
        <app-text-input
          [type]="'text'"
          [label]="'Numero civico'"
          [group]="contractDataForm"
          [controlName]="'hqAddressNumber'"
          [controlObject]="contractDataForm.controls.hqAddressNumber"
          [isRequired]="true"
        ></app-text-input>

        <app-city-input
          class="form-control"
          [label]="'Città e provincia'"
          [group]="contractDataForm"
          [controlObject]="contractDataForm.controls.hqCity"
          [controlName]="'hqCity'"
          [isRequired]="true"
        ></app-city-input>

        <app-zip-input
          *ngIf="!isProspect"
          class="form-control"
          [label]="'CAP'"
          [group]="contractDataForm"
          [controlObject]="contractDataForm.controls.hqZip"
          [controlName]="'hqZip'"
          [linkedControlObject]="contractDataForm.controls.hqCity"
          [isRequired]="true"
          [clonedLegalZip]="clonedLegalZip"
        ></app-zip-input>
        <app-text-input
          *ngIf="isProspect"
          class="form-control"
          [label]="'CAP'"
          [group]="contractDataForm"
          [controlObject]="contractDataForm.controls.hqZip"
          [controlName]="'hqZip'"
          [isRequired]="true"
        >
        </app-text-input>
      </div>
    </div>
  </form>

  <mat-card
    class="submit-error mat-elevation-z0"
    *ngIf="contractDataForm.errors?.validationMessage"
  >
    <mat-list>
      <mat-list-item>
        <mat-icon mat-list-icon>announcement</mat-icon>
        <div mat-line>{{ contractDataForm.errors.validationMessage }}</div>
      </mat-list-item>
    </mat-list>
  </mat-card>
</div>

<div class="form-buttons" #formButtons>
  <div class="page-container">
    <button *ngIf="!isProspect" mat-button color="primary" routerLink="/activate">
      Annulla
    </button>
    <button
      mat-flat-button
      color="primary"
      [disabled]="contractDataForm.pending"
      (click)="submitForm()"
    >
      Avanti
    </button>
  </div>
</div>
