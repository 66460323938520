import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import Messages from './assets/messages.json';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
  notFoundContent: any;

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.activatedRoute.data.subscribe((data) => {
      const key = Object.keys(data).length > 0 ? data.page : 'default';
      let { message, image, title } = Messages[key];
      this.notFoundContent = { message, image, title };
    });
  }
}
