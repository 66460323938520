import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AccountManagement } from '../../models/account-management.model';
import { Plan } from '../../models/plan.model';
import { ApiService } from '../../services/api.service';
import { DialogService } from '../../services/dialog.service';
import { ErrorService } from '../../services/error.service';
import { FormDataService } from '../../services/form-data.service';
import { NavbarService } from '../../services/navbar.service';
import { LoadingService } from '../../services/loading.service';
import { SessionService } from '../../services/session.service';

import { UserValidators } from '../../validators/username.validator';
import { AccountManagementValidators } from '../../validators/account-management.validators';
import { ScrollService } from 'src/app/services/scroll.service';
import { ProspectService } from 'src/app/services/prospect.service';

@Component({
  selector: 'app-account-management',
  templateUrl: './account-management.component.html',
  styleUrls: ['./account-management.component.scss'],
})
export class AccountManagementComponent implements OnInit, OnDestroy {
  plan: Plan;
  accountManagementForm: UntypedFormGroup;
  isProspect: boolean;

  accountManagerDocument$: Observable<File>;
  cardReferentDocument$: Observable<File>;

  private accountManagerInfoWarning: BehaviorSubject<boolean>;
  accountManagerInfoWarning$: Observable<boolean>;

  private cardReferentInfoWarning: BehaviorSubject<boolean>;
  cardReferentInfoWarning$: Observable<boolean>;

  watchedControlSubscriptions: Subscription;
  documentSubscriptions: Subscription;

  constructor(
    private el: ElementRef,
    private dialogService: DialogService,
    private formBuilder: UntypedFormBuilder,
    private formDataService: FormDataService,
    private loadingService: LoadingService,
    private navbarService: NavbarService,
    private userValidators: UserValidators,
    private router: Router,
    private apiService: ApiService,
    private accountManagementValidators: AccountManagementValidators,
    private sessionService: SessionService,
    private errorService: ErrorService,
    private scrollService: ScrollService,
    private prospectService: ProspectService,
  ) {
    this.plan = this.sessionService.getPlan();

    this.accountManagerDocument$ = formDataService.accountManagerDocument$;
    this.cardReferentDocument$ = formDataService.cardReferentDocument$;

    this.watchedControlSubscriptions = new Subscription();
    this.documentSubscriptions = new Subscription();

    this.accountManagerInfoWarning = new BehaviorSubject(false);
    this.accountManagerInfoWarning$ = this.accountManagerInfoWarning.asObservable();

    this.cardReferentInfoWarning = new BehaviorSubject(false);
    this.cardReferentInfoWarning$ = this.cardReferentInfoWarning.asObservable();
  }

  ngOnInit(): void {
    this.isProspect = this.sessionService.getIsProspect();
    const defaultFormData = this.sessionService.getAccountManagementFormData();

    if (this.isProspect) {
      this.accountManagementForm =
        this.prospectService.buildAccountManagementForm(defaultFormData);
      this.accountManagementForm.disable();
    } else {
      this.accountManagementForm = this._buildForm(defaultFormData);

      this.accountManagementForm.statusChanges
        .pipe(filter(() => !this.accountManagementForm.valid))
        .subscribe(() => this._onFormInValid());

      const watchedAccountManagerControls = [
        this.accountManagementForm.controls.firstName,
        this.accountManagementForm.controls.lastName,
        this.accountManagementForm.controls.email,
        this.accountManagementForm.controls.mobileNumber,
      ];

      watchedAccountManagerControls.forEach((control) => {
        const controlSubscription = this.formDataService
          .watchConsistencyInfo(control)
          .subscribe((isValueChanged) => {
            this.accountManagerInfoWarning.next(isValueChanged);
          });
        this.watchedControlSubscriptions.add(controlSubscription);
      });

      const watchedCardReferentControls = [
        this.accountManagementForm.controls.cardFirstName,
        this.accountManagementForm.controls.cardLastName,
      ];

      watchedCardReferentControls.forEach((control) => {
        const controlSubscription = this.formDataService
          .watchConsistencyInfo(control)
          .subscribe((isValueChanged) => {
            this.cardReferentInfoWarning.next(isValueChanged);
          });
        this.watchedControlSubscriptions.add(controlSubscription);
      });

      const accountManagerDocumentSubscription = this.accountManagerDocument$
        .pipe(map((file) => (file !== null ? true : false)))
        .subscribe((isFileUploaded: boolean) => {
          this.accountManagerInfoWarning.next(!isFileUploaded);
        });
      this.documentSubscriptions.add(accountManagerDocumentSubscription);

      const cardReferentDocumentSubscription = this.cardReferentDocument$
        .pipe(map((file) => (file !== null ? true : false)))
        .subscribe((isFileUploaded: boolean) => {
          this.cardReferentInfoWarning.next(!isFileUploaded);
        });
      this.documentSubscriptions.add(cardReferentDocumentSubscription);
    }
  }

  saveDraft() {
    // Save a draft of the form in the session
    const { accountManagerFile, cardReferentFile } =
      this.sessionService.getUploadedFilesData();

    const formData: any = {
      ...this.accountManagementForm.value,

      accountManagerId: accountManagerFile ? accountManagerFile.fileKey : null,
      cardReferentId: cardReferentFile ? cardReferentFile.fileKey : null,
    };

    this.sessionService.saveAccountManagementFormData({
      ...formData,
      accountManagerId: accountManagerFile ? accountManagerFile.fileKey : null,
      cardReferentId: cardReferentFile ? cardReferentFile.fileKey : null,
    });

    this.router.navigate(['/contract-data']);
  }

  submitForm() {
    // Che the form valid before sending to the backend
    if (this.accountManagementForm.invalid) {
      this.accountManagementForm.setErrors({
        validationMessage:
          'Errore durante il salvataggio. Correggi i campi non validi e riprova.',
      });
      this.accountManagementForm.markAllAsTouched();

      return;
    }

    this.loadingService.startSpinner();
    const { accountManagerFile, cardReferentFile } =
      this.sessionService.getUploadedFilesData();

    const formData: any = {
      ...this.accountManagementForm.value,

      accountManagerId: accountManagerFile ? accountManagerFile.fileKey : null,
      cardReferentId: cardReferentFile ? cardReferentFile.fileKey : null,
    };

    this.apiService.submitAccountManagementForm(formData).subscribe(
      (accountManagement: AccountManagement) => {
        this.formDataService.saveAccountManagementForm(accountManagement);
        this.router.navigate(['/signature']).then(() => {
          this.loadingService.stopSpinner();
        });
      },
      (errorResponse: HttpErrorResponse) => {
        this.loadingService.stopSpinner();
        this._handleError(errorResponse);

        // SMARMELLO: Remove setTimeout, the class is added after few ms...
        setTimeout(() => {
          const firstInvalid = this._getFirstInvalidControlPosition();
          this.scrollService.scrollToCoords(firstInvalid);
        }, 50);
      },
    );
  }

  openDialog(dialogDataSlug) {
    this.dialogService.open(dialogDataSlug);
  }

  copyFromDirector(targetControlName: string) {
    const { directorFirstName, directorLastName } =
      this.sessionService.getContractFormData();

    const { directorFile } = this.sessionService.getUploadedFilesData();

    switch (targetControlName) {
      case 'accountManager':
        this.accountManagementForm.controls.firstName.setValue(directorFirstName);
        this.accountManagementForm.controls.lastName.setValue(directorLastName);

        this.accountManagementForm.controls.accountManagerId.setValue(
          directorFile.fileKey,
        );

        this.formDataService.saveAccountManagerDocument(directorFile);
        this.sessionService.saveUploadedFiles({
          accountManagerFile: directorFile,
        });

        break;
      case 'cardReferent':
        this.accountManagementForm.controls.cardFirstName.setValue(directorFirstName);
        this.accountManagementForm.controls.cardLastName.setValue(directorLastName);

        this.accountManagementForm.controls.cardReferentId.setValue(directorFile.fileKey);

        this.formDataService.saveCardReferentDocument(directorFile);
        this.sessionService.saveUploadedFiles({
          cardReferentFile: directorFile,
        });

        break;
      default:
        break;
    }
  }

  copyFromAccountManager() {
    const { accountManagerFile } = this.sessionService.getUploadedFilesData();
    const accountManagerFirstName = this.accountManagementForm.controls.firstName.value;
    const accountManagerLastName = this.accountManagementForm.controls.lastName.value;

    this.accountManagementForm.controls.cardFirstName.setValue(accountManagerFirstName);
    this.accountManagementForm.controls.cardLastName.setValue(accountManagerLastName);

    this.accountManagementForm.controls.cardReferentId.setValue(
      accountManagerFile.fileKey,
    );

    this.formDataService.saveCardReferentDocument(accountManagerFile);
    this.sessionService.saveUploadedFiles({
      cardReferentFile: accountManagerFile,
    });
  }

  ngOnDestroy(): void {
    this.documentSubscriptions && this.documentSubscriptions.unsubscribe();
    this.watchedControlSubscriptions && this.watchedControlSubscriptions.unsubscribe();
  }

  private _getFirstInvalidControlPosition(): number {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      '.mat-form-field-invalid',
    );

    if (!firstInvalidControl) {
      return null;
    }

    return firstInvalidControl.offsetTop;
  }

  private _onFormInValid() {
    this.navbarService.disableStep('payment');
  }

  private _buildForm(formDefaultValues: AccountManagement): UntypedFormGroup {
    return this.formBuilder.group({
      firstName: [
        formDefaultValues.firstName,
        [Validators.required, Validators.maxLength(255)],
      ],
      lastName: [
        formDefaultValues.lastName,
        [Validators.required, Validators.maxLength(255)],
      ],
      accountManagerId: [formDefaultValues.accountManagerId],
      email: [
        formDefaultValues.email,
        [
          Validators.required,
          Validators.pattern(
            "^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$",
          ),
        ],
      ],
      mobileNumber: [
        formDefaultValues.mobileNumber,
        [
          Validators.required,
          Validators.pattern(
            '^(\\((00|\\+)39\\)|(00|\\+)39)?\\s?(38[890]|34[4-90]|36[680]|33[13-90]|32[89]|35[01]|37[019])\\s?(\\d{3}\\s?\\d{3,4}|\\d{6,7})$',
          ),
        ],
      ],
      cardFirstName: [
        formDefaultValues.cardFirstName,
        [Validators.required, Validators.maxLength(255)],
      ],
      cardLastName: [
        formDefaultValues.cardLastName,
        [Validators.required, Validators.maxLength(255)],
      ],
      cardReferentId: [formDefaultValues.cardReferentId],
      circuitPropensity: [
        formDefaultValues.circuitPropensity,
        [
          Validators.required,
          this.accountManagementValidators.circuitPropensityValidator(this.plan),
        ],
      ],
      acceptancePercentage: [
        formDefaultValues.acceptancePercentage,
        [Validators.required, Validators.pattern('^25|50|75|100$')],
      ],
      onlineName: [
        formDefaultValues.onlineName,
        [Validators.required, Validators.maxLength(255)],
      ],
      loginName: [
        formDefaultValues.loginName,
        [Validators.required, Validators.minLength(3), Validators.maxLength(30)],
        this.userValidators.usernameValidator(),
      ],
    });
  }

  private _handleError(errorResponse: HttpErrorResponse): void {
    this.accountManagementForm = this.errorService._handleSubmitError(
      this.accountManagementForm,
      errorResponse,
    );
  }
}
