import { Component, OnInit } from '@angular/core';
import { ScrollService } from 'src/app/services/scroll.service';
import { ScrollElement } from 'src/app/models/scroll-element.model';
import { SessionService } from 'src/app/services/session.service';
import { Events } from 'src/app/shared/utils/events';

import { ENV } from '../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  showScrollNav: boolean;
  navElements: ScrollElement[];
  landingRoute: string = '/';
  isProduction: boolean = ENV.production;

  constructor(
    private scrollService: ScrollService,
    private sessionService: SessionService,
    private events: Events,
  ) {
    this.showScrollNav = false;
  }

  ngOnInit(): void {
    this.scrollService.elements$.subscribe((navElements) => {
      this.showScrollNav = !!navElements.length;
      this.navElements = navElements;
    });
    this.events.subscribe('save-landing-route', () => {
      this.landingRoute = this.sessionService.getLandingRoute() || '/';
    });
  }

  scrollTo(scrollElementSlug: string) {
    this.scrollService.scrollTo(scrollElementSlug);
  }
}
