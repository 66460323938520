import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { NavbarService } from './navbar.service';
import { Step } from '../models/step.model';

@Injectable({
  providedIn: 'root',
})
export class StepGuardService implements CanActivate {
  constructor(public router: Router, private navbarService: NavbarService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const activeRoute: string = route.routeConfig.path;
    const navbarStep: Step = this.navbarService.getStep(activeRoute);

    if (navbarStep.isEnabled === false) {
      this.router.navigate([`/${this.navbarService.getFirstValidStep().slug}`]);
    }
    return navbarStep.isEnabled;
  }
}
