class DialogData {
  title: string;
  content: string;
}

const dialogsData = {
  privacyPolicyBase: {
    title: 'Trattamento dei dati personali',
    content: `
    <h1>Informativa per il trattamento dei dati personali</strong></h1>
    <p><span style="font-weight: 400;">Ex art. 13 Regolamento UE 2016/679</span></p>

    <p><strong>SARDEX S.p.A.</strong><span style="font-weight: 400;">, con sede in (09038) Serramanna, Viale Sant&rsquo;Ignazio, 16, P.I. 03257760920, (in seguito, &ldquo;</span><strong>Titolare</strong><span style="font-weight: 400;">&rdquo;), in qualit&agrave; di titolare del trattamento, informa l&rsquo;Azienda iscritta - ai sensi dell&rsquo;art. 13 Regolamento UE n. 2016/679 (in seguito, &ldquo;</span><strong>GDPR</strong><span style="font-weight: 400;">&rdquo;) - che i dati personali acquisiti nel corso del rapporto contrattuale saranno trattati con le modalit&agrave; e per le finalit&agrave; seguenti.</span></p>
    <p><span style="font-weight: 400;">La presente Privacy Policy fornisce informazioni sul tipo di dati raccolti e sulla modalit&agrave; di raccolta, sul motivo della raccolta e sull&rsquo;utilizzo che ne viene fatto, sui diritti e sulle opzioni relative all&rsquo;utilizzo dei dati da parte nostra, sul modo in cui i dati vengono trattati e con chi potrebbero essere condivisi, per quanto tempo vengono conservate le informazioni, ecc. Si prega di leggerla attentamente.</span></p>

    <h2><strong>1. Oggetto del Trattamento</strong></h2>

    <h3><strong>Dati personali</strong></h3>
    <p><span style="font-weight: 400;">Oltre ai dati raccolti con metodi automatizzati (come specificato in &ldquo;dati di navigazione&rdquo;), trattiamo anche i dati forniti dall&rsquo;Azienda iscritta al momento della sottoscrizione del contratto di iscrizione al Circuito. Questi comprendono, a titolo esemplificativo:</span></p>
    <ul>
    <li style="font-weight: 400;"><span style="font-weight: 400;">I dati identificativi dell'impresa aderente (es. nome online, ragione sociale, partita IVA, CF, indirizzi sede legale e sede operativa, recapiti telefonici e mail, settore di attivit&agrave;, n. dipendenti, fatturato ultimo anno)</span></li>
    <li style="font-weight: 400;"><span style="font-weight: 400;">I dati identificativi del referente Conto Sardex e dell&rsquo;intestatario Carta Sardex con allegata la copia della carta d&rsquo;identit&agrave; dello stesso&nbsp;</span></li>
    <li style="font-weight: 400;"><span style="font-weight: 400;">Le informazioni fornite liberamente dall&rsquo;Azienda in fase di contatto o d&rsquo;iscrizione</span></li>
    <li style="font-weight: 400;"><span style="font-weight: 400;">Se l&rsquo;Azienda iscritta ci contatta, potrebbe essere presente un registro della reciproca corrispondenza.</span></li>
    <li style="font-weight: 400;"><span style="font-weight: 400;">Se l&rsquo;Azienda iscritta ci contatta per segnalare un problema con il nostro sito o per ricevere assistenza tecnica e/o per i clienti, potrebbe essere presente un registro della corrispondenza o della conversazione.</span></li>
    <li style="font-weight: 400;"><span style="font-weight: 400;">Informazioni sull&rsquo;identit&agrave; nei canali social, per comunicazioni o campagne sponsorizzate riguardanti eventi o novit&agrave; all&rsquo;interno del programma. Canali social di seguito elencati:</span></li>
    <ul>
    <li style="font-weight: 400;"><span style="font-weight: 400;">Twitter</span></li>
    <li style="font-weight: 400;"><span style="font-weight: 400;">Instagram</span></li>
    <li style="font-weight: 400;"><span style="font-weight: 400;">Linkedin</span></li>
    <li style="font-weight: 400;"><span style="font-weight: 400;">Youtube</span></li>
    <li style="font-weight: 400;"><span style="font-weight: 400;">Facebook</span></li>
    </ul>
    </ul>
    <p><span style="font-weight: 400;">All&rsquo;Azienda iscritta potrebbero anche essere proposti sondaggi che utilizziamo a scopo di ricerca, sebbene l&rsquo;Azienda non sia obbligata a completarli. Queste informazioni vengono utilizzate per le finalit&agrave; descritte nella presente Privacy Policy. L&rsquo;Azienda iscritta pu&ograve; modificare in qualsiasi momento i propri dati personali e opporsi al loro ulteriore trattamento (come specificato in seguito al paragrafo 8).</span></p>

    <h3><strong>Dati relativi alle transazioni</strong></h3>
    <p><span style="font-weight: 400;">Sono conservati cluster di informazioni rispetto alle abitudini di consumo e alle transazioni effettuate, siano esse in acquisto o in vendita, per categoria merceologica. Tra tali dati potrebbero essere ricomprese categorie particolari di dati personali come dettagliate dall&rsquo;art. 9 GDPR (es. transazioni riguardanti prestazioni sanitarie).</span></p>

    <h3><strong>Dati di navigazione</strong></h3>
    <p><span style="font-weight: 400;">I nostri siti web e le nostre applicazioni (mobili) possono utilizzare diverse tecnologie per essere pi&ugrave; fruibili, pi&ugrave; facili da utilizzare, efficienti e sicuri. Tali tecnologie potrebbero comportare la raccolta automatica dei dati personali da parte nostra. Esempi di tali tecnologie includono cookie, flash cookie e analisi dei dati web. Nel caso in cui si segua un collegamento a uno di questi siti web, si tenga presente che tali siti web dispongono di proprie privacy policy per le quali non ci assumiamo alcuna responsabilit&agrave;. Controlla queste politiche prima di inviare qualsiasi dato personale ai siti web.</span></p>
    <p><span style="font-weight: 400;">Quando un utente visita il nostro sito web, il browser dell&rsquo;utente invia automaticamente dati al nostro server. Questi dati ci consentono di ottimizzare i nostri servizi e di migliorare l&rsquo;esperienza dell&rsquo;utente sui nostri siti e sulle nostre applicazioni. I dati vengono automaticamente raccolti e archiviati da noi o da terze parti per nostro conto. Il nostro sito web utilizza i cookie. Per maggiori informazioni sul nostro utilizzo dei cookie, si pu&ograve; consultare la nostra Cookie Policy</span></p>

    <p><span style="font-weight: 400;">https://www.iubenda.com/privacy-policy/8114991</span></p>

    <p><span style="font-weight: 400;">Le informazioni relative al </span><em><span style="font-weight: 400;">device</span></em><span style="font-weight: 400;"> dell&rsquo;utente potrebbero essere raccolte per scopi di amministrazione del sistema, nonch&eacute; per riportare informazioni aggregate con finalit&agrave; di analisi di marketing interne. Si tratta di dati relativi alle azioni e ai modelli di navigazione dei nostri utenti e potrebbero includere quanto segue:</span></p>
    <ul>
      <li>Indirizzo IP del visitatore</li>
      <li>Data e ora della visita</li>
      <li>URL di riferimento (il sito da cui il visitatore &egrave; stato indirizzato)</li>
      <li>Pagine visitate e navigazione dell&rsquo;utente sul nostro sito web</li>
      <li>Informazioni sul browser utilizzato (tipo, versione, sistema operativo ecc.)</li>
      <li>Tipo di operazioni svolte.</li>
      <li>Annunci visualizzati.</li>
    </ul>

    <h2>2. Finalit&agrave; del trattamento e relative basi giuridiche</h2>

    <h3><strong>2.1 Finalit&agrave; di servizio</strong></h3>
    <p><span style="font-weight: 400;">Quando l&rsquo;Azienda iscritta sottoscrive un accordo con noi, tratteremo i dati personali che sono stati raccolti per quel particolare scopo. Il trattamento di tali dati &egrave; necessario per l&rsquo;esecuzione del nostro contratto con l&rsquo;Azienda iscritta&nbsp; o per poterne gestire le richieste precontrattuali e/o derivanti dal contratto e per tutti gli obblighi legali connessi.</span></p>
    <p><span style="font-weight: 400;">Le finalit&agrave; sono pertanto le seguenti:</span></p>
    <ul>
    <li style="font-weight: 400;"><span style="font-weight: 400;">concludere i contratti per i servizi forniti dal Titolare;</span></li>
    <li style="font-weight: 400;"><span style="font-weight: 400;">adempiere agli obblighi precontrattuali, contrattuali e fiscali derivanti dai rapporti con l&rsquo;Azienda iscritta;</span></li>
    <li style="font-weight: 400;"><span style="font-weight: 400;">adempiere agli obblighi previsti dalla legge, da un regolamento, dalla normativa comunitaria o da un ordine dell&rsquo;Autorit&agrave; (come ad esempio in materia di antiriciclaggio).</span></li>
    </ul>
    <p><strong>Le basi giuridiche per tale trattamento sono l&rsquo;obbligo di legge, nonch&eacute; le finalit&agrave; precontrattuali e contrattuali (art. 6, paragrafo 1, lett. b) e c) GDPR).</strong></p>

    <h3><strong>2.2 Finalit&agrave; di profilazione</strong></h3>
    <p><span style="font-weight: 400;">L&rsquo;elaborazione dei dati personali e delle abitudini di consumo dell&rsquo;Azienda iscritta ci consente di proporre offerte migliori e conformi alle esigenze personali della singola Azienda iscritta.&nbsp;</span></p>
    <p><span style="font-weight: 400;">Solo previo specifico ed espresso consenso, potremo trattare i dati forniti dall&rsquo;Azienda iscritta per finalit&agrave; di profilazione ossia per valutare gusti, preferenze ed abitudini di consumo anche correlate ad indagini di mercato ed analisi di tipo statistico. Rientra in questa categoria qualsiasi forma di Trattamento automatizzato di Dati Personali per valutare determinati aspetti personali quali quelli riguardanti, a titolo esemplificativo e non esaustivo, le preferenze personali, gli interessi, il comportamento commerciale, in relazione alla ricorrenza degli acquisti, a pattern di appartenenza, alla zona geografica.</span></p>
    <p><span style="font-weight: 400;">La base giuridica per tale trattamento &egrave; il consenso (art. 6, paragrafo 1, lett. a) GDPR e art. 9, paragrafo 2, lett. a)).</span></p>

    <h3><strong>2.3 Finalit&agrave; commerciali, di marketing diretto e/o indiretto</strong></h3>
    <p><span style="font-weight: 400;">Potremo anche trattare i dati personali ordinari dell&rsquo;Azienda iscritta&nbsp; sulla base delle regole contenute nell&rsquo;articolo 6, paragrafo 1, lettera f) GDPR, a meno che non prevalgano sui nostri interessi i diritti o le libert&agrave; fondamentali dell&rsquo;Azienda iscritta. Abbiamo un </span><strong>legittimo interesse</strong><span style="font-weight: 400;"> al trattamento dei dati personali per scopi di </span><strong>marketing diretto</strong><span style="font-weight: 400;">. Il nostro interesse legittimo si basa sulle preferenze in modo da poter personalizzare al meglio la nostra offerta e, da ultimo, offrire prodotti e servizi che ne soddisfino meglio le esigenze.</span></p>
    <p><span style="font-weight: 400;">Avuto riguardo alle finalit&agrave; di </span><strong>marketing indiretto</strong><span style="font-weight: 400;">, potremo trattare i dati personali, previo specifico e distinto consenso, per svolgere attivit&agrave; promozionali e/o di marketing per conto di terzi. Rientrano in questa categoria tutte le attivit&agrave; compiute per promuovere prodotti, servizi, venduti e/o erogati da soggetti terzi con i quali il Titolare intrattiene rapporti giuridici di collaborazione o in partnership.</span></p>
    <p><span style="font-weight: 400;">Tale trattamento potr&agrave; riguardare anche la comunicazione di nuove convenzioni o iniziative promosse da singoli esercenti o per informazioni promozionali e pubblicitarie. L&rsquo;Azienda iscritta potr&agrave; in qualsiasi momento scegliere di sospendere tale invio, scrivendo una mail ai riferimenti in calce o utilizzando gli strumenti tecnologici a sua disposizione (es. tasto unsubscribe in calce alle mail).</span></p>
    <p><span style="font-weight: 400;">Le modalit&agrave; di contatto relative alla presente finalit&agrave; di marketing diretto e/o indiretto potranno essere sia di tipo automatizzato (email, sms, mms, telefonate senza operatore) sia di tipo tradizionale (telefonate con operatore, invii postali). In ogni caso, l&rsquo;Azienda iscritta&nbsp; potr&agrave; revocare il suo consenso, anche in modo parziale, ad esempio richiedendo l&rsquo;invio di informazioni con le sole modalit&agrave; di contatto tradizionali.</span></p>

    <h2><strong>3. Modalit&agrave; di trattamento</strong></h2>
    <p><span style="font-weight: 400;">Il trattamento dei dati personali &egrave; realizzato per mezzo delle operazioni indicate all&rsquo;art. 4 n. 2) GDPR e precisamente: raccolta, registrazione, organizzazione, conservazione, consultazione, elaborazione, modificazione, selezione, estrazione, raffronto, utilizzo, interconnessione, blocco, comunicazione, cancellazione e distruzione dei dati. I dati personali sono sottoposti a trattamento sia cartaceo che elettronico e/o automatizzato. Adottiamo adeguate misure tecniche e organizzative per proteggere i dati personali dall&rsquo;accidentale o illecita distruzione, perdita, alterazione, uso non autorizzato, divulgazione o accesso, in particolare laddove il trattamento implica la trasmissione dei dati su una rete, e contro tutte le altre forme di trattamento illecito e uso improprio.</span></p>

    <h2><strong>4. Periodo di conservazione dei dati personali</strong></h2>
    <p><span style="font-weight: 400;">Segnaliamo che, nel rispetto dei principi di liceit&agrave;, limitazione delle finalit&agrave; e minimizzazione dei dati, ai sensi dell&rsquo;art. 5 GDPR, il periodo di conservazione dei dati personali &egrave; stabilito per un arco di tempo non superiore al conseguimento delle finalit&agrave; per le quali sono raccolti e trattati e nel rispetto delle tempi prescritti dalla legge e per le esigenze civilistiche connesse.</span></p>
    <p><span style="font-weight: 400;">In particolare, i dati personali saranno conservati nei nostri archivi come segue: i dati amministrativi saranno conservati per 10 anni; la documentazione tecnica e la contrattualistica per 5 anni; i dati relativi al servizio di Google Analytics per 2 anni; i dati raccolti per finalit&agrave; di marketing per 2 anni; tali termini devono intendersi decorrenti dal momento della cessazione del servizio.</span></p>

    <h2><strong>5. Categorie di soggetti cui sono comunicati i dati personali</strong></h2>
    <p><span style="font-weight: 400;">I dati potranno essere resi accessibili per le finalit&agrave; sopra dettagliate:</span></p>
    <ul>
    <li style="font-weight: 400;"><span style="font-weight: 400;">a dipendenti e collaboratori del Titolare nella loro qualit&agrave; di autorizzati al trattamento;</span></li>
    <li style="font-weight: 400;"><span style="font-weight: 400;">a societ&agrave; terze o altri soggetti (a titolo indicativo, consulenti, call center esterni, fornitori di servizi, etc.) che svolgono attivit&agrave; in outsourcing per conto del Titolare, nella loro qualit&agrave; di responsabili esterni del trattamento. Queste terze parti possono trattare i dati personali solo nella misura necessaria per erogare i loro servizi. I nostri responsabili del trattamento dei dati hanno l&rsquo;obbligo contrattuale di trattare tali informazioni con la massima riservatezza.</span></li>
    </ul>
    <p><span style="font-weight: 400;">Il Titolare potr&agrave; inoltre comunicare i dati dell&rsquo;Azienda iscritta per le finalit&agrave; di cui all&rsquo;art. 2.1 ai soggetti pubblici destinatari per legge, alle Autorit&agrave; giudiziarie, nonch&eacute; a tutti quei soggetti ai quali la comunicazione sia obbligatoria per finalit&agrave; di legge. Detti soggetti tratteranno i dati nella loro qualit&agrave; di autonomi titolari del trattamento. </span><strong>I dati personali non saranno diffusi.</strong></p>

    <h2><strong>6. Trasferimento dati verso Paesi terzi</strong></h2>
    <p><span style="font-weight: 400;">I dati personali sono conservati su server ubicati in Italia e in altri paesi dell&rsquo;Euro zona. Il Titolare, ove si rendesse necessario, avr&agrave; facolt&agrave; di spostare i server anche extra-UE. In tal caso, il Titolare assicura sin d&rsquo;ora che il trasferimento dei dati extra-UE avverr&agrave; in conformit&agrave; alle disposizioni di legge applicabili, previa stipula delle clausole contrattuali standard previste dalla Commissione Europea.</span></p>

    <h2><strong>7. Natura del conferimento dei dati e conseguenze del rifiuto di rispondere</strong></h2>
    <p><span style="font-weight: 400;">Ferma l&rsquo;autonomia dell&rsquo;Azienda iscritta, il conferimento dei dati per le finalit&agrave; di cui all&rsquo;art. 2.1 &egrave; obbligatorio. In loro assenza, non saremo in grado di garantire i servizi contrattuali dettagliati nell&rsquo;art. 2.1.</span></p>
    <p><span style="font-weight: 400;">Il conferimento dei dati per le finalit&agrave; di cui all&rsquo;art. 2.2 &egrave; invece facoltativo, cos&igrave; come &egrave; facoltativo quello relativo alle finalit&agrave; di cui all&rsquo;art. 2.3. L&rsquo;Azienda iscritta pu&ograve; quindi decidere di negare il consenso o di revocare il consenso gi&agrave; fornito: in tal caso, non potr&agrave; ricevere comunicazioni commerciali e materiale promozionale inerenti i servizi offerti dal Titolare. Continuer&agrave; comunque ad avere diritto ai servizi di cui all&rsquo;art. 2.1.</span></p>

    <h2><strong>8. Diritti dell&rsquo;interessato</strong></h2>
    <p><span style="font-weight: 400;">L&rsquo;interessato pu&ograve; beneficiare dei diritti elencati nell&rsquo;art. 15 GDPR e precisamente:</span></p>
    <ol>
    <li style="font-weight: 400;"><span style="font-weight: 400;">Diritto di accesso ai dati personali;</span></li>
    <li style="font-weight: 400;"><span style="font-weight: 400;">Diritto di ottenere la rettifica o la cancellazione degli stessi o la limitazione del trattamento che lo riguardano;</span></li>
    <li style="font-weight: 400;"><span style="font-weight: 400;">Diritto di opporsi al trattamento;</span></li>
    <li style="font-weight: 400;"><span style="font-weight: 400;">Diritto di revocare il consenso, ove previsto (la revoca del consenso non pregiudica la liceit&agrave; del trattamento basata sul consenso conferito prima della revoca);</span></li>
    <li style="font-weight: 400;"><span style="font-weight: 400;">Diritto di proporre reclamo all&rsquo;autorit&agrave; di controllo italiana (&ldquo;Garante per la Protezione dei Dati Personali&rdquo; i cui dati di contatto sono indicati nel sito www.garanteprivacy.it).</span></li>
    </ol>
    <p><span style="font-weight: 400;">Ove applicabili, ha altres&igrave; i diritti di cui agli artt. 16-21 GDPR (diritto all&rsquo;oblio, diritto di limitazione di trattamento, diritto alla portabilit&agrave; dei dati, diritto di opposizione).</span></p>

    <h2><strong>9. Modalit&agrave; di esercizio dei diritti</strong></h2>
    <p><span style="font-weight: 400;">L&rsquo;Azienda iscritta&nbsp; potr&agrave; in qualsiasi momento esercitare i suoi diritti inviando:</span></p>
    <ul>
    <li style="font-weight: 400;"><span style="font-weight: 400;">una raccomandata a.r. a </span><strong>SARDEX S.p.A.</strong><span style="font-weight: 400;">, con sede in (09038) Serramanna, Via Serra, 44</span></li>
    <li style="font-weight: 400;"><span style="font-weight: 400;">una mail ordinaria a privacy@sardexpay.net;</span></li>
    <li style="font-weight: 400;"><span style="font-weight: 400;">una e-mail all&rsquo;indirizzo dpo@sardexpay.net.</span></li>
    </ul>

    <h2><strong>10. Titolare, DPO e Responsabili.</strong></h2>
    <p><span style="font-weight: 400;">Il Titolare del trattamento &egrave; SARDEX S.p.A., con sede in (09038) Serramanna, Via Serra, 44.</span></p>
    <p><span style="font-weight: 400;">Il </span><strong>Responsabile della Protezione dei Dati (Data Protection Officer)</strong><span style="font-weight: 400;"> cui &egrave; possibile rivolgersi per esercitare i diritti sopra elencati e/o per eventuali chiarimenti in materia di tutela dati personali &egrave; raggiungibile all&rsquo;indirizzo mail: dpo@sardexpay.net</span></p>
    <p><span style="font-weight: 400;">L&rsquo;elenco aggiornato dei responsabili esterni del trattamento &egrave; custodito presso la sede legale del Titolare del trattamento.</span></p>

    <h2><strong>11. Modifiche a questa Policy</strong></h2>
    <p><span style="font-weight: 400;">SARDEX si &egrave; impegnata a rispettare i principi fondamentali della privacy e della protezione dei dati. Pertanto, rivediamo periodicamente la nostra Privacy Policy al fine di mantenerla aggiornata e conforme ai principi sulla privacy e sulla protezione dei dati. Questa Privacy Policy pu&ograve; essere modificata in futuro al fine di tenere il passo con i nuovi sviluppi e le opportunit&agrave; associate a Internet e, soprattutto, al fine di mantenere la conformit&agrave; alla legislazione vigente (europea e nazionale). Eventuali modifiche che potremmo apportare alla nostra Privacy Policy in futuro saranno pubblicate e rese disponibili al momento dell&rsquo;accesso nell&rsquo;area riservata dell&rsquo;iscritto e, se necessario, potranno essere comunicate all&rsquo;Azienda iscritta via e-mail.&nbsp;</span></p>

    <p><strong><em>Ultimo aggiornamento: marzo 2019&nbsp;</em></strong></p>
    `,
  },
  referenteConto: {
    title: 'Referente del conto',
    content:
      'Il referente del conto rappresenta la persona di riferimento per la gestione del conto Sardex.',
  },
  intestatarioCarta: {
    title: 'Intestatario della carta',
    content:
      "L'intestatario della carta rappresenta la persona autorizzata all'utilizzo della carta Sardex fornita al momento dell'adesione al circuito.",
  },
  disponibilitaCommerciale: {
    title: 'Disponibilità commerciale e percentuale di compensazione',
    content: `
    <h3><strong>Disponibilità commerciale</strong></h3>
    <span style="font-weight: 400;">La disponibilità commerciale, ricordando che 1 SRD equivale a 1 €, è la quantità di beni e servizi che ogni azienda mette a disposizione del Circuito, ovvero l'obiettivo minimo di fatturato che l'azienda iscritta si prefissa quando entra nel mercato Sardex. </span>
    <br />
    <span style="font-weight: 400;">Ciascun aderente a Sardex può rimanere al di sotto della disponibilità commerciale concordata, oppure raggiungerla o ancora, se fattura più del previsto, superarla e continuare a crescere nel Circuito concordando quindi un nuovo traguardo di fatturato a cui tendere.</span>
    <br />
    <br />
    <h3><strong>Percentuale di compensazione</strong></h3>
    <span style="font-weight: 400;">Ogni impresa iscritta al Circuito si impegna, fino a mille euro di valore commerciale, a effettuare e a richiedere l\’intero pagamento della fornitura in Crediti Sardex.</span>
    <br />
    <span style="font-weight: 400;">Superata questa soglia, quindi per tutti quegli acquisti e vendite di valore superiore ai 1000 euro, per la parte eccedente (esclusi quindi i primi 1000) è possibile per le parti accordarsi su un pagamento parte in euro e parte in crediti.</span>
    <br />
    <span style="font-weight: 400;">All\’atto della sottoscrizione del contratto ogni azienda segnala una percentuale di compensazione per le vendite di valore superiore ai 1000 €.</span>
    <br />
    <span style="font-weight: 400;">
    La percentuale di compensazione può avere valori di 25%, 50%, 75%, 100%. Una volta stabilita, sottoscritta contrattualmente e resa visibile agli altri utenti quest’ultima deve essere sempre e comunque rispettata.</span>`,
  },
  nomeOnline: {
    title: 'Nome online',
    content:
      "Il nome online rappresenta la denominazione della tua azienda, ovvero la tua insegna, all'interno del circuito. E' il nome attraverso cui gli altri scritti individueranno la tua azienda e sarà visibile nella tua vetrina personale.",
  },
  nomeLogin: {
    title: 'Nome di login',
    content:
      "Il nome di login rappresenta il tuo username, ovvero il nome utente che utilizzerai per effettuare l'accesso nella tua area riservata.",
  },
  terminiCondizioni: {
    title: 'Termini e condizioni',
    content: `
<h3><strong>1. Definizioni</strong></h3>
<p><strong>1.1</strong><span style="font-weight: 400;"> Nel presente contratto sono cos&igrave; definiti i seguenti termini:</span></p>
<p><strong>1.2 </strong><span style="font-weight: 400;">Circuito: &egrave; il mercato ideato e gestito da Sardex SpA per consentire ai propri iscritti di effettuare compravendite di beni e servizi con lo strumento dello scambio multilaterale attraverso operazioni in compensazione.</span></p>
<p><strong>1.3</strong><span style="font-weight: 400;"> Iscritto: soggetti persone giuridiche (da individuare in piccole-medie imprese, artigiani e professionisti) e persone fisiche, costituenti il settore dei consumatori finali, che aderendo al circuito fruiscono dei servizi offerti da Sardex SpA.&nbsp;</span></p>
<p><strong>1.4</strong><span style="font-weight: 400;"> Estratto Conto: insieme delle registrazioni tenute da Sardex SpA degli acquisti e delle vendite effettuate da ciascun iscritto.</span></p>
<p><strong>1.5</strong><span style="font-weight: 400;"> SardexPay: &egrave; il circuito che comprende gli iscritti e che viene gestito da Sardex SpA ed &egrave; il sistema ove vengono registrate le operazioni e i relativi rapporti di debito-credito di ogni iscritto, in un unico soggetto informale a cui fanno riferimento debiti e crediti riportati da ogni iscritto e le relative risultanze che verranno poi evidenziate e documentate dall&rsquo;estratto conto di ciascun iscritto.</span></p>
<p><strong>1.6</strong><span style="font-weight: 400;"> Sardex SpA: &egrave; il soggetto giuridico che ha ideato il circuito e che lo gestisce sulla scorta delle regole che verranno esposte in prosieguo.</span></p>
<h3><strong>2. Partecipazione al Circuito di credito commerciale SardexPay</strong></h3>
<h3><strong>2.1 Modalit&agrave; di adesione</strong></h3>
<p><span style="font-weight: 400;">Il rapporto fra Sardex SpA e l&rsquo;iscritto s&rsquo;intende regolato dalla Richiesta di Iscrizione al Circuito Sardex debitamente sottoscritta e firmata e dalle presenti Condizioni Generali di Contratto. Il contratto s&rsquo;intende perfezionato al momento dell&rsquo;accettazione da parte di Sardex SpA, della Richiesta di Iscrizione. La richiesta dovr&agrave; essere formulata per iscritto, a mezzo lettera raccomandata, fax o mail certificata, ovvero via web attraverso la piattaforma indicata ai potenziali aderenti. La richiesta di iscrizione sar&agrave; considerata tacitamente accettata, se non rigettata da Sardex SpA entro 5 giorni dalla data di ricezione della medesima. La domanda di iscrizione pu&ograve; essere compilata in presenza di un operatore autorizzato, presso le sedi operative di Sardex SpA e presso i portali web gestiti da Sardex SpA, nonch&eacute; dai consulenti di Sardex, fermo restando che in ogni caso la Richiesta potr&agrave; essere accolta esclusivamente da Sardex.&nbsp;</span></p>
<h3><strong>2.2 Premesse di adesione</strong></h3>
<p><span style="font-weight: 400;">Fatto salvo quanto verr&agrave; esposto in seguito, tutte le persone fisiche capaci d&rsquo;intendere e di agire e in pieno possesso dei diritti civili, come pure le persone giuridiche e le societ&agrave; individuali che esercitano un&rsquo;attivit&agrave; in proprio, possono richiedere l&rsquo;iscrizione al Circuito di Credito Commerciale SardexPay.</span></p>
<h3><strong>2.3 Impedimento all&rsquo;adesione</strong></h3>
<p><strong>2.3.1</strong><span style="font-weight: 400;"> Non potranno aderire al circuito e le loro domande saranno pertanto respinte, quei soggetti (persone giuridiche e/o societ&agrave; di persone, professionisti e/o artigiani) che non dispongano di beni o servizi, che possano costituire oggetto di scambio nel circuito o nei confronti delle quali siano aperte una delle procedure concorsuali previste dalla legge italiana, siano in stato di liquidazione /o di insolvenza anche se non pronunciata giudizialmente;</span></p>
<p><strong>2.3.2</strong><span style="font-weight: 400;"> Oltre quanto gi&agrave; indicato al punto 2.3.1 non potranno aderire al circuito i soggetti intermediari finanziari, nella definizione prevista dalle Norme bancarie (TUB) e finanziarie (TUF). Sardex Spa si riserva il diritto di valutare se accettare o meno l&rsquo;iscrizione senza essere tenuta a a motivare eventuali dinieghi alla richiesta di iscrizione.</span></p>
<p><strong>2.3.3</strong> <span style="font-weight: 400;">Non potranno aderire al circuito e le loro domande saranno pertanto respinte quei soggetti nei confronti dei quali sia pendente dinanzi alle competenti autorit&agrave; giudiziarie una procedura per abuso di posizione dominante sul mercato, per violazione delle norme in materia di concorrenza o per pubblicit&agrave; occulta e non corretta.</span></p>
<p><strong>2.4</strong><span style="font-weight: 400;"> Genere di societ&agrave; ammesse</span></p>
<p><span style="font-weight: 400;">Al Circuito possono aderire solo le piccole e medie imprese, societ&agrave; di capitali, societ&agrave; di persone e, comunque, restando invece escluse le grandi imprese e le imprese di grande distribuzione verticale e orizzontale, avendo queste ultime interessi confliggenti, ovvero interessi non compatibili con quelli delle piccole e medie imprese. La definizione di grande impresa esclusa dal circuito &egrave; rimessa alla discrezione di Sardex, che non sar&agrave; tenuta a motivare eventuali dinieghi alla richiesta di iscrizione.</span></p>
<h3><strong>3. Servizi di Sardex SpA</strong></h3>
<p><strong>3.1</strong><span style="font-weight: 400;"> L&rsquo;adesione al circuito Sardex consente agli iscritti di effettuare acquisti e vendite con gli altri iscritti attraverso scambi multilaterali di beni e servizi, che vengono regolamentati attraverso il sistema di compensazione.</span></p>
<p><strong>3.2</strong><span style="font-weight: 400;"> A tal fine Sardex SpA offre i seguenti servizi:</span></p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">mette a disposizione un conto in compensazione consultabile e fruibile 24 ore su 24 e 7 giorni su 7 presso l&rsquo;indirizzo web www.sardexpay.net;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">mette a disposizione di ogni iscritto la consultazione dei riferimenti e degli spazi virtuali di tutti gli altri iscritti;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">redige l&rsquo;estratto conto di ogni iscritto che contiene la contabilizzazione di debiti e crediti e rende disponibile il documento nell&rsquo;area riservata;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">mette a disposizione di ogni iscritto una Carta Sardex identificativa utile alla contabilizzazione di acquisti e vendite con gli altri iscritti;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">mette a disposizione personale proprio per l&rsquo;assistenza alle operazioni di compravendita degli iscritti all&rsquo;interno del Circuito;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">supervisiona la correttezza delle operazioni complessive all&rsquo;interno del circuito, segnalando le situazioni di debito e credito temporaneamente non aderenti al regolamento del circuito stesso, nonch&eacute; le ipotesi di operazioni non consentite;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">ogni eventuale altro servizio utile al buon funzionamento del Circuito.</span></li>
</ul>
<p><strong>3.3</strong><span style="font-weight: 400;"> Sardex SpA favorisce scambi grazie ad una piattaforma informatica, ma, se non entro i limiti di verifica dello stato di esposizione e per evitare transazioni vietate, non interviene nella compravendita n&eacute; in qualit&agrave; di acquirente n&eacute; in qualit&agrave; di venditore. A tal guisa, non si assume la responsabilit&agrave; sulla qualit&agrave; dei beni e dei servizi scambiati e in generale, per qualunque circostanza attinente ai rapporti instauratisi tra gli iscritti, &egrave; da questi ultimi esonerata da qualsivoglia vizio di fabbricazione e dalle conseguenti responsabilit&agrave;.</span></p>
<p><strong>3.4</strong><span style="font-weight: 400;"> Sardex fornir&agrave; i servizi di cui al presente regolamento contrattuale solo ed esclusivamente agli iscritti che: abbiano completato la procedura di richiesta di iscrizione abbiano accettato i relativi termini e condizioni di contratto siano in regola con i pagamenti di tutti i costi relativi ai servizi erogati da Sardex SpA e le commissioni sulle transazioni effettuate.</span></p>
<p><span style="font-weight: 400;">Inoltre gli iscritti dovranno all&rsquo;atto dell&rsquo;iscrizione accettare espressamente di corrispondere a Sardex SpA le competenze e le commissioni relative alle transazioni eseguite tramite l&rsquo;utilizzo dei seguenti mezzi di pagamento:</span></p>
<ol>
<li style="font-weight: 400;"><span style="font-weight: 400;">Carta di credito;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Sepa direct debit / Credit Transfer B2B;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Sardex SpA si riserva di indicare nuove modalit&agrave; di pagamento.</span></li>
</ol>

<h3><strong>4. Corrispettivi</strong></h3>
<p><strong>4.1</strong><span style="font-weight: 400;"> L&rsquo;iscritto, nella misura definita nel piano tariffario accordato in fase di iscrizione, corrisponder&agrave; a Sardex SpA:</span></p>
<p><strong>4.1.1</strong><span style="font-weight: 400;"> Una somma a titolo di canone annuale di adesione al circuito;</span></p>
<p><strong>4.1.2</strong><span style="font-weight: 400;"> Solo ed esclusivamente al raggiungimento di obiettivi prestabiliti, intesi come superamento della disponibilit&agrave; commerciale indicata in contratto, una somma aggiuntiva, calcolata percentualmente, secondo quanto indicato nel piano tariffario, sul canone annuale.</span></p>
<p><strong>4.2</strong><span style="font-weight: 400;"> Qualora tali corrispettivi non venissero versati nei tempi dovuti, le penali sono calcolate nell&rsquo;uno per cento delle somme dovute per ogni mese di ritardo, fatto salvo il diritto al risarcimento dell&rsquo;eventuale maggiore danno, delle eventuali spese legali e della refusione delle spese e fermo restando il diritto di Sardex di risolvere per giusta causa il rapporto associativo, con espulsione dell&rsquo;iscritto inadempiente.</span></p>
<p><strong>4.3</strong><span style="font-weight: 400;"> Sardex SpA si riserva il diritto di esercitare delle penali, sui saldi attivi di iscritti inattivi per un periodo di oltre 120 giorni, pari all&rsquo;uno per cento dell&rsquo;attivo contabilizzato.</span></p>
<p><strong>4.4 </strong><span style="font-weight: 400;">Sardex SpA si riserva la facolt&agrave; di chiedere all&rsquo;iscritto il pagamento delle somme dovute a titolo di canone annuale (Art. 4.1.1) o delle somme aggiuntive al raggiungimento di obiettivi prestabiliti (Art. 4.1.2) in euro o in Crediti Sardex, dando comunicazione all&rsquo;iscritto della propria scelta sette giorni prima della scadenza del pagamento. Qualora le somme dovute dall&rsquo;iscritto in forza del contratto sottoscritto vengano chieste da Sardex SpA in Crediti Sardex, l&rsquo;iscritto autorizza sin d&rsquo;ora e senza necessit&agrave; di ulteriore comunicazione con l&rsquo;invio della fattura dovuta, la Sardex SpA ad effettuare la transazione, operando direttamente sul proprio conto.</span></p>
<h3><strong>5. Diritti e doveri degli iscritti a SardexPay</strong></h3>
<h3><strong>5.1 Generalit&agrave; degli iscritti</strong></h3>
<p><span style="font-weight: 400;">I nomi degli iscritti e le relative percentuali di accettazione sono pubblicati gratuitamente sul sito www.sardexpay.net nella sezione riservata.</span></p>
<h3><strong>5.2 Inserzioni di iscritti</strong></h3>
<p><strong>5.2.1</strong><span style="font-weight: 400;"> Gli iscritti ufficiali hanno il diritto di pubblicizzare le proprie offerte e richieste sul portale sardexpay.net.</span></p>
<p><strong>5.2.2</strong><span style="font-weight: 400;"> Se la quota di accettazione in Crediti Sardex offerta nelle inserzioni pubblicitarie interne al Circuito supera quella indicata negli elenchi degli iscritti, l&rsquo;autore dell&rsquo;inserzione &egrave; obbligato a rispettarla.</span></p>
<h3><strong><em>5.3 </em></strong><strong>Mailing SardexPay</strong></h3>
<p><strong>5.3.1</strong><span style="font-weight: 400;"> Gli iscritti ufficiali possono chiedere al proprio Servizio Clienti l&rsquo;invio di loro offerte pubblicitarie a una parte o a tutti gli iscritti al Circuito. Per queste comunicazioni esistono direttive separate e complementari alle presenti condizioni generali. Il Circuito pu&ograve; rifiutare senza addurre motivazioni gli invii delle promozioni se ritiene di non poter far fronte alla richiesta.</span></p>
<h3><strong>5.4 Disponibilit&agrave; annuale di beni e servizi da parte degli iscritti</strong></h3>
<p><span style="font-weight: 400;">Ogni iscritto &egrave; tenuto a comunicare e rispettare tassativamente la disponibilit&agrave; di beni e servizi accordata nei confronti degli altri iscritti all&rsquo;atto di sottoscrizione del contratto e ogni modifica di tale disponibilit&agrave; annuale dovr&agrave; essere comunicata per iscritto a Sardex SpA e sottoposta ad accettazione o rifiuto a suo insindacabile giudizio. Il mantenimento della disponibilit&agrave; dei beni e servizi offerti costituisce condizione essenziale per la stabilit&agrave;, l&rsquo;equilibrio e il corretto funzionamento del circuito. La violazione di quest&rsquo;obbligo potr&agrave; consentire a Sardex di risolvere il rapporto associativo di diritto ex art. 1456 c.c.</span></p>
<h3><strong><em>5.5</em></strong><strong> Regolamento sulla quota di accettazione di Crediti Sardex</strong></h3>
<p><span style="font-weight: 400;">Gli acquisti fino ad un valore di &euro; 1000,00(mille) effettuati all&rsquo;interno del Circuito vengono pagati al 100% in Crediti Sardex. Per importi superiori, i primi &euro; 1000,00 vengono pagati in Crediti Sardex, mentre per la somma eccedente &egrave; possibile concordare un pagamento misto (EUR+SRD) partendo dalla percentuale minima indicata dal venditore e pubblicata nella sezione riservata del sito www.sardexpay.net</span></p>
<h3><strong><em>5.6</em></strong><strong> Parit&agrave; tra clienti Sardex e clienti che pagano in valuta ufficiale</strong></h3>
<p><span style="font-weight: 400;">Gli iscritti s&rsquo;impegnano a fatturare ai clienti Sardex agli stessi prezzi dei clienti che pagano in valuta ufficiale, in particolare a concedere loro gli stessi sconti o ribassi eventualmente in corso.</span></p>
<h3><strong><em>5.7</em></strong><strong> Prodotti e prestazioni</strong></h3>
<p><span style="font-weight: 400;">L&rsquo;iscritto mette a disposizione, anche dei consumatori finali aderenti al circuito, beni e servizi prodotti a regola d&rsquo;arte e, ove previsto, dotati delle certificazioni di processo, di prodotto o ambientali, con particolare riguardo alla vigente normativa sull&rsquo;infortunistica, la sicurezza sul lavoro e la tossicit&agrave; dei materiali. Nel caso siano previste, l&rsquo;iscritto mette a disposizione la documentazione necessaria ad identificare la regolarit&agrave; delle lavorazioni e delle prestazioni.</span></p>
<h3><strong><em>5.8</em></strong><strong> Obbligo di permettere l&rsquo;accesso a tutte le offerte anche ai clienti Sardex</strong></h3>
<p><span style="font-weight: 400;">Ogni iscritto s&rsquo;impegna nei confronti della clientela Sardex con tutto il suo assortimento, tutte le sue prestazioni e tutti i suoi servizi conformemente agli obblighi assunti riguardo alla quota in Crediti Sardex convenuta e pubblicata ed alla disponibilit&agrave; annuale accordata.</span></p>
<h3><strong><em>5.9</em></strong><strong> Accettazione di Crediti Sardex solo da parte di iscritti al Circuito</strong></h3>
<p><span style="font-weight: 400;">Ogni iscritto al Circuito Sardex s&rsquo;impegna ad accettare pagamenti in Crediti Sardex solo da altri iscritti titolari di un Conto Sardex.</span></p>
<h3><strong><em>5.10</em></strong><strong> Obbligo di notifica dell&rsquo;iscritto</strong></h3>
<p><span style="font-weight: 400;">Ogni iscritto s&rsquo;impegna a comunicare al pi&ugrave; presto per iscritto qualsiasi cambiamento dei dati rilasciati nella domanda di iscrizione al Circuito.</span></p>
<h3><strong>6. Assunzione di responsabilit&agrave;</strong></h3>
<h3><strong><em>6.1</em></strong><strong> Indennizzo</strong></h3>
<p><span style="font-weight: 400;">L&rsquo;utente si impegna a manlevare e tenere indenne Sardex SpA da ogni perdita, responsabilit&agrave;, azioni, danni, spese, costi, penali, multe e le tasse (comprese le spese legali e costi) sostenuti da Sardex a causa di qualsiasi doglianza o rivendicazioni di terzi, richieste o procedimenti legali derivanti da:</span></p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">azioni, omissioni o comportamenti in violazione del presente contratto, o la violazione di qualsiasi legge o dei diritti di terzi;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">qualsiasi violazione delle dichiarazioni e garanzie contenute nella domanda di iscrizione o del presente accordo;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">la realizzazione di acquisti e/o vendite vietate, realizzate attraverso la piattaforma;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">l&rsquo;indebito utilizzo delle informazioni accessibili su o attraverso il sito web;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">qualsiasi violazione di marchi, diritti d&rsquo;autore o altri diritti di propriet&agrave; intellettuale di terzi;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">violazione per il mancato pagamento delle imposte e tasse dovute anche in relazione alle operazioni di scambio nell&rsquo;ambito del circuito. Gli iscritti prendono atto che l&rsquo;obbligo fiscale in tal senso &egrave; sempre a loro carico.</span></li>
</ul>
<p><strong>6.2</strong><span style="font-weight: 400;"> L&rsquo;iscritto d&agrave; atto che Sardex svolge mera attivit&agrave; di gestione del circuito e del suo regolare svolgimento e che &egrave; terzo rispetto alle transazioni e agli scambi tra iscritti. Pertanto l&rsquo;iscritto prende atto e concorda sul fatto che Sardex non garantisce:</span></p>
<p><span style="font-weight: 400;">la qualit&agrave;, quantit&agrave;, sicurezza, liceit&agrave;, commerciabilit&agrave;, o altre caratteristiche dei prodotti o servizi inseriti o acquistati attraverso il circuito;</span></p>
<p><span style="font-weight: 400;">l&rsquo;accuratezza, affidabilit&agrave;, integrit&agrave;, o la legalit&agrave; di qualsiasi offerta, proposta, dichiarazione, dati, parere o altri contenuti degli iscritti attraverso il circuito.</span></p>
<p><strong>6.3</strong><span style="font-weight: 400;"> Nel caso in cui vi sia una controversia con uno o altri iscritti e/o con soggetti terzi, l&rsquo;iscritto si impegna a manlevare e tenere indenne Sardex SpA da qualsiasi richiesta di risarcimento danni di qualsiasi tipo e natura, comprese le spese legali, connessi o derivanti da tale controversia.</span></p>
<h3><strong>7 Unit&agrave; di conto e crediti commerciali</strong></h3>
<p><strong>7.1</strong><span style="font-weight: 400;"> Il valore dei beni e servizi proposti sar&agrave; indicato in unit&agrave; di conto denominate Sardex, il cui valore nominale &egrave; idealmente di una unit&agrave; di moneta corrente nei paesi UE (a scopo esemplificativo 1 Sardex = 1 Euro).</span></p>
<p><strong>7.2</strong><span style="font-weight: 400;"> L&rsquo;unit&agrave; di conto rappresenta un credito commerciale equiparabile al valore in moneta corrente esclusivamente per agevolazione della determinazione del valore dello scambio.</span></p>
<p><strong>7.3</strong><span style="font-weight: 400;"> Sardex non potr&agrave; quindi emettere n&eacute; titoli di credito n&eacute; determinare tassi di interesse sull&rsquo;estratto conto del Fruitore. Il credito presente nel conto non potr&agrave; in nessun caso essere trasformato in moneta corrente n&eacute; in titoli di credito.&nbsp;</span></p>
<p><strong>7.4</strong><span style="font-weight: 400;"> Sardex potr&agrave; regolare in Crediti Sardex anche le attivit&agrave; connesse al funzionamento del circuito, ferma restando la possibilit&agrave; di richiedere agli iscritti pagamenti in Euro al fine di pareggiare eventuali conti passivi.</span></p>
<h3><strong>8. Estratto Conto e contabilizzazione</strong></h3>
<p><strong>8.1</strong><span style="font-weight: 400;"> La contabilizzazione progressiva di acquisti e vendite &egrave; registrata nell&rsquo;estratto conto, disponibile nell&rsquo;area riservata della piattaforma www.sardexpay.net, interrogabile attraverso nome utente e password ovvero id e password. Sar&agrave; pertanto onere dell&rsquo;iscritto verificare gli aggiornamenti del proprio estratto conto con frequenza settimanale. L&rsquo;iscrizione di una posta si intende accettata dall&rsquo;iscritto qualora quest&rsquo;ultimo, entro il termine di giorni quindici dalla data dell&rsquo;aggiornamento che si intende contestare, non provveda a sottoporre a Sardex SpA un reclamo in merito.</span></p>
<p><em><span style="font-weight: 400;">8.2</span></em><span style="font-weight: 400;"> L&rsquo;estratto conto dell&rsquo;iscritto sar&agrave; aggiornato solo successivamente al completamento di una transazione. Nel caso di insorgenza di controversie circa la transazione, la contabilizzazione viene sospesa fino a composizione della controversia stessa.</span></p>
<h3><strong>9. Compensazione</strong></h3>
<p><strong>9.1</strong><span style="font-weight: 400;"> Qualora l&rsquo;estratto conto dell&rsquo;iscritto evidenzi un saldo contabile negativo, l&rsquo;iscritto sar&agrave; tenuto ad eseguire, a favore degli iscritti che ne facciano richiesta, una o pi&ugrave; vendite in compensazione fino al pareggio dell&rsquo;estratto conto, al 100% in Crediti Sardex.</span></p>
<p><strong>9.2</strong><span style="font-weight: 400;"> Qualora l&rsquo;iscritto si rifiuti di eseguire una prestazione in compensazione o non possa farlo per effetto di risoluzione e/o recesso, egli sar&agrave; tenuto, a prima richiesta, a versare somme in danaro equivalenti a Sardex SpA. Ricevute tali somme, Sardex provveder&agrave; ad aggiornare il saldo contabile dell&rsquo;iscritto e a produrre la liberatoria.</span></p>
<p><strong>9.3</strong><span style="font-weight: 400;"> Qualora nei casi previsti dall&rsquo;articolo 9.1 un iscritto non compensi un debito per acquisto entro dodici mesi dalla conclusione della transazione, questi sar&agrave; tenuto a versare in danaro una somma equivalente al debito al fine di pareggiare il debito stesso. Ricevute tali somme, Sardex SpA provveder&agrave; ad aggiornare il saldo contabile dell&rsquo;iscritto. In difetto si verificher&agrave; una delle ipotesi di risoluzione di cui infra.</span></p>
<p><strong><em>9.4 </em></strong><span style="font-weight: 400;">Con le somme ricevute, nei casi di cui ai precedenti punti 9.2 e 9.3, Sardex SpA provveder&agrave; ad acquisire ed immettere nel circuito beni e servizi per un valore equivalente e comunque nel limite delle somme ricevute, al fine di soddisfare le richieste degli iscritti con saldo attivo e/o per pareggiare le posizioni negative da pi&ugrave; di 12 mesi di iscritti con il conto in passivo.</span></p>
<p><strong>9.5</strong><span style="font-weight: 400;"> E&rsquo; fatto divieto di intraprendere compravendite aventi ad oggetto Crediti Sardex. La violazione accertata comporter&agrave; la risoluzione del contratto, fatte salve le conseguenze civili e penali eventualmente accertate dalla competente autorit&agrave; giudiziaria.</span></p>
<h3><strong>10. Limite di spesa</strong></h3>
<p><strong>10.1</strong><span style="font-weight: 400;"> Al fine di consentire acquisti prima di aver effettuato vendite, Sardex SpA pu&ograve; concedere all&rsquo;iscritto un limite di spesa, che continuer&agrave; a comparire nell&rsquo;estratto conto sommando ad esso i crediti commerciali maturati e sottraendovi i debiti.</span></p>
<p><strong>10.2</strong><span style="font-weight: 400;"> La modifica o la revoca del limite di spesa &egrave; ad insindacabile giudizio di Sardex SpA, qualora cambino o vengano meno i requisiti che l&rsquo;iscritto aveva al momento della concessione del limite di spesa.</span></p>
<p><strong>10.3</strong><span style="font-weight: 400;"> Qualora Sardex SpA assegni ad un nuovo iscritto un limite di spesa pari a zero, quest&rsquo;ultimo potr&agrave; provvedere ad un acquisto solo dopo aver maturato crediti.</span></p>
<h3><strong>11. Liste di offerta e richiesta</strong></h3>
<p><span style="font-weight: 400;">Per garantire il buon funzionamento del Circuito l&rsquo;iscritto sar&agrave; tenuto a comunicare all&rsquo;atto della stipula del contratto, e successivamente durante l&rsquo;operativit&agrave;, con cadenza regolare, la propria offerta di prodotti e servizi nonch&eacute; la propria richiesta di beni e servizi, fornendo indicazioni specifiche ed esaustive.</span></p>
<h3><strong>12. Durata del contratto</strong></h3>
<p><span style="font-weight: 400;">Il contratto ha durata di dodici mesi e si rinnova tacitamente. La disdetta va inviata con preavviso di tre mesi rispetto alla scadenza con raccomandata AR a Sardex SpA o tramite PEC.</span></p>
<h3><strong>13. Risoluzione anticipata del contratto</strong></h3>
<p><strong>13.1</strong><span style="font-weight: 400;"> Sardex SpA ha facolt&agrave; di risolvere anticipatamente il contratto senza preavviso o preventiva costituzione in mora dell&rsquo;iscritto, oltre che nell&rsquo;ipotesi di cui ai punti 5.5, 9.3 e 9.5, nei casi che seguono:</span></p>
<p><strong><em>13.2</em></strong><span style="font-weight: 400;"> sia stata richiesta una procedura concorsuale a carico dell&rsquo;iscritto;</span></p>
<p><strong>13.3</strong><span style="font-weight: 400;"> l&rsquo;iscritto abbia posto in liquidazione l&rsquo;impresa o abbia cessato la sua attivit&agrave;;</span></p>
<p><strong>13.4</strong><span style="font-weight: 400;"> l&rsquo;iscritto sia incorso nella violazione dei casi previsti dai precedenti articoli 4 e 5;</span></p>
<p><strong>13.5</strong><span style="font-weight: 400;"> siano venuti meno i requisiti di accettazione della richiesta di iscrizione, a insindacabile giudizio di Sardex SpA;&nbsp;</span></p>
<p><strong>13.6</strong><span style="font-weight: 400;"> qualora la modifica dell&rsquo;attivit&agrave; originariamente ammessa nel circuito faccia venir meno i requisiti di adesione al circuito.</span></p>
<p><span style="font-weight: 400;">In tal caso Sardex SpA provveder&agrave; alla risoluzione con preavviso inviato a mezzo raccomandata AR o Posta Elettronica Certificata.</span></p>
<h3><strong>14. Effetti della risoluzione o dell&rsquo;estinzione</strong></h3>
<p><strong>14.1</strong><span style="font-weight: 400;"> Qualora per risoluzione o recesso il contratto si estingua con una posizione debitoria da parte dell&rsquo;iscritto, le modalit&agrave; di estinzione del debito sono le stesse riportate nel precedente articolo 9.</span></p>
<p><strong>14.2</strong><span style="font-weight: 400;"> Qualora Sardex SpA provveda alla risoluzione anticipata nei casi previsti dal precedente articolo 13, con esclusione del punto 13.5, l&rsquo;eventuale posizione debitoria dovr&agrave; essere sanata direttamente a Sardex SpA.</span></p>
<p><strong>14.3 </strong><span style="font-weight: 400;">Qualora al momento della cessazione prevista nei precedenti punti 14.1 e 14.2 emerga una posizione creditoria, tale credito potr&agrave; essere compensato esclusivamente in beni e servizi ed in nessun caso in moneta corrente entro tre mesi decorrenti&nbsp; dalla data di scadenza contrattuale. Oltre questa data il credito residuo non sar&agrave; pi&ugrave; a disposizione dell&rsquo;iscritto ma sar&agrave; utilizzato da Sardex per il buon funzionamento del circuito.</span></p>
<h3><strong>15. Modifiche del contratto</strong></h3>
<p><span style="font-weight: 400;">Sardex SpA ha il diritto di modificare i contenuti delle presenti condizioni generali di contratto e l&rsquo;offerta di servizi di cui al precedente articolo 3. Di tali modifiche dovr&agrave; essere data comunicazione all&rsquo;iscritto. Quest&rsquo;ultimo accetta tacitamente se entro 15 giorni dal ricevimento della comunicazione non provvede a comunicare il proprio recesso a mezzo raccomandata AR o tramite PEC. Tutte le modifiche alle condizioni di contratto dovranno essere provate per iscritto.</span></p>
<h3><strong>16. Privacy</strong></h3>
<p><span style="font-weight: 400;">I dati personali derivanti dalla esecuzione del presente contratto saranno trattati per le finalit&agrave; e con le modalit&agrave; descritte dettagliatamente nella allegata Informativa ex art. 13 GDPR alla quale si rimanda integralmente.&nbsp;</span></p>
<h3><strong>17. Domicilio</strong></h3>
<p><span style="font-weight: 400;">L&rsquo;iscritto elegge domicilio ai fini del presente contratto presso il domicilio dichiarato in sede di richiesta di iscrizione. La modifica dovr&agrave; essere comunicata a mezzo raccomandata AR. L&rsquo;iscritto &egrave; tenuto a rendersi reperibile da parte dello staff di Sardex SpA e da parte degli altri iscritti, ed a fornire, in sua assenza, almeno un referente aziendale correttamente informato sul funzionamento del Circuito.</span></p>
<h3><strong>18. Foro Competente</strong></h3>
<p><strong>18.1</strong><span style="font-weight: 400;"> Qualunque controversia che insorgesse nell&rsquo;interpretazione e nell&rsquo;applicazione del presente contratto fra l&rsquo;iscritto e Sardex SpA dovr&agrave; essere composta presso il Foro di Cagliari. Qualunque altro foro &egrave; escluso.</span></p>
<p><strong>18.2</strong><span style="font-weight: 400;"> Le eventuali controversie fra iscritti al circuito per transazioni concluse all&rsquo;interno del circuito Sardex potranno essere demandate ad un collegio arbitrale composto da tre membri, due di nomina delle parti ed un terzo con funzioni di presidente nominato per accordo dei due arbitri o, in caso di mancato accordo, dal presidente del consiglio di amministrazione di Sardex SpA o ancora, nel caso di rigetto di quest&rsquo;ultimo da parte di uno dei due arbitri, dal presidente del tribunale di Cagliari.</span></p>
<p><strong>18.3 </strong><span style="font-weight: 400;">La nomina degli arbitri, il contenuto dell&rsquo;istanza e il lodo andranno notificati a Sardex SpA.</span></p>
<p><em><span style="font-weight: 400;">18.4</span></em><span style="font-weight: 400;"> Il procedimento, condotto in via rituale e fatto salvo il principio del contraddittorio, si svolger&agrave; con decisioni assunte secondo diritto. Il Lodo sar&agrave; inappellabile</span></p>
<p><strong>18.5</strong><span style="font-weight: 400;"> Il collegio avr&agrave; competenza anche per la determinazione delle spese di lodo secondo il principio di soccombenza.&nbsp;</span></p>
<p><strong>18.6 </strong><span style="font-weight: 400;">L&rsquo;arbitrato avr&agrave; sede a Cagliari.</span></p>
<p><strong>18.7</strong><span style="font-weight: 400;"> La previsione della possibilit&agrave; di devolvere le controversie tra i soli iscritti a un Collegio arbitrale deve ritenersi facoltativa e non preclude il ricorso all&rsquo;autorit&agrave; giudiziaria ordinaria, restando competente in via esclusiva il foro di Cagliari.</span></p>

    `,
  },
};

export { DialogData, dialogsData };
