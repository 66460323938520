import { Injectable } from '@angular/core';

import { AbstractControl, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class ContractDataValidators {
  constructor() {}

  autocompleteObjectValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (typeof control.value === 'string') {
        return { invalidAutocompleteObject: { value: control.value } };
      }
      return null;
    };
  }

  dateValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const date = moment(control.value);
      return date.isValid() ? null : { pattern: true };
    };
  }

  futureDateValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const date = moment(control.value);
      if (date.isValid()) {
        const today = moment();

        return date <= today ? null : { futureDate: true };
      }
    };
  }

  majorAgeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const date = moment(control.value);
      if (date.isValid()) {
        const today = moment();
        const age = today.diff(date, 'years', true);
        return age > 18 ? null : { majorAge: true };
      }
    };
  }
}
