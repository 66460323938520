/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AccountManagementRequest } from '../models/account-management-request';
import { AccountManagementResponse } from '../models/account-management-response';
import { Activate } from '../models/activate';
import { ActivateRequest } from '../models/activate-request';
import { ContractData } from '../models/contract-data';
import { ContractDataRequest } from '../models/contract-data-request';
import { ContractDataResponse } from '../models/contract-data-response';
import { FactoringResponse } from '../models/factoring-response';
import { PaymentDetailsRequest } from '../models/payment-details-request';
import { PaymentDetailsResponse } from '../models/payment-details-response';
import { SignatureRequest } from '../models/signature-request';
import { SignatureResponse } from '../models/signature-response';


/**
 * Activate plan workflow
 */
@Injectable({
  providedIn: 'root',
})
export class ActivatePlanService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation accountManagement
   */
  static readonly AccountManagementPath = '/activate/{plan_id}/accountManagement';

  /**
   * Account Management Form
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountManagement()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountManagement$Response(params: {

    /**
     * Plan Id to identify the plan
     */
    planId: string;
  
    /**
     * Activation application form
     */
    body: AccountManagementRequest
  }): Observable<StrictHttpResponse<AccountManagementResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivatePlanService.AccountManagementPath, 'post');
    if (params) {

      rb.path('plan_id', params.planId);

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountManagementResponse>;
      })
    );
  }

  /**
   * Account Management Form
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `accountManagement$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountManagement(params: {

    /**
     * Plan Id to identify the plan
     */
    planId: string;
  
    /**
     * Activation application form
     */
    body: AccountManagementRequest
  }): Observable<AccountManagementResponse> {

    return this.accountManagement$Response(params).pipe(
      map((r: StrictHttpResponse<AccountManagementResponse>) => r.body as AccountManagementResponse)
    );
  }

  /**
   * Path part for operation activationApplication
   */
  static readonly ActivationApplicationPath = '/activate/{plan_id}';

  /**
   * Ask for an activation application
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activationApplication()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activationApplication$Response(params: {

    /**
     * Plan Id to identify the plan
     */
    planId: string;
  
    /**
     * Activation application form
     */
    body: ActivateRequest
  }): Observable<StrictHttpResponse<{ 'Activate'?: Activate, 'ContractData'?: ContractData, 'isProspect'?: boolean }>> {

    const rb = new RequestBuilder(this.rootUrl, ActivatePlanService.ActivationApplicationPath, 'post');
    if (params) {

      rb.path('plan_id', params.planId);

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'Activate'?: Activate, 'ContractData'?: ContractData, 'isProspect'?: boolean }>;
      })
    );
  }

  /**
   * Ask for an activation application
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activationApplication$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activationApplication(params: {

    /**
     * Plan Id to identify the plan
     */
    planId: string;
  
    /**
     * Activation application form
     */
    body: ActivateRequest
  }): Observable<{ 'Activate'?: Activate, 'ContractData'?: ContractData, 'isProspect'?: boolean }> {

    return this.activationApplication$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'Activate'?: Activate, 'ContractData'?: ContractData, 'isProspect'?: boolean }>) => r.body as { 'Activate'?: Activate, 'ContractData'?: ContractData, 'isProspect'?: boolean })
    );
  }

  /**
   * Path part for operation factoring
   */
  static readonly FactoringPath = '/activate/{plan_id}/factoring';

  /**
   * Factoring
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `factoring()` instead.
   *
   * This method doesn't expect any request body.
   */
  factoring$Response(params: {

    /**
     * Plan Id to identify the plan
     */
    planId: string;

  }): Observable<StrictHttpResponse<FactoringResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivatePlanService.FactoringPath, 'get');
    if (params) {

      rb.path('plan_id', params.planId);

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FactoringResponse>;
      })
    );
  }

  /**
   * Factoring
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `factoring$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  factoring(params: {

    /**
     * Plan Id to identify the plan
     */
    planId: string;

  }): Observable<FactoringResponse> {

    return this.factoring$Response(params).pipe(
      map((r: StrictHttpResponse<FactoringResponse>) => r.body as FactoringResponse)
    );
  }

  /**
   * Path part for operation contractData
   */
  static readonly ContractDataPath = '/activate/{plan_id}/contractData';

  /**
   * Contract Data Form
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contractData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contractData$Response(params: {

    /**
     * Plan Id to identify the plan
     */
    planId: string;
  
    /**
     * Activation application form
     */
    body: ContractDataRequest
  }): Observable<StrictHttpResponse<ContractDataResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivatePlanService.ContractDataPath, 'post');
    if (params) {

      rb.path('plan_id', params.planId);

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ContractDataResponse>;
      })
    );
  }

  /**
   * Contract Data Form
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `contractData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contractData(params: {

    /**
     * Plan Id to identify the plan
     */
    planId: string;
  
    /**
     * Activation application form
     */
    body: ContractDataRequest
  }): Observable<ContractDataResponse> {

    return this.contractData$Response(params).pipe(
      map((r: StrictHttpResponse<ContractDataResponse>) => r.body as ContractDataResponse)
    );
  }

  /**
   * Path part for operation paymentDetails
   */
  static readonly PaymentDetailsPath = '/activate/{plan_id}/paymentDetails';

  /**
   * Validate Payment Details
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentDetails$Response(params: {

    /**
     * Plan Id to identify the plan
     */
    planId: string;
  
    /**
     * Activation application form
     */
    body: PaymentDetailsRequest
  }): Observable<StrictHttpResponse<PaymentDetailsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivatePlanService.PaymentDetailsPath, 'post');
    if (params) {

      rb.path('plan_id', params.planId);

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaymentDetailsResponse>;
      })
    );
  }

  /**
   * Validate Payment Details
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `paymentDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentDetails(params: {

    /**
     * Plan Id to identify the plan
     */
    planId: string;
  
    /**
     * Activation application form
     */
    body: PaymentDetailsRequest
  }): Observable<PaymentDetailsResponse> {

    return this.paymentDetails$Response(params).pipe(
      map((r: StrictHttpResponse<PaymentDetailsResponse>) => r.body as PaymentDetailsResponse)
    );
  }

  /**
   * Path part for operation initializeSignature
   */
  static readonly InitializeSignaturePath = '/activate/{plan_id}/signature';

  /**
   * Initializes a Signature procedure via Open Api.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `initializeSignature()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  initializeSignature$Response(params: {

    /**
     * Plan Id to identify the plan
     */
    planId: string;
  
    /**
     * Activation application form
     */
    body: SignatureRequest
  }): Observable<StrictHttpResponse<SignatureResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActivatePlanService.InitializeSignaturePath, 'post');
    if (params) {

      rb.path('plan_id', params.planId);

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SignatureResponse>;
      })
    );
  }

  /**
   * Initializes a Signature procedure via Open Api.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `initializeSignature$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  initializeSignature(params: {

    /**
     * Plan Id to identify the plan
     */
    planId: string;
  
    /**
     * Activation application form
     */
    body: SignatureRequest
  }): Observable<SignatureResponse> {

    return this.initializeSignature$Response(params).pipe(
      map((r: StrictHttpResponse<SignatureResponse>) => r.body as SignatureResponse)
    );
  }

}
