import { Component } from '@angular/core';
import { LoadingService } from 'src/app/services/loading.service';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-nocheckout-thank-you',
  templateUrl: './nocheckout-thank-you.component.html',
  styleUrls: ['./nocheckout-thank-you.component.scss'],
})
export class NocheckoutThankYouComponent {
  public pec: string;

  constructor(
    private sessionService: SessionService,
    private loadingService: LoadingService,
  ) {}

  ngOnInit(): void {
    const contractData = this.sessionService.getContractFormData();
    this.pec = contractData.pec;
    this.loadingService.stopSpinner();
  }
}
