import { Component, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { EventEmitter } from '@angular/core';

import * as moment from 'moment';
import { Moment } from 'moment';

@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
})
export class DateInputComponent {
  @Input() label: string;
  @Input() group: UntypedFormGroup;
  @Input() controlName: string;
  @Input() placeholder?: string = '';
  @Input() controlObject: UntypedFormControl;
  @Input() isRequired?: boolean;

  @Output() dateHasChanged = new EventEmitter();

  today: Date;
  todayLocale: string;

  private previousValue: string;

  constructor() {
    this.today = new Date();
    this.todayLocale = moment().format('DD/MM/YYYY').toString();
  }

  ngOnInit(): void {
    this.previousValue = this.controlObject.value;
  }

  isDateChanged(actualDate: Moment): void {
    let previousDate: Moment = moment.utc(this.previousValue, 'DD/MM/YYYY');

    //NOTE: avoid to show consistency warning if date input is still empty
    if (this.previousValue === '') {
      previousDate = actualDate;
      this.previousValue = moment.utc(previousDate).format('DD/MM/YYYY').toString();
    }

    this.dateHasChanged.emit(actualDate.diff(previousDate, 'minutes') !== 0);
  }
}
