import { Component, Inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-phone-number-dialog',
  templateUrl: './phone-number-dialog.component.html',
  styleUrls: [],
})
export class SignaturePhoneNumberDialog {
  constructor(
    public dialogRef: MatDialogRef<SignaturePhoneNumberDialog>,
    @Inject(MAT_DIALOG_DATA) public signatureFormGroup: UntypedFormGroup,
  ) {}

  save(): void {
    this.dialogRef.close(this.signatureFormGroup.controls.signatureMobilePhone.value);
  }
}
