import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  MatAutocompleteSelectedEvent,
  MatAutocomplete,
} from '@angular/material/autocomplete';
import { startWith, map, take } from 'rxjs/operators';
import { MatChipInputEvent } from '@angular/material/chips';
import { CategoriesService } from 'src/lib/services';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-tags-input',
  templateUrl: './tags-input.component.html',
  styleUrls: ['./tags-input.component.scss'],
})
export class TagsInputComponent implements OnInit, OnChanges {
  @Input() supplyChainPosition: string;
  @Input() productCategory: string;
  @Input() businessType: string;
  @Input() label: string;
  @Input() hint?: string;
  @Input() placelholder?: string;
  @Input() group: UntypedFormGroup;
  @Input() controlName: string;
  @Input() placeholder?: string = '';
  @Input() controlObject: UntypedFormControl;
  @Input() disabled: boolean;

  @ViewChild('tagsInput') tagsInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  isLoading: boolean;
  visible: boolean = true;
  selectable: boolean = false;
  removable: boolean = true;
  chipsDisabled: boolean = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  filteredTags: Observable<string[]>;
  selectedTags: string[] = [];
  allTags: string[] = [];

  constructor(
    private categoriesService: CategoriesService,
    private sessionService: SessionService,
  ) {}

  ngOnInit(): void {
    this.filteredTags = this.controlObject.valueChanges.pipe(
      startWith(null),
      map((tag: string | null) => (tag ? this._filter(tag) : this.allTags.slice())),
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    this.controlObject.disable();

    const { businessType } = changes;

    if (!!businessType) {
      this.selectedTags = [];
      this.allTags = [];

      if (changes.businessType.currentValue === null) {
        this.chipsDisabled = true;
        this.removable = false;

        return;
      }

      this.isLoading = true;

      this.categoriesService
        .attivita({
          body: {
            posizionamento_in_filiera: this.supplyChainPosition,
            settore_merceologico: this.productCategory,
            attivita: this.businessType,
          },
        })
        .pipe(take(1))
        .subscribe((res) => {
          this.removable = true;
          this.allTags = res.tags_aggiuntive;
          this.isLoading = false;

          const { tag } = this.sessionService.getContractFormData();

          if (tag && tag.length) {
            this.allTags = [...res.tags_minime, ...res.tags_aggiuntive];
            this.selectedTags = tag;
            this.selectedTags.map((tagToRemove) => {
              this._removeFromTagsDictionary(tagToRemove);
            });
            this.controlObject.setValue(tag);
          } else {
            this.selectedTags = res.tags_minime;
            this.controlObject.setValue(this.selectedTags);
          }

          if (!changes['disabled']?.currentValue) {
            this.controlObject.enable();
            this.chipsDisabled = false;
          }
        });
    }
  }

  add(event: MatChipInputEvent): void {
    // Cannot add manually, prevent override of the existing value
    this.controlObject.setValue(this.selectedTags);
  }

  resetInputValue(): void {
    this.tagsInput.nativeElement.value = '';
  }

  remove(tag: string): void {
    const index = this.selectedTags.indexOf(tag);

    if (index >= 0) {
      this.selectedTags.splice(index, 1);
      this.allTags.push(tag);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this._removeFromTagsDictionary(event.option.viewValue);
    this.selectedTags.push(event.option.viewValue);
    this.tagsInput.nativeElement.value = '';
    this.controlObject.setValue(this.selectedTags);
  }

  private _removeFromTagsDictionary(tag: string): void {
    const index = this.allTags.indexOf(tag);

    if (index >= 0) {
      this.allTags.splice(index, 1);
    }
  }

  private _filter(value: string): string[] {
    return this.allTags.filter((tag) => {
      const regex = new RegExp(value, 'gi');

      return tag.match(regex);
    });
  }
}
