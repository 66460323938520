import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'signature-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class SignatureFeedbackComponent implements OnInit {
  protected isSuccess!: boolean;
  protected requiredPayment: boolean;

  protected emailAddress: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private sessionService: SessionService,
  ) {
    this.emailAddress = 'sardex@pec.it'; //TODO: add email to contact
    this.requiredPayment = this.sessionService.getRequiredPayment();
  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe((data) => {
      this.isSuccess = data.success;
    });
  }
}
