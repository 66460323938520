import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { CategoriesService } from 'src/lib/services';

@Component({
  selector: 'app-supply-chain-position',
  templateUrl: './supply-chain-position.component.html',
  styleUrls: ['./supply-chain-position.component.scss'],
})
export class SupplyChainPositionComponent implements OnInit {
  @Input() label: string;
  @Input() hint?: string;
  @Input() isRequired?: boolean;
  @Input() group: UntypedFormGroup;
  @Input() controlName: string;
  @Input() placeholder?: string = '';
  @Input() controlObject: UntypedFormControl;
  @Input() disabled: boolean;

  isLoading: boolean;
  supplyChainPositionCategories: string[];
  filteredOptions: Observable<string[]>;

  constructor(private categoriesService: CategoriesService) {}

  ngOnInit() {
    this.isLoading = true;
    this.controlObject.disable();

    this.categoriesService
      .elenca()
      .pipe(take(1))
      .subscribe((res) => {
        this.isLoading = false;
        
        if(!this.disabled) {
          this.controlObject.enable();
        }
        this.supplyChainPositionCategories = res;
      });
  }
}
