import { Routes } from '@angular/router';

const productsSlug = '/products';
export const specialRoutes: Routes = [
  {
    path: 'confeserfidi',
    redirectTo: `${productsSlug}/confeserfidi`,
    pathMatch: 'full',
  },
];
