<mat-form-field [formGroup]="group" appearance="fill">
  <mat-label>{{ label }}</mat-label>
  <mat-select [id]="controlName" [formControlName]="controlName" [required]="isRequired">
    <mat-option
      *ngFor="let cat of productCategoryCategories; let i = index"
      [value]="cat"
      [id]="controlName + '_' + i"
    >
      {{ cat }}
    </mat-option>
  </mat-select>
  <mat-spinner *ngIf="isLoading" diameter="25" color="accent" matSuffix></mat-spinner>
  <mat-hint>{{ hint }}</mat-hint>
  <mat-error>
    <app-input-validation [controlObject]="controlObject"></app-input-validation>
  </mat-error>
</mat-form-field>
