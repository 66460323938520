<h2 mat-dialog-title>Numero di telefono per la convalida del contratto</h2>
<mat-dialog-content>
  <p>
    Utilizzeremo questo numero di telefono per completare la procedura di firma. Inserisci
    un numero di telefono cellulare valido e raggiungibile da te
  </p>
  <app-text-input
    type="phone"
    controlName="signatureMobilePhone"
    label="Numero di telefono cellulare"
    [group]="signatureFormGroup"
    [controlObject]="signatureFormGroup.controls.signatureMobilePhone"
    [isRequired]="true"
  ></app-text-input>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button color="accent" (click)="save()">Cancella</button>

  <button
    mat-button
    color="primary"
    [disabled]="signatureFormGroup.controls.signatureMobilePhone.invalid"
    (click)="save()"
  >
    Continua
  </button>
</mat-dialog-actions>
