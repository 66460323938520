import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { InputTypes } from 'src/app/models/inputTypes.model';

@Component({
  selector: 'app-input-validation',
  templateUrl: './input-validation.component.html',
  styleUrls: ['./input-validation.component.scss'],
})
export class InputValidationComponent implements OnInit {
  @Input() controlObject: UntypedFormControl;
  @Input() inputType: InputTypes;

  inputTypesEnum = InputTypes;

  constructor() {}

  ngOnInit(): void {}
}
