<mat-form-field appearance="fill" [formGroup]="group">
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    [type]="type"
    [id]="controlName"
    [name]="controlName"
    [formControlName]="controlName"
    [required]="isRequired"
    (blur)="onBlur()"
  />
  <mat-icon *ngIf="iconSuffix" matSuffix>{{ iconSuffix }}</mat-icon>
  <mat-spinner
    *ngIf="group.pending && group.get(controlName).pending"
    diameter="25"
    color="accent"
    matSuffix
  ></mat-spinner>
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
  <mat-error>
    <app-input-validation
      [controlObject]="controlObject"
      [inputType]="type"
    ></app-input-validation>
  </mat-error>
</mat-form-field>
