<div class="container">
  <div>
    <h2>
      Abbiamo ricevuto la tua richiesta di attivazione tramite appuntamento.
      <br /><br />Verrai contattato al più presto da un nostro incaricato.
    </h2>

    <a routerLink="/" mat-raised-button color="accent" class="mat-big-button">Chiudi</a>
  </div>
</div>
