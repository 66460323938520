<mat-form-field [formGroup]="group" appearance="fill">
  <mat-label>{{ label }}</mat-label>
  <mat-select
    [id]="controlName"
    [formControlName]="controlName"
    [value]="controlObject.value"
    [required]="isRequired"
  >
    <mat-option disabled>{{ placeholder }}</mat-option>
    <ng-container *ngFor="let optionData of optionsData">
      <mat-option value="{{ optionData }}" *ngIf="optionData !== ''">{{
        optionData
      }}</mat-option>
    </ng-container>
  </mat-select>
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
  <mat-error>
    <app-input-validation [controlObject]="controlObject"></app-input-validation>
  </mat-error>
</mat-form-field>
