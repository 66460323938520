import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { Activate as AtokaActivate } from '../../lib/models/activate';
import { ContractData as AtokaContractData } from '../../lib/models/contract-data';

import { AccountManagementRequest } from '../../lib/models/account-management-request';
import { ActivatePlanService } from '../../lib/services/activate-plan.service';
import { AskAppointmentService } from '../../lib/services/ask-appointment.service';
import { CityService } from '../../lib/services/city.service';
import { ContractDataRequest } from '../../lib/models/contract-data-request';
import { InitializeAgreementService } from '../../lib/services/initialize-agreement.service';
import { Plan as PlanConfig } from '../../lib/models/plan';

import { Activate } from '../models/activate.model';
import { ContractData } from '../models/contract-data.model';
import { City } from 'src/lib/models/city';
import { Payment } from '../models/payment.model';
import { SessionService } from './session.service';
import { PaymentDetailsResponse } from '../../lib/models/payment-details-response';
import { Plan } from 'src/app/models/plan.model';
import { NoCheckoutSubscriptionService } from 'src/lib/services';
import { NocheckoutRequest } from 'src/lib/models';
import { Signature } from '../models/signature.model';
import { SignatureResponse } from 'src/lib/models/signature-response';
import { FactoringResponse } from 'src/lib/models/factoring-response';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private readonly defaultAgreementSlug = 'sardex';
  constructor(
    private initializeAgreementService: InitializeAgreementService,
    private activatePlanService: ActivatePlanService,
    private askAppointmentService: AskAppointmentService,
    private cityService: CityService,
    private sessionService: SessionService,
    private noCheckoutSubscriptionService: NoCheckoutSubscriptionService,
  ) {}

  getPlanConfig(
    agreementSlug,
    linkId,
  ): Observable<{
    plans?: PlanConfig;
    paymentRequired?: boolean;
    isProspect?: boolean;
    token?: string;
  }> {
    return this.initializeAgreementService.initialize({
      agreementSlug: agreementSlug || this.defaultAgreementSlug,
      linkId: linkId || '',
    });
  }

  onlineActivation(
    data: Activate,
  ): Observable<{ Activate?: AtokaActivate; ContractData?: AtokaContractData }> {
    const plan: Plan = this.sessionService.getPlan();

    return this.activatePlanService.activationApplication({
      planId: plan.id,
      body: data,
    });
  }

  offlineActivation(data: Activate): Observable<void> {
    const plan: Plan = this.sessionService.getPlan();

    return this.askAppointmentService.askAppointment({
      planId: plan.id,
      body: data,
    });
  }

  submitContractDataForm(data: ContractDataRequest): Observable<ContractData> {
    const plan: Plan = this.sessionService.getPlan();

    return this.activatePlanService.contractData({
      planId: plan.id,
      body: data,
    });
  }

  submitAccountManagementForm(
    data: AccountManagementRequest,
  ): Observable<AccountManagementRequest> {
    const plan: Plan = this.sessionService.getPlan();

    return this.activatePlanService.accountManagement({ planId: plan.id, body: data });
  }

  submitSignatureForm(data: Signature): Observable<SignatureResponse> {
    const plan: Plan = this.sessionService.getPlan();

    return this.activatePlanService.initializeSignature({ planId: plan.id, body: data });
  }

  submitPaymentForm(data: Payment): Observable<PaymentDetailsResponse> {
    const plan: Plan = this.sessionService.getPlan();
    return this.activatePlanService.paymentDetails({ planId: plan.id, body: data });
  }

  submitNoCheckoutContract(data: NocheckoutRequest): Observable<void> {
    return this.noCheckoutSubscriptionService.noCheckoutSubscribe({ body: data });
  }

  searchCity(query: string): Observable<City[]> {
    return query.length >= 3 ? this.cityService.searchCity({ q: query }) : of([]);
  }

  getZip(cityId: string): Observable<string[]> {
    return this.cityService.getCityCap({ cityId });
  }

  getFactoring(planId: string): Observable<FactoringResponse> {
    return this.activatePlanService.factoring({ planId });
  }
}
