import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Plan } from '../../models/plan.model';
import { LoadingService } from '../../services/loading.service';
import { SessionService } from '../../services/session.service';
import ProductTypes from './assets/product-types.json';

@Component({
  selector: 'app-plan-card',
  templateUrl: './plan-card.component.html',
  styleUrls: ['./plan-card.component.scss'],
})
export class PlanCardComponent implements OnInit {
  @Input() plan: Plan;
  @Input() requiredPayment: boolean;

  isOnePaySolution: boolean;
  hasDiscount: boolean;
  hasDelay: boolean;
  planDelayLabel: string;

  constructor(
    private router: Router,
    private loadingService: LoadingService,
    private sessionService: SessionService,
  ) {}

  ngOnInit(): void {
    this.checkOnePaySolution();

    this.hasDiscount =
      this.plan.discount && this.plan.discountedPrice != this.plan.fullPrice;
    this.hasDelay = this.plan.delay && this.plan.delay > 0;

    if (this.hasDelay) {
      // When the delay is less than 30 days or is not divisible by 30 the UI will
      // display the remaining days instead of the months
      if (this.plan.delay < 30 || this.plan.delay % 30 !== 0) {
        this.planDelayLabel = `${this.plan.delay} giorni`;
      } else {
        const delayedMonths = this.plan.delay / 30;
        const monthsLabel = delayedMonths > 1 ? 'mesi' : 'mese';
        this.planDelayLabel = `${delayedMonths} ${monthsLabel}`;
      }
    }
  }

  private checkOnePaySolution() {
    const check = !this.plan.installments || this.plan.installments === 0;
    this.isOnePaySolution = check;
  }

  activatePlan(): void {
    this.loadingService.startSpinner();
    this.sessionService.savePlan(this.plan);
    this.router.navigate(['/activate']).then(() => {
      this.loadingService.stopSpinner();
    });
  }

  getProductType(productCode: string) {
    let types = Object.keys(ProductTypes);
    for (let type of types) {
      if (productCode.startsWith(type)) return ProductTypes[type];
    }
    return '';
  }
}
