<div class="container">
  <div *ngIf="!paymentCheck" class="payment-check">
    <mat-spinner color="accent"></mat-spinner>
    <h3>
      Per favore, <br />
      attendi qualche minuto mentre verifichiamo il pagamento
    </h3>
  </div>
  <div *ngIf="paymentCheck">
    <h1>Benvenuto!</h1>
    <br /><br />
    <h2>
      <ng-container *ngIf="!isProspect; else prospectView">
        <p>
          Il tuo conto è stato attivato correttamente. Abbiamo inviato il contratto
          all'indirizzo {{ pec }}
        </p>
        <p>
          Verifica la tua casella di posta per ottenere le indicazioni sul primo accesso
          al tuo conto personale
        </p>
      </ng-container>
      <ng-template #prospectView>
        <p>
          La tua sottoscrizione è stata attivata correttamente. Abbiamo inviato il
          contratto all'indirizzo {{ pec }}
        </p>
      </ng-template>
    </h2>

    <a
      *ngIf="!isProspect"
      href="https://sardex.nosu.co/"
      mat-raised-button
      color="accent"
      class="mat-big-button"
      >VAI AL CONTO</a
    >
  </div>
</div>
