<div class="container" id="not-found">
  <div class="page-container not-found-container">
    <div>
      <!-- <mat-icon class="not-found-icon material-icons">sentiment_very_dissatisfied</mat-icon> -->
      <img [src]="notFoundContent.image" class="not-found-icon material-icons" alt="" />
      <!-- <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="far" data-icon="frown" class="not-found-icon material-icons svg-inline--fa fa-frown fa-w-16" role="img" viewBox="0 0 496 512"><path fill="currentColor" d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200-89.7 200-200 200zm-80-216c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm160-64c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zm-80 128c-40.2 0-78 17.7-103.8 48.6-8.5 10.2-7.1 25.3 3.1 33.8 10.2 8.4 25.3 7.1 33.8-3.1 16.6-19.9 41-31.4 66.9-31.4s50.3 11.4 66.9 31.4c8.1 9.7 23.1 11.9 33.8 3.1 10.2-8.5 11.5-23.6 3.1-33.8C326 321.7 288.2 304 248 304z"/></svg> -->
    </div>
    <h1 class="not-found-tagline">{{notFoundContent.title}}</h1>
    <div class="not-found-message" [innerHTML]="notFoundContent.message"></div>
  </div>
</div>
