<mat-card class="plan-card">
  <div class="plan-card-header">
    {{ getProductType(plan.name) }}
  </div>

  <mat-card-content>
    <div class="plan-card-solution-type">
      <ng-container *ngIf="requiredPayment">
        <span *ngIf="isOnePaySolution">Pagamento in unica soluzione</span>
        <span *ngIf="!isOnePaySolution">Pagamento rateizzato</span>
      </ng-container>
      <ng-container *ngIf="!requiredPayment">
        <span>&nbsp;</span>
      </ng-container>
    </div>

    <div class="plan-card-price">
      <div class="mat-display-1 full-price" [ngClass]="{ 'is-discounted': hasDiscount }">
        <span>{{ plan.fullPrice }}€</span>
      </div>
      <ng-container *ngIf="hasDiscount && isOnePaySolution">
        <div class="mat-display-1 discounted-price">
          <span class="price"> {{ plan.discountedPrice | number : '1.2-2' }}€ </span>
        </div>
      </ng-container>
      <ng-container *ngIf="!isOnePaySolution">
        <div class="mat-display-1 discounted-price">
          <small>{{ plan.installments }} rate da </small>
          <span class="price"> {{ plan.installmentPrice | number : '1.2-2' }}€ </span>
        </div>
      </ng-container>
      <ng-container *ngIf="hasDiscount">
        <div class="discount">
          <div class="mat-subheading-2">-{{ plan.discount }}%</div>
        </div>
      </ng-container>

      <div class="iva-disclaimer">Importo comprensivo d'Iva</div>
    </div>

    <ng-container *ngIf="hasDelay">
      <div class="plan-card-delay">
        <div class="">Prima rata tra {{ planDelayLabel }}</div>
      </div>
    </ng-container>

    <div class="plan-card-content">
      <mat-divider></mat-divider>
      <ul>
        <li class="mat-body-2">
          Disponibilità commerciale da {{ plan.commercialAvailability.gte }} a
          {{ plan.commercialAvailability.lte }}
        </li>
      </ul>

      <ul *ngFor="let detail of plan.details">
        <mat-divider></mat-divider>
        <li class="mat-body-2">
          {{ detail }}
        </li>
      </ul>
      <mat-divider></mat-divider>
    </div>

    <div class="plan-card-actions">
      <a mat-flat-button color="accent" class="mat-big-button" (click)="activatePlan()">
        <ng-container *ngIf="requiredPayment">
          <span [ngSwitch]="plan.type">
            <ng-container *ngSwitchCase="'online'"> Attiva online </ng-container>
            <ng-container *ngSwitchCase="'recurring'"> Attiva online </ng-container>
            <ng-container *ngSwitchCase="'offline'">
              Attiva con appuntamento
            </ng-container>
            <ng-container *ngSwitchDefault></ng-container>
          </span>
        </ng-container>
        <ng-container *ngIf="!requiredPayment">
          <span> Compila il contratto </span>
        </ng-container>
      </a>

      <ng-container *ngIf="requiredPayment">
        <p class="info mat-body-2" [ngSwitch]="plan.type">
          <ng-container *ngSwitchCase="'online'">
            Pagamento con carta di credito o PayPal
          </ng-container>
          <ng-container *ngSwitchCase="'offline'">
            Pagamento con carta di credito, bonifico, PayPal, Dilapay
          </ng-container>
          <ng-container *ngSwitchDefault></ng-container>
        </p>
      </ng-container>
    </div>
  </mat-card-content>
</mat-card>
