<footer>
  <div class="first-row">
    <div class="info">
      <strong>Sardex Spa</strong>
      <ul>
        <li>Viale Sant'Ignazio, 16 - 09038 Serramanna SU</li>
        <li>P.IVA: 03257760920</li>
      </ul>
    </div>
    <div class="links">
      <span class="link">
        <mat-icon aria-hidden="false" aria-label="Sito Web"> desktop_mac </mat-icon>
        <a href="https://www.sardexpay.net" title="SardexPay" target="_blank"
          >SardexPay</a
        >
      </span>
      <span class="link">
        <mat-icon aria-hidden="false" aria-label="Email"> mail </mat-icon>
        <a href="mailto:info@sardexpay.net" title="Email" target="_blank"
          >info@sardexpay.net</a
        >
      </span>
      <span class="link">
        <mat-icon aria-hidden="false" aria-label="Chiama"> call </mat-icon>
        <span>070 332 7433</span>
      </span>
    </div>
  </div>
  <div class="second-row">
    <span>Copyright © SardexPay</span>
  </div>
</footer>
