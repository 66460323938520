<mat-form-field appearance="fill" [formGroup]="group">
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    [matDatepicker]="picker"
    [id]="controlName"
    [name]="controlName"
    [formControlName]="controlName"
    (click)="picker.open()"
    (dateChange)="isDateChanged($event.value)"
    [placeholder]="placeholder"
    [max]="today"
    [required]="isRequired"
  />
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
  <mat-error>
    <p
      *ngIf="
        controlObject.errors?.pattern && (controlObject.dirty || controlObject.touched)
      "
    >
      La data inserita deve rispettare il formato GG/MM/AAAA
    </p>
    <p
      *ngIf="
        controlObject.errors?.futureDate && (controlObject.dirty || controlObject.touched)
      "
    >
      La data inserita non può essere successiva a {{ todayLocale }}
    </p>
    <p
      *ngIf="
        controlObject.errors?.majorAge && (controlObject.dirty || controlObject.touched)
      "
    >
      Per proseguire è necessario aver 18 anni o più
    </p>
  </mat-error>
</mat-form-field>
